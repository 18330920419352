import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CommonConfig } from "constant";
import "assets/css/style.css";
import { useForm, Controller } from "react-hook-form";
import Card from "@mui/material/Card";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import Grid from "@mui/material/Grid";
import {
  Add,
  ArrowDownward,
  CancelScheduleSendTwoTone,
  ControlCameraTwoTone,
  NoBackpackSharp,
} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import axios from "axios";
import cogoToast from "cogo-toast";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CardContent from "@mui/material/CardContent";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button as PButton } from "primereact/button";
import { TextField } from "@mui/material";
import ViewListIcon from '@mui/icons-material/ViewList';
import MDTypography from "components/MDTypography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  FormControlLabel,
  Radio,
  RadioGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel,
  Switch,
} from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Autocomplete from "@mui/material/Autocomplete";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import DateFnsUtils from "@date-io/date-fns";

function BookPropertyList(props) {
  let navigate = useNavigate();

  if (localStorage.getItem("userData")) {
    // alert("Hello");
  } else {
    // alert("Hello2")
    navigate("/");
  }
  let i = 1;
  const { state } = useLocation();
  const [apiData, setApiData] = useState([]);
  const [rememberMe, setRememberMe] = useState(true);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [name, setName] = useState("");
  const [cityOrState, setCityOrState] = useState("");
  const [userType, setUserType] = useState("");
  const [phoneOrEmail, setPhoneOrEmail] = useState("");
  const [personIdNumber, setPersonIdNumber] = useState("");
  const [stateList, setStateList] = useState(null);
  const [values, setValues] = useState([]);
  const [stateLabel, setStateLabel] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [availableBedroom, setAvailableBedroom] = useState(null);
  const [propertyId, setPropertyId] = useState(null);
  const [bookId, setBookId] = useState(null);
  const [selectedMediumType, setSelectedMediumType] = useState(false);
  const [appointmentdelID, setappointmentdelID] = useState(null);
  const [loader, setLoader] = React.useState(false);
  const [conformationData, setConformationData] = useState(null);
  const [bookingAllStatus, setBookingAllStatus] = useState(null);
  const [visitType, setVisitType] = useState('');
  const [showDateRange, setShowDateRange] = useState(true);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  let loginUser;

  loginUser = JSON.parse(localStorage.getItem("userData"));

  var userTypeMessage = "View Inspection List";

  console.log("Testing12 ", loginUser.PersonId);

  console.log("bookingAllStatus", bookingAllStatus);

  const columns = [
    {
      name: "Booking Inspection Number",
      selector: "BookID",
      filter: true,
      sortable: true,
    },
    {
      name: "Property ID",
      selector: "ValueCarePropertyId",
      filter: true,
      sortable: true,
    },
    {
      name: "Address",
      selector: "Address",
      filter: true,
      sortable: true,
    },
    {
      name: "Name",
      selector: "PersonName",
      filter: true,
      sortable: true,
    },
    {
      name: "Email",
      selector: "Email",
      filter: true,
      sortable: true,
    },
    {
      name: "Phone",
      selector: "Phone",
      filter: true,
      sortable: true,
    },
    {
      name: "Managed By",
      selector: "managebyname",
      filter: true,
      sortable: true,
    },
    {
      name: "Inquiry Date",
      selector: "InquiryDate",
      filter: true,
      sortable: true,
    },
    {
      name: "Visiting Date",
      selector: "VisitDateTime",
      filter: true,
      sortable: true,
    },
    {
      name: "Booking Date",
      selector: "ConfirmationDateTime",
      filter: true,
      sortable: true,
    },
    // {
    //   name: "Available Bedroom",
    //   selector: "TotalAvailableBedroomCount",
    //   filter: true,
    //   sortable: true,
    // },
    {
      name: "Status",
      selector: "ManageStatus",
      filter: true,
      sortable: true,
    },
    // {
    //   name: "Date",
    //   selector: "ADateTime",
    //   filter: true,
    //   sortable: true,
    // },
    // {
    //   name: "Time",
    //   selector: "VsitingTime",
    //   filter: true,
    //   sortable: true,
    //   rowReorderIcon: null,
    //   filtericon: false,
    // },
    {
      name: "Action",
      body: (data) => actionTemplate(data),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const visitTypeList = [
    { label: 'Inquiry Date', value: 'InquiryDate' },
    { label: 'Visit Date', value: 'VisitDate' },
  ];
  
  // const bookingStatus = () => {
  //   setConformationData("Active");
  //   setTimeout(() => {
  //     setConformationData(null);
  //   }, 3000);
  // };

  const handleSwitchChange = () => {
    setShowDateRange(!showDateRange);
  };

  useEffect(() => {
    console.log("checkupdatenstatus", bookingAllStatus);
    if (bookingAllStatus) {
      getColoumnsRow();
    }
  }, [bookingAllStatus]);

  const actionTemplate = (data) => {
    return (
      <div>
        <PButton
          type="button"
          icon="pi pi-user-edit"
          className="p-button-warning"
          onClick={() => editUser(data)}
          style={{ marginRight: ".5em" }}
          tooltip="Edit Inspection Details"
          tooltipOptions={{ position: "bottom" }}
        />
        &nbsp;
        {data.ManageStatus === "Booked" ? (
        <PButton
          type="button"
          icon="pi pi-user-minus"
          className="p-button-danger"
          onClick={() => {
            selectedRow(data);
          }}
          style={{ marginRight: ".5em" }}
          tooltip="Cancel Booking"
          tooltipOptions={{ position: "bottom" }}
        />
        ) : (
          <></>
        )}
        &nbsp;
        {data.ManageStatus !== "Booked" ? (
          <PButton
            type="button"
            icon="pi pi-calendar-plus"
            className="p-button-success"
            // disabled={conformationData}
            onClick={() => {
              ConfirmRow(data);
            }}
            tooltip="Confirm Booking"
            tooltipOptions={{ position: "bottom" }}
          />
        ) : (
          <></>
        )}
          {/* <span>
            <PButton
              type="button"
              icon="pi pi-check"
              className="p-button-success"
              disabled={conformationData}
              onClick={() => {
                StatusRow(data, "Complete");
              }}
              tooltip="Complete Booking"
              tooltipOptions={{ position: "bottom" }}
            />
            &nbsp;
            <PButton
              type="button"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => {
                StatusRow(data, "Cancelled");
              }}
              tooltip="Cancel Booking"
              tooltipOptions={{ position: "bottom" }}
            />
          </span> */}
      </div>
    );
  };

  const getColoumnsRow = () =>
    columns.map((item, id) => {
      let columnStyle = { minWidth: "125px" };
      if (item.selector === "Address" || item.selector === "Email") {
        columnStyle = { minWidth: "300px" };
      } else if (item.selector === "BookID") {
        columnStyle = { minWidth: "170px" };
      } else if (item.selector === "managebyname") {
        columnStyle = { minWidth: "150px" };
      } else if (item.name === "Action") {
        columnStyle = { minWidth: "140px" };
      } else if (item.selector === "InquiryDate" || item.selector === "VisitDateTime" || item.selector === "ConfirmationDateTime") {
        columnStyle = { minWidth: "170px" };
      } else if (item.selector === "PersonName") {
        columnStyle = { minWidth: "170px" };
      }
      return (
        <Column
          key={id}
          sortable={item.sortable}
          body={item?.body}
          filter={item.filter}
          field={item.selector}
          header={item.name}
          icon="pi pi-filter-icon pi-filter"
          style={columnStyle}
        />
      );
    });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    // errors,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
  } = useForm({
    UserType: "",
    defaultValues: {
      visitType: '',
      fromDate: '',
      toDate: '',
    },
  });

  const getData = (props) => {

    if (props === 'filter') {
      clearErrors();
    }

    if (props === 'filter') {
      if (!visitType) {
        setError('visitType', {
          type: 'manual',
          message: 'Search By is required',
        });
      }

      if (!fromDate) {
        setError('fromDate', {
          type: 'manual',
          message: 'From Date is required',
        });
      }

      if (!toDate) {
        setError('toDate', {
          type: 'manual',
          message: 'To Date is required',
        });
      }
    }

    if (props === 'filter') {
      if (!visitType || !fromDate || !toDate) {
        console.error('Validation error: Please fill in all required fields');
        cogoToast.error("Please fill in all required fields");
        return;
      }
    }

    setLoader(!loader);
    let userLogin = JSON.parse(localStorage.getItem("userData"));
    var manageBy = "All"
    if (userLogin.PersonType == "INTERNAL STAFF") {
      manageBy = userLogin.PersonId
    }
    var dataset = {
      manageBy: manageBy,
      showDateRange : showDateRange === true ? 1: 0,
      visitType: visitType,
      fromDate:CommonConfig.formatDate(fromDate),
      toDate: CommonConfig.formatDate(toDate)
    }
    axios({
      url: CommonConfig.ApiUrl + "Property/getBookInspectionlist",

      method: "POST",
      headers: {
        // https://uatapi.valuecare.org.au/Referral/getReferralList
        // Add any auth token here
        //authorization: "your token comes here",
      },
      // headers: {},
      data: dataset,
    })
      .then((res) => {
        setLoader(!loader);
        if (res.data?.success === true) {
          console.log("resdata", res.data.data[0]);
          setLoader(false);
          if (res.data.data[0].length > 0) {
            console.log("resdata33", res.data.data[0]);
            const mapData = res.data.data[0].map((tempData) => {
              tempData.IsMultiLivingOption = "";
              tempData.IsPreferred = "";
              tempData.BookingStatus = "";
              return tempData;
            });
            setApiData(mapData);
            console.log("Data...1", mapData);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const CancelDelete = () => {
    setSelectedMediumType("");
    setOpenDialog(false);
  };

  const StatusRow = (StatusRowData, StatusType) => {
    var PropertyId = {
      id: StatusRowData.PropertyID,
      bookID: StatusRowData.BookInspectionID,
      statusdata: StatusType,
    };

    axios({
      url: CommonConfig.ApiUrl + "Property/UpdateAvalibleBedroomCount",
      method: "POST",
      headers: {},
      data: PropertyId,
    })
      .then((res) => {
        if (res.data?.success) {
          cogoToast.success("Booking " + StatusType + " successfully");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ConfirmRow = (Confirmdata) => {
    console.log("Confirmdata = ", Confirmdata?.BookingStatus);
    setBookingAllStatus(Confirmdata?.BookingStatus);
    setAvailableBedroom(Confirmdata?.TotalAvailableBedroomCount);
    setPropertyId(Confirmdata?.PropertyID);
    setBookId(Confirmdata?.BookInspectionID);
    setOpenConfirmDialog(true);
    // UpdateAvalibleBedroomCount(Confirmdata);
    // bookingStatus();
  };

  const ConfirmAction = () => {
    console.log("Selected Medium Type:", selectedMediumType);
    if (selectedMediumType == "Yes") {
      document.getElementById("ServicesError").style.display = "none";
      UpdateAvalibleBedroomCount('Booked');
      setOpenConfirmDialog(false); 
    } else if (selectedMediumType == "No") {
      document.getElementById("ServicesError").style.display = "none";
      setOpenConfirmDialog(false);
    } else {
      document.getElementById("ServicesError").style.display = "block";
    }
  };

  const CancelAction = () => {
    setSelectedMediumType("");
    setOpenConfirmDialog(false);
  };

  const UpdateAvalibleBedroomCount = (pStatus) => {
    var PropertyId = {
      id: propertyId,
      bookID: bookId,
      manageStatus: pStatus,
    };
    axios({
      url: CommonConfig.ApiUrl + "Property/UpdateAvalibleBedroomCount",
      method: "POST",
      headers: {},
      data: PropertyId,
    })
      .then((res) => {
        if (res.data?.success) {
          cogoToast.success("Booking status changed to " + pStatus + " successfully");
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectedRow = (selectedRow = {}) => {
    setappointmentdelID(selectedRow.BookInspectionID);
    console.log("........,new", appointmentdelID);

    setPropertyId(selectedRow?.PropertyID);
    setBookId(selectedRow?.BookInspectionID);

    setOpenDialog(true);
  };

  const CancelBooking = () => {
    console.log("Selected Medium Type:", selectedMediumType);
    if (selectedMediumType == "Yes") {
      document.getElementById("ServicesError").style.display = "none";
      UpdateAvalibleBedroomCount('Canceled');
      setOpenDialog(false); 
    } else if (selectedMediumType == "No") {
      document.getElementById("ServicesError").style.display = "none";
      setOpenDialog(false);
    } else {
      document.getElementById("ServicesError").style.display = "block";
    }
  };

  const deleteUser = (selectedRow = {}) => {
    setSelectedMediumType(false);
    if (selectedMediumType == "Yes") {
      document.getElementById("ServicesError").style.display = "none";
      let inputData = {
        id: appointmentdelID,
      };
      console.log("inputData...", inputData);
      axios({
        url: CommonConfig.ApiUrl + "Property/deleteBookInspection",
        method: "POST",
        headers: {},
        data: inputData,
      })
        .then((res) => {
          if (res.data?.success) {
            console.log("test222", res.data);
            if (res.data.success === true) {
              cogoToast.success("Deleted Successfully.");
              setOpenDialog(false);
              getData();
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (selectedMediumType == "No") {
      document.getElementById("ServicesError").style.display = "none";
      setOpenDialog(false);
    } else {
      document.getElementById("ServicesError").style.display = "block";
    }
  };

  const editUser = async (row = {}) => {
    setLoader(!loader);
    const BookInspectionID = row.BookInspectionID;
    var rowdata = row;
    console.log("PropertyID////0", row?.BookingStatus);
    console.log("rowDataFromListing", rowdata);

    navigate("/layouts/Property/EditBookProperty", {
      state: rowdata,
    });
    // var id = { id: BookInspectionID };
    // // console.log("idddddddddddd", id);
    // // {"id":"c7604f2b-6996-11ed-9f52-fa163e3ffd3c"}
    // try {
    //   const data = await axios(CommonConfig.ApiUrl + "Property/getBookPropertylistById", {
    //     method: "POST",
    //     data: id,
    //   }).then((res) => {
    //     console.log("ressss", res);
    //     setLoader(!loader);
    //     var userdata = res.data.data[0][0];
    //     //   dOC: res.data.data[1],

    //     var documentdata = [];

    // documentdata.push(res.data.data[1]);
    // var newdata = userdata.concat(documentdata);
    // documentdata.map((value) => {
    //   userdata.push(value);
    // });

    // });

    // navigate("/layouts/referal/refEdit", {
    //   state: data,
    // });
    // } catch (error) {
    //   console.log({ error });
    // }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Card sx={{ width: "100%", mt: 3 }}>
        <MDBox display="flex">
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="primary"
            color="white"
            shadow="md"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <ViewListIcon fontSize="medium" color="inherit"></ViewListIcon>
          </MDBox>
          <MDTypography variant="h4" sx={{ mt: 2, mb: 1, ml: 2 }}>
            {userTypeMessage}
          </MDTypography>
        </MDBox>
        <MDBox sx={{ pt: 2, pb: 2, pl: 2, pr: 2 }}>

          <div className="custom-accordian">
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon style={{ scale: '120%' }} />}>Search Filters</AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  {showDateRange && (
                    <>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="visitType"
                            control={control}
                            defaultValue={''}
                            rules={{ required: 'Search By is required' }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={visitTypeList}
                                onChange={(event, newValue) => {
                                  onChange(newValue?.value || '');
                                  setVisitType(newValue?.value);
                                }}
                                // value={visitTypeList.find((option) => option.value === value) || null}
                                // sx={{ width: 300 }}
                                renderInput={(params) => (
                                <TextField 
                                {...params} 
                                label="Search By*"
                                error={!!errors.visitType}
                                // helperText={errors.visitType?.message}
                                helperText={errors.visitType ? errors.visitType.message : ''}
                                />
                                )}
                              />
                            )}
                          />
                        </div>
                      </Grid></>)}
                  <Grid item md="3">
                    {showDateRange && (
                      <>
                        <div className="reg_input">
                          <Controller
                            name="fromDate"
                            defaultValue=""
                            id="fromDate"
                            control={control}
                            rules={{ required: 'From Date is required' }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <LocalizationProvider dateAdapter={DateFnsUtils}>
                                <DatePicker
                                  label="From Date*"
                                  value={value}
                                  inputFormat="dd/MM/yyyy"
                                  onChange={(date) => {
                                    setFromDate(date);
                                    onChange(date); 
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      id="fromDate"
                                      name="fromDate"
                                      {...params}
                                      error={errors.fromDate}
                                      // helperText={errors.fromDate ? "From Date Required." : ""}
                                      helperText={errors.fromDate ? errors.fromDate.message : ''}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </div>
                      </>
                    )}
                  </Grid>
                  <Grid item md="3">
                    {showDateRange && (
                      <>
                        <div className="reg_input">
                          <Controller
                            name="toDate"
                            defaultValue=""
                            id="toDate"
                            control={control}
                            rules={{ required: 'To Date is required' }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <LocalizationProvider dateAdapter={DateFnsUtils}>
                                <DatePicker
                                  label="To Date*"
                                  value={value}
                                  inputFormat="dd/MM/yyyy"
                                  onChange={(date) => {
                                    setToDate(date);
                                    onChange(date); 
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      id="toDate"
                                      name="toDate"
                                      {...params}
                                      error={errors.fromDate}
                                      // helperText={errors.fromDate ? "To Date Required." : ""}
                                      helperText={errors.toDate ? errors.toDate.message : ''}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </div>
                      </>
                    )}
                  </Grid>
                  <Grid item md="2">
                    <MDButton onClick={() => {
                      getData('filter');
                      }} variant="contained" color="primary">
                      Search
                    </MDButton>
                  </Grid>
                </Grid>

                {/* <Grid container spacing={3}>
                  <Grid item md="4">
                    <FormControlLabel control={<Switch checked={showDateRange} onChange={handleSwitchChange} />} label="With Date Range" />
                  </Grid>
                </Grid> */}
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="card">
            <div className="table-custom">
              <DataTable
                scrollable
                value={apiData}
                showGridlines
                scrollHeight="600px"
                scrollWidth="600px"
                responsiveLayout="scroll"
                paginator
                rows={10}
                totalRecords={apiData ? apiData.length : 0}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rowsPerPageOptions={[10, 20, 50]}
                emptyMessage="No records found"
                // paginatorLeft={paginatorLeft}
                // paginatorRight={paginatorRight}
                filterDisplay="row"
              >
                {getColoumnsRow()}
              </DataTable>
            </div>
          </div>
        </MDBox>
      </Card>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="xs">
        <DialogTitle>Remove Assign Property</DialogTitle>
        <DialogContent>
          <Grid item md="12">
            <div className="reg_input check_value mb-0">
              <InputLabel className="reg_lable mt-5 mb-2">
                Are You Sure to Cancel this booking?
              </InputLabel>
              <div>
                <span class="servicesError" id="ServicesError">
                  Please select any one option.
                </span>
              </div>
              <RadioGroup
                aria-label="remove"
                name="Remove"
                row="true"
                onChange={(e) => setSelectedMediumType(e.target.value)}
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio checked={selectedMediumType === "Yes" ? true : false} />}
                  label="Yes"
                  name="Remove"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio checked={selectedMediumType === "No" ? true : false} />}
                  label="No"
                  name="Remove"
                />
              </RadioGroup>
              <span class="errorTC" id="ServicesError">
                Please select any one service
              </span>
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton variant="contained" color="secondary" onClick={() => CancelDelete()}>
            Back
          </MDButton>
          <MDButton
            variant="contained"
            color="primary"
            onClick={() => {
              CancelBooking();
            }}
          >
            Ok
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)} maxWidth="md">
          <DialogTitle>Confirm Booking Property</DialogTitle>
          <DialogContent>
            <Grid item md="12">
              {availableBedroom > 0 ? (
                <div className="reg_input check_value mb-0">
                  <InputLabel className="reg_lable mt-5 mb-2">
                    Are You Sure to Confirm this booking?
                  </InputLabel>
                  <div className="pd-box">
                    <p>
                      <span>Available Bedrooms:</span>{" "}
                      {availableBedroom}
                    </p>
                  </div>
                  <div>
                    <span class="servicesError" id="ServicesError">
                      Please select any one option.
                    </span>
                  </div>
                  <RadioGroup
                    aria-label="remove"
                    name="Remove"
                    row="true"
                    onChange={(e) => setSelectedMediumType(e.target.value)}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio checked={selectedMediumType === "Yes" ? true : false} />}
                      label="Yes"
                      name="Remove"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio checked={selectedMediumType === "No" ? true : false} />}
                      label="No"
                      name="Remove"
                    />
                  </RadioGroup>
                  <span class="errorTC" id="ServicesError">
                    Please select any one service
                  </span>
                </div>
              ) : (
                <div className="pd-box">
                  <p>
                    <span style={{ color: "red", fontSize: "1rem" }}>
                      No Available Bedrooms. Please choose another property.
                    </span>
                  </p>
                </div>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <MDButton
              variant="contained"
              color="secondary"
              onClick={() => CancelAction()}>
              Back
            </MDButton>
            {availableBedroom > 0 ? (
              <MDButton
                variant="contained"
                color="primary"
                onClick={() => {
                  ConfirmAction();
                }}
              >
                Ok
              </MDButton>
            ) : (
              <></>
            )}
          </DialogActions>
        </Dialog>

    </DashboardLayout>
  );
}

export default BookPropertyList;
