// import { Datepicker } from 'vanillajs-datepicker';

import moment from "moment";
import cogoToast from "cogo-toast";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";

export class CommonConfig {
  static BookingType_FaceToFace = "Face to Face";
  static BookingType_RequestCallBack = "Request Callback";
  static BookingType_AudioVideoConference = "Audio/Video Conference";

  static isEmpty = function (value) {
    if (value === undefined || value === null || value === "") {
      return true;
    } else {
      if (typeof value === "string") {
        return value.trim() === "";
      } else {
        return false;
      }
    }
  };

  static isObjectEmpty = function (obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  static giveNotNaN = function (val, isInt) {
    if (isInt) return parseInt(isNaN(val) || "" == val ? 0 : val);
    else return parseFloat(isNaN(val) || "" == val ? 0 : val);
  };

  static showErrorMsg = function (msg) {
    return (
      <span className="text-danger">
        <span className="field-validation-error">{msg}</span>
      </span>
    );
  };

  static filterTwoArray = function (data, filters) {
    return data.filter((e) => {
      try {
        filters.forEach((o) => {
          Object.keys(o).forEach((key) => {
            if (e[key] !== o[key]) throw new 1();
          });
        });

        return true;
      } catch (e) {
        return false;
      }
    });
  };

  static getDatesBetweenDates = function (startDate, endDate) {
    let dates = [];
    //to avoid modifying the original date
    const theDate = new Date(startDate);
    while (theDate < endDate) {
      dates = [...dates, new Date(theDate)];
      theDate.setDate(theDate.getDate() + 1);
    }
    // dates = [...dates, endDate]
    dates.splice(0, 1);
    return dates;
  };

  static addDaysToDates = function (date, noOfDays) {
    var theDate = date;
    theDate.setDate(theDate.getDate() + noOfDays);
    return theDate;
  };

  static dateFormat = {
    dateTime: "MM/DD/YYYY hh:mm:ss A",
    dateOnly: "MM/DD/YYYY",
    dateFirst: "DD/MM/YYYY",
    forDatePicker: "DD-MM-YYYY",
    yearOnly: "YYYY",
    dbDateTime: "YYYY-MM-DD HH:mm:ss",
    timeOnly: "HH:mm",
  };

  static loginData = function () {
    if (localStorage.getItem("loginData")) {
      return JSON.parse(localStorage.getItem("loginData"));
    } else {
      return "";
    }
  };

  static userCountryData = function () {
    if (localStorage.getItem("UserCountryDetails")) {
      return JSON.parse(localStorage.getItem("UserCountryDetails"));
    } else {
      return "";
    }
  };

  static getUserAccess = function (moduleName) {
    if (localStorage.getItem("loginData")) {
      let data = JSON.parse(localStorage.getItem("loginData")).userModuleAccess;

      let moduleAccess = data.find((x) => x.MenuKey === moduleName);

      return moduleAccess;
    } else {
      return "";
    }
  };

  static filterCaseInsensitive = function (filter, row) {
    const id = filter.pivotId || filter.id;
    const content = row[id];

    if (typeof content !== "undefined") {
      if (typeof content === "object" && content !== null && content.key) {
        return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
      } else {
        return String(content).toLowerCase().includes(filter.value.toLowerCase());
      }
    }

    return true;
  };

  static RegExp = {
    number: /^[0-9\b]+$/,
    onlyNumber: /[a-zA-Z~`!@#$%^&*()_+=-{}|:"<>?,;']+$/,
    onlyDecimal: /^[0-9]+(\.[0-9][0-9])?$/,
    phoneNumber: /^([0-9]+\s?)*$/g,
    // email : /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9-]+\.[A-Z]{2,6}$/ig,
    email:
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    companyName: /[!@~`#$%^&*()_+\-=\]{};':"\\|,.<>?]/,
    zipCode: /^([a-zA-Z0-9]+\s?)*$/g,
    regExpNumber: /[0-9]/g,
    regExpUpperCase: /[A-Z]/g,
    regExpLowerCase: /[a-z]/g,
    phone: /^[0-9]{10}$/,
    regExpSpecialCharacter: /[!@#$%^&*(),.?":{}|<>]/g,
  };

  static getCurrentUserId = function () {
    if (localStorage.getItem("userData")) {
      let personId = JSON.parse(localStorage.getItem("userData")).PersonId;
      return personId;
    }

    return "";
  };

  static isInternalStaff = function () {
    if (localStorage.getItem("userData")) {
      let PersonType = JSON.parse(localStorage.getItem("userData")).PersonType;
      if (PersonType === "Admin" || PersonType === "INTERNAL STAFF") {
        return 1;
      } else if (
        PersonType === "SUPPORT COORDINATOR" ||
        PersonType === "CARER" ||
        PersonType === "SUB CONTRACTOR" ||
        PersonType === "INTERESTED VC SERVICES"
      ) {
        return 2;
      } else if (PersonType === "DIFFERENTLY ABLED") {
        return 3;
      } else {
        return 0;
      }
    }
  };

  static formatDate = function (date) {
    if (date) {
      let formattedDate = moment(date).format("YYYY-MM-DD");
      if (formattedDate) return formattedDate;
      return null;
    }
    return "";
  };

  static formatDate2 = function (date) {
    if (date) {
      let formattedDate = moment(date).format("dd/MM/yyyy");
      if (formattedDate) return formattedDate;
      return null;
    }
    return "";
  };
  static chartColors = [
    "#00acc1",
    "#00ff98",
    "#ff9800",
    "#f44336",
    "#666699",
    "#CC9933",
    "#006666",
    "#3399FF",
    "#993300",
    "#FFCC66",
    "#6699CC",
    "#663366",
    "#663366",
    "#669999",
    "#CC6600",
    "#9999FF",
    "#0066CC",
    "#99CCCC",
    "#FFCC00",
    "#009999",
    "#99CC33",
    "#FF9900",
    "#999966",
    "#66CCCC",
    "#339966",
    "#CCCC33",
    "#003f5c",
    "#665191",
    "#a05195",
    "#d45087",
    "#2f4b7c",
    "#f95d6a",
    "#ff7c43",
    "#ffa600",
    "#EF6F6C",
    "#465775",
    "#56E39F",
    "#59C9A5",
    "#5B6C5D",
    "#0A2342",
    "#2CA58D",
    "#84BC9C",
    "#CBA328",
    "#F46197",
    "#DBCFB0",
    "#545775",
  ];

  static victoriaAddres = "victoria Address";
  static MalebournAddress = "MalebournAddress";

  // InsertVideo

  // static documentUrl = "https://valuecare.cognisun.net:9595/";
  // static ApiUrl = "https://valuecare.cognisun.net:9595/api/";
  // static dir = "https://valuecare.cognisun.net/Backend/documents/";

  static ZoomUrl = "https://zoom.valuecare.org.au:9395/api/";
  // static ZoomUrl = "http://localhost:5002/api/";

  static documentUrl = "https://servicesapi.valuecare.org.au/";
  static ApiUrl = "https://servicesapi.valuecare.org.au/api/"; // This is for Prod
  // static ApiUrl = "http://localhost:8080/api/" // This is for Local
  static dir = "https://servicesapi.valuecare.org.au/documents/";
// }
}
