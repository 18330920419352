import React, { useEffect, useState } from "react";
import "./styles.css";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { TextField, Grid } from "@mui/material";
import logo from "../../../assets/studyMaterial/logo.PNG";
import PdfLogo from "../../../assets/studyMaterial/PdfLogo.PNG";
import { useLocation, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import axios from "axios";
import { CommonConfig } from "constant";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import { Button as PButton } from "primereact/button";
import cogoToast from "cogo-toast";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel,
} from "@mui/material";
import { FormControl } from '@material-ui/core';


export default function StudyMaterial() {
  let navigate = useNavigate();

  let userLogin = "";
  if (localStorage.getItem("userData")) {
    // alert("Hello");
    userLogin = JSON.parse(localStorage.getItem("userData"));
    console.log("test....", userLogin.PersonType);
  } else {
    // alert("Hello2")
    navigate("/");
  }
  const [pdf, setPdf] = React.useState("");
  const [width, setWidth] = React.useState(0);
  const [images, setImages] = React.useState([]);
  const [height, setHeight] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pdfRendering, setPdfRendering] = React.useState("");
  const [pageRendering, setPageRendering] = React.useState("");
  const [fileDetails, setFileDetails] = useState([]);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  // added by akm
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);


  const [multiFile, setMultiFile] = useState([]);
  const [multiFileDetails, setMultiFileDetails] = useState([]);
  const [multiFileName, setMultiFileName] = useState([]);
  const [allVideoData, setAllVideoData] = useState([]);
  // Update-----------------------
  const [allDocumentData, setAllDocumentData] = useState([]);
  const [allPdfData, setAllPdfData] = useState(null);
  const [allFileData, setAllFileData] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [editFileData, setEditFileData] = useState(null);
  const [selectedMediumType, setSelectedMediumType] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteCoursesID, setDeleteCoursesID] = useState(null);

  console.log("deleteCoursesID", deleteCoursesID);
  console.log("allFileDataa", CommonConfig.dir + "video/" + allFileData?.[7]?.pdffilename);
  console.log("allFileData", allFileData?.[7]);
  console.log("allFileDataAkshay", allFileData);
  console.log("allfileUrl", fileUrl);

  useEffect(() => {
    let getPdfData = allDocumentData.filter((item) => item.fileType == "application/pdf");
    let getFileData = allDocumentData.filter((item) => item.fileType == "video/mp4");
    setAllPdfData(getPdfData);
    setAllFileData(getFileData);
    console.log("application/pdf", getFileData);
  }, [allDocumentData]);

  //   addmultipleFile----------------------------------------
  function uploadSingleFile(e) {
    let ImagesArray = Object.entries(e.target.files).map((e) => URL.createObjectURL(e[1]));
    let fileArray = Object.entries(e.target.files).map((e) => e?.[1]);
    let fileNameArray = Object.entries(e.target.files).map((e) => e?.[1]?.name);
    setMultiFileName([...multiFileName, ...fileNameArray]);
    setMultiFileDetails([...multiFileDetails, ...fileArray]);
    setMultiFile([...multiFile, ...ImagesArray]);
  }

  console.log("chekdummydata", fileDetails?.type);
  console.log("fileDetails", fileDetails);

  //   video-------------------------------------------------

  async function showFile(event) {
    try {
      setPdfRendering(true);
      const file = event.target.files[0];
      const url = URL.createObjectURL(file);
      setFileUrl(url);
      console.log("checkbloobUrl", url);
      setFileDetails(file);
    } catch (error) {
      alert(error.message);
    }
  }

  async function renderPage() {
    setPageRendering(true);
    const imagesList = [];
    const canvas = document.createElement("canvas");
    canvas.setAttribute("className", "canv");
    let canv = document.querySelector(".canv");

    for (let i = 1; i <= pdf.numPages; i++) {
      var page = await pdf.getPage(i);
      var viewport = page.getViewport({ scale: 1 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      var render_context = {
        canvasContext: canvas.getContext("2d"),
        viewport: viewport,
      };
      console.log("page lenght", pdf.numPages);
      setWidth(viewport.width);
      setHeight(viewport.height);
      await page.render(render_context).promise;
      let img = canvas.toDataURL("image/png");
      imagesList.push(img);
    }
    setImages(imagesList);
    setPageRendering(false);
  }

  useEffect(() => {
    pdf && renderPage();
    // eslint-disable-next-line
  }, [pdf, currentPage]);

  const styles = {
    wrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "5px",
    },
    imageWrapper: {
      // width: "300px",
      // height: "350px",
      border: "1px solid rgba(0,0,0,0.15)",
      borderRadius: "3px",
      boxShadow: "0 2px 5px 0 rgba(0,0,0,0.25)",
      padding: "0",
    },
  };

  function ClickPreviw(params) {
    navigate("/video-streaming", {
      state: { File: multiFileDetails[0] },
    });
  }

  function ClickPreviwPdf(params) {
    setTimeout(() => {
      navigate("/PdfFile", {
        state: { File: images, Url: pdf?._transport?._params?.url },
      });
    }, 2000);
  }

  const handleSubmit = () => {
    var formData = new FormData();
    formData.append("file", fileDetails);
    formData.append("fileName", fileDetails?.name);
    formData.append("fileType", fileDetails?.type);
    // fileDetails?.type ? fileDetails?.type : editFileData?.fileType
    // formData.append("file", pdfDetails);
    // formData.append("pdfFileName", pdfDetails?.name);
    formData.append("Title", title);
    formData.append("description", description);
    formData.append("CoursesID", editFileData);

    console.log("hellll", formData);
    axios({
      url: CommonConfig.ApiUrl + "Property/InsertVideo",
      // url: "http://localhost:5000/api/Property/InsertVideo",
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        if (res.data.success === true) {
            cogoToast.success("Courses inserted Successfully");
          //   navigate("/pages/contact-us/list");
        }
        console.log("test", res.data);
        getStudyMaterial();
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const updateFileData = () => {
    var formData = new FormData();
    // formData.append("file", editFileData);
    // formData.append("fileName", editFileData?.pdffilename);
    // formData.append("fileType", editFileData?.fileType);

    // formData.append("file", pdfDetails);
    // formData.append("pdfFileName", pdfDetails?.name);
    formData.append("Title", title ? title : editFileData?.Title);
    formData.append("description", description ? description : editFileData?.Description);
    formData.append("CoursesID", editFileData?.CoursesID);

    console.log("hellll", formData);
    axios({
      url: CommonConfig.ApiUrl + "Property/updateFileData",
      // url: "http://localhost:5000/api/Property/updateFileData",
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        if (res.data.success === true) {
            cogoToast.success("Document Updated Successfully");
          //   navigate("/pages/contact-us/list");
        }
        console.log("test", res.data);
        getStudyMaterial();
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const getStudyMaterial = () => {
    axios({
      url: CommonConfig.ApiUrl + "Property/getstudymaterial",
      // url: "http://localhost:5000/api/Property/getstudymaterial",
      method: "GET",
      headers: {},
    })
      .then((res) => {
        console.log("resStudyData", res.data);
        if (res.data.success) {
          setAllDocumentData(res.data.data[0]);
          // console.log("resStudyData11", res.data.data[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectedRow = (element = {}) => {
    setDeleteCoursesID(element?.CoursesID);

    setOpenDialog(true);
  };

  const deleteUser = () => {
    setSelectedMediumType(false);

    if (selectedMediumType == "Yes") {
      document.getElementById("ServicesError").style.display = "none";
      let inputData = {
        CoursesID: deleteCoursesID,
      };
      console.log("inputData...", inputData);
      axios({
        // url: "http://localhost:5000/api/Property/deletefiledata",
        url: CommonConfig.ApiUrl + "Property/deleteFileData",
        method: "POST",
        headers: {},

        data: inputData,
      })
        // Handle the response from backend here
        .then((res) => {
          if (res.data?.success) {
            console.log("test222", res.data);

            if (res.data.success === true) {
              cogoToast.success("Deleted Successfully.");
              setOpenDialog(false);
              getStudyMaterial();
            }
          }
        })
        // Catch errors if any
        .catch((error) => {
          console.log(error);
        });
    } else if (selectedMediumType == "No") {
      document.getElementById("ServicesError").style.display = "none";
      setOpenDialog(false);
    } else {
      document.getElementById("ServicesError").style.display = "block";
    }
  };

  useEffect(() => {
    getStudyMaterial();
  }, []);

  const CancelDelete = () => {
    setSelectedMediumType("");
    setOpenDialog(false);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Card sx={{ width: "100%", mt: 3 }}>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="primary"
              color="white"
              shadow="md"
              borderRadius="xl"
              ml={3}
              mt={-2}
            >
              <Icon fontSize="medium" color="inherit">
                add
              </Icon>
            </MDBox>
            <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
              Study Material
            </MDTypography>
          </MDBox>
          <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }} id="s1" >
            <Grid>
              {userLogin.PersonType === "Admin" ? (
                <div>
                  <Grid className="inputField" container spacing={2}>
                    <Grid item md="6" ms="0.5">
                      <div className="cs-input">
                        <FormControl fullWidth>
                          <InputLabel htmlFor="description-input">Title</InputLabel>
                          <TextField
                            name="Title"
                            id="Title"
                            // label="Title"
                            variant="outlined"
                            value={title ? title : editFileData?.Title}
                            // defaultValue={title ? title : editFileData?.Title}
                            onChange={(e) => setTitle(e.target.value)}
                            fullWidth="true"
                          />
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item md="6">
                      <div className="cs-input">
                        <InputLabel>Select File</InputLabel>
                        <input
                          id="fileUpload"
                          type="file"
                          multiple
                          accept=" application/pdf,.mov,.mp4"
                          onChange={showFile}
                          className="mt-input"
                        // value={fileDetails ? fileDetails : editFileData?.pdffilename}
                        // disabled={fileLimit}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid className="inputField" container spacing={0}>
                    <Grid item md="12">
                      <div className="cs-input">
                        <FormControl fullWidth>
                          <InputLabel htmlFor="description-input">Description</InputLabel>
                          <TextField

                            name="Description"
                            multiline
                            rows={2}
                            // label="Description"
                            fullWidth="true"
                            id="Description"
                            value={description ? description : editFileData?.Description}
                            // defaultValue={editFileData?.Description}
                            formControlProps={{
                              fullWidth: true,
                            }}

                            onChange={(e) => setDescription(e.target.value)}
                          />

                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item md="12">
                      {editFileData ? (
                        <MDButton
                          type="submit"
                          onClick={updateFileData}
                          variant="contained"
                          color="primary"
                        >
                          Update
                        </MDButton>
                      ) : (
                        <MDButton
                          type="submit"
                          onClick={handleSubmit}
                          variant="contained"
                          color="primary"
                        >
                          Submit
                        </MDButton>
                      )}
                    </Grid>
                  </Grid>
                </div>
              ) : null}
              <Grid className="inputField" container spacing={2}>
                <Grid item md="3" ms="0.5">
                  {fileDetails ? (
                    <div className="imagepreviw">
                      <div
                        className="pdfwraper"
                      // onClick={() => ClickPreviwPdf()}
                      >
                        {/* {fileDetails?.type === "video/mp4" ? (
                          <img className="uplodadimg" src={logo} width={200} />
                        ) : (
                          <img className="uplodadimg" src={PdfLogo} width={200} />
                        )}

                        <p className="pdfpara">{fileDetails?.name}</p> */}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <button
                        className="pdfbtn"
                        id="upload-button"
                        onClick={() => document.getElementById("file-to-upload").click()}
                      >
                        Change file
                      </button>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <div className={"movies-layout mt-30"}>
              <div className={"movie-card"}>
                <Grid container spacing={3}>
                  {allFileData?.map((element) => {
                    console.log("Checkelementdata", element);

                    return (
                      <Grid item md="3">
                        <div className="std-box">
                          {/* <img className="uplodadimg" src={logo} width={200} /> */}
                          <video
                            className="uplodadimg"
                            src={element?.pdffilename}
                            onClick={() => {
                              // window.open(CommonConfig.dir + "video/" + element?.pdffilename);

                              navigate("/video-streaming", {
                                state: { data: element },
                                // state: { data: fileDetails, url: allFileData[0] },
                              });
                            }}
                          />
                          <h6>{element?.Title}</h6>
                          <p>{element?.Description}</p>
                          {/* <div class = "action-btn"> */}
                            {userLogin.PersonType == "Admin"  ? (
                              <div class = "action-btn">

                              <a href="#s1">
                              <PButton
                                type="button"
                                icon="pi pi-pencil"
                                className="p-button-warning"
                                onClick={() => setEditFileData(element)}
                                style={{ marginRight: ".5em" }}
                                tooltip="Edit Study File"
                                tooltipOptions={{ position: "bottom" }}
                              />
                            </a>
                            <PButton
                              type="button"
                              className="p-button-danger"
                              icon="pi pi-user-edit"
                              onClick={() => selectedRow(element)}
                              tooltip="Delete Study File"
                              tooltipOptions={{ position: "bottom" }}
                            />
                            </div>

                            )
                            : null}
                            
                          </div>

                        {/* </div> */}
                      </Grid>
                    );
                  })}
                </Grid>

                {/* <div className={"details"}>
                  <footer>
                    <div className={"bottom"}>
                      <p>{["This step by step instruction video on how best to use ValuCare"]}</p>
                    </div>
                  </footer>
                </div> */}
              </div>
            </div>
            <div className={"movies-layout mt-30"}>
              <div className={"movie-card"}>
                <Grid container spacing={3}>
                  {allPdfData?.map((element) => {
                    return (
                      <Grid item md="3">
                        <div className="std-box">
                          <img
                            className="uplodadimg"
                            src={PdfLogo}
                            width={200}
                            onClick={() => {
                              console.log("element?.pdffilename", element?.pdffilename);
                              navigate("/PdfFile", {
                                state: { data: element?.pdffilename },
                              });
                            }}
                          />
                          <h6>{element?.Title}</h6>
                          <p>{element?.Description}</p>
                          {userLogin.PersonType == "Admin"  ? (
                          <div class = "action-btn">
                            <a href="#s1">
                              <PButton
                                type="button"
                                icon="pi pi-pencil"
                                className="p-button-warning"
                                onClick={() => setEditFileData(element)}
                                style={{ marginRight: ".5em" }}
                                tooltip="Edit Study File"
                                tooltipOptions={{ position: "bottom" }}
                              />
                            </a>
                            <PButton
                              type="button"
                              className="p-button-danger"
                              icon="pi pi-user-edit"
                              onClick={() => selectedRow(element)}
                              tooltip="Delete Study File"
                              tooltipOptions={{ position: "bottom" }}
                            />
                          </div>
                          ):null}
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </div>
          </MDBox>
        </Card>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="xs">
          <DialogTitle>Remove Study Materials File</DialogTitle>
          <DialogContent>
            <Grid item md="12">
              <div className="reg_input check_value mb-0">
                <InputLabel className="reg_lable mt-5 mb-2">
                  Are You Sure to Remove this File.?
                </InputLabel>
                <div>
                  <span class="servicesError" id="ServicesError">
                    Please select any one option.
                  </span>
                </div>
                <RadioGroup
                  aria-label="remove"
                  name="Remove"
                  row="true"
                  onChange={(e) => setSelectedMediumType(e.target.value)}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio checked={selectedMediumType === "Yes" ? true : false} />}
                    label="Yes"
                    name="Remove"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio checked={selectedMediumType === "No" ? true : false} />}
                    label="No"
                    name="Remove"
                  />
                </RadioGroup>
                <span class="errorTC" id="ServicesError">
                  Please select any one service
                </span>
              </div>
            </Grid>
          </DialogContent>
          <DialogActions>
            <MDButton variant="contained" color="secondary" onClick={() => CancelDelete()}>
              Back
            </MDButton>
            <MDButton
              variant="contained"
              color="primary"
              onClick={() => {
                deleteUser();
              }}
            >
              Ok
            </MDButton>
          </DialogActions>
        </Dialog>
      </DashboardLayout>
    </>
  );
}
