import Analytics from "layouts/dashboards/analytics";
import ZoomList from "layouts/pages/Zoom-Meeting/ZoomList";
import BookAppointment from "layouts/pages/book-appointment/index";
import EditBookAppointment from "layouts/pages/book-appointment/edit";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";

import SignInIllustration from "layouts/authentication/sign-in/illustration";
import ResetCover from "layouts/authentication/reset-password/cover";
// import Signupwork from "layouts/authentication/sign-up/Workforvaluecare";
// import Signupservices from "layouts/authentication/sign-up/Valuecareservices.js";
import Cover from "layouts/authentication/sign-up/Index";
import List from "layouts/dashboards/userprofile/Index";
import EditUser from "layouts/dashboards/userprofile/edit";
import UserBookAppointment from "layouts/pages/book-appointment/userbookappointment";
import UserBookAppointmentToken from "layouts/pages/book-appointment/UserBookAppointmentToken";
import ContactusList from "layouts/pages/contact-us/list";
import EditContact from "layouts/pages/contact-us/edit";
import AllProjects from "layouts/referal/Index";
import Referallist from "layouts/referal/Referallist";
import RefEdit from "layouts/referal/refEdit";
import SignUpDifferentlyAbled from "./layouts/authentication/sign-up/SignUpDifferentlyAbled";
import FamilyMember from "layouts/authentication/sign-up/FamilyMember";
import SupportCordinator from "layouts/authentication/sign-up/SupportCordinator";
import ValueCareServices from "layouts/authentication/sign-up/ValueCareServices";
import SubContractor from "layouts/authentication/sign-up/SubContractor";
import WorkForValueCare from "layouts/authentication/sign-up/WorkForValueCare";

import InternalStaff from "layouts/authentication/sign-up/InternalStaff";
import EditInternalStaff from "layouts/authentication/sign-up/EditInternalStaff";

import ChangePassword from "layouts/authentication/sign-up/ChangePassword";
import ForgotPassword from "layouts/authentication/sign-up/ForgotPassword";
import FaceToFace from "layouts/pages/book-appointment/FaceToFace";
import RequestCallback from "layouts/pages/book-appointment/RequestCallback";
import AudioVideoConference from "layouts/pages/book-appointment/AudioVideoConference";

// import QualityRegistration from "../src/layouts/pages/quality-registration";

// StudyMaterial--------------------
import StudyMaterial from "../src/layouts/pages/Study-Material/StudyMaterial";

// @mui icons
import Icon from "@mui/material/Icon";
import BookAppointmentList from "layouts/pages/book-appointment/list";
import ComplaintRegister from "layouts/pages/quality-registration/ComplaintRegister";
import RiskRegister from "layouts/pages/quality-registration/RiskRegister";
import ComplianceRegister from "layouts/pages/quality-registration/ComplianceRegister";
import OfficeRiskAssessment from "layouts/pages/quality-registration/OfficeRiskAssessment";
import ContinuosImprovementRegister from "layouts/pages/quality-registration/ContinuosImprovementRegister";
import AppointmentReport from "layouts/pages/reports/appointmentReport";
import AddPatient from "layouts/authentication/sign-up/AddPatient";
import Face2FaceEdit from "layouts/pages/book-appointment/Face2FaceEdit";
import ResetPassword from "layouts/authentication/sign-up/ResetPassword";
import AddProperty from "layouts/Property/AddProperty";
import AssignProperty from "layouts/Property/AssignProperty";
import PropertyList from "layouts/Property/PropertyList";
import BookPropertyList from "layouts/Property/BookPropertyList";
import EditBookProperty from "layouts/Property/EditBookProperty";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";

import VideoInput from "layouts/pages/Study-Material/VideoInput";
import PdfFile from "layouts/pages/Study-Material/PdfFile";
import PropertyInspection from "layouts/Property/PropertyInspection";
const routes = [
  {
    name: "Authentication",
    key: "authentication",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/sign-in/basic",
            component: <SignInBasic />,
          },
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-in/cover",
            component: <SignInCover />,
          },

          {
            name: "Illustration",
            key: "illustration",
            route: "/authentication/sign-in/illustration",
            component: <SignInIllustration />,
          },
        ],
      },

      // {
      //   name: "Signupwork",
      //   key: "Signupwork",
      //   collapse: [
      //     {
      //       name: "Signupwork",
      //       key: "Signupwork",
      //       route: "/authentication/sign-up/Workforvaluecare",
      //       component: <Signupwork />,
      //     },
      //   ],
      // },

      // {
      //   name: "Signupservices",
      //   key: "Signupservices",
      //   collapse: [
      //     {
      //       name: "Signupservices",
      //       key: "Signupservices",
      //       route: "/authentication/sign-up/Valuecareservices",
      //       component: <Signupservices />,
      //     },
      //   ],
      // },

      {
        name: "cover",
        key: "cover",
        collapse: [
          {
            name: "cover",
            key: "cover",
            route: "/authentication/sign-up/Index",
            component: <Cover />,
          },
        ],
      },

      {
        name: "Reset Password",
        key: "reset-password",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/reset-password/cover",
            component: <ResetCover />,
          },
        ],
      },

      {
        type: "collapse",
        name: "Differently Abled",
        key: "DifferentlyAbled",
        route: "authentication/sign-up/SignUpDifferentlyAbled",
        component: <SignUpDifferentlyAbled />,
        // href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
        icon: <Icon fontSize="medium">checking</Icon>,
        noCollapse: true,
      },

      {
        type: "collapse",
        name: "Family Member",
        key: "FamilyMember",
        route: "/authentication/sign-up/FamilyMember",
        component: <FamilyMember />,
        icon: <Icon fontSize="medium">checking</Icon>,
        noCollapse: true,
      },

      {
        type: "collapse",
        name: "Support Cordinator",
        key: "SupportCordinator",
        route: "/authentication/sign-up/SupportCordinator",
        component: <SupportCordinator />,
        icon: <Icon fontSize="medium">checking</Icon>,
        noCollapse: true,
      },

      {
        type: "collapse",
        name: "Value Care Services",
        key: "ValueCareServices",
        route: "/authentication/sign-up/ValueCareServices",
        component: <ValueCareServices />,
        icon: <Icon fontSize="medium">checking</Icon>,
        noCollapse: true,
      },

      {
        type: "collapse",
        name: "Work For ValueCare",
        key: "WorkForValueCare",
        route: "/authentication/sign-up/WorkForValueCare",
        component: <WorkForValueCare />,
        icon: <Icon fontSize="medium">checking</Icon>,
        noCollapse: true,
      },

      {
        type: "collapse",
        name: "Reset Password",
        key: "ResetPassword",
        route: "/authentication/sign-up/ResetPassword",
        component: <ResetPassword />,
        icon: <Icon fontSize="medium">checking</Icon>,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "FaceToFace",
        key: "FaceToFace",
        route: "/pages/book-appointment/FaceToFace",
        component: <FaceToFace />,
        icon: <Icon fontSize="medium">checking</Icon>,
        noCollapse: true,
      },

      {
        type: "collapse",
        name: "RequestCallback",
        key: "RequestCallback",
        route: "/pages/book-appointment/RequestCallback",
        component: <RequestCallback />,
        icon: <Icon fontSize="medium">checking</Icon>,
        noCollapse: true,
      },

      {
        type: "collapse",
        name: "AudioVideoConference",
        key: "AudioVideoConference",
        route: "/pages/book-appointment/AudioVideoConference",
        component: <AudioVideoConference />,
        icon: <Icon fontSize="medium">checking</Icon>,
        noCollapse: true,
      },

      {
        type: "collapse",
        name: "Sub Contractor",
        key: "SubContractor",
        route: "/authentication/sign-up/SubContractor",
        component: <SubContractor />,
        icon: <Icon fontSize="medium">checking</Icon>,
        noCollapse: true,
      },

      {
        type: "collapse",
        name: "Add Patient",
        key: "AddPatient",
        route: "/authentication/sign-up/AddPatient",
        component: <AddPatient />,

        icon: <Icon fontSize="medium">checking</Icon>,
        noCollapse: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "Analytics",
    route: "/dashboards/analytics",
    component: <Analytics />,
    icon: <Icon fontSize="medium">dashboard</Icon>,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Your Zoom Meeting",
    key: "ZoomList",
    route: "/pages/Zoom-Meeting/ZoomList",
    component: <ZoomList />,
    icon: <Icon fontSize="medium">ZoomList</Icon>,
    noCollapse: true,
  },

  // {
  //   type: "collapse",
  //   name: "Book Appointment",
  //   key: "changelog",
  //   route: "/pages/book-appointment/userbookappointment",
  //   component: <UserBookAppointment />,
  //   icon: <Icon fontSize="medium">Book Appointment</Icon>,
  //   noCollapse: true,
  // },

  {
    type: "collapse",
    name: "Book Appointment",
    key: "changelog",
    route: "/pages/book-appointment/UserBookAppointmentToken",
    component: <UserBookAppointmentToken />,
    icon: (
      <Icon fontSize="medium" className="white-icon">
        add_task
      </Icon>
    ),
    noCollapse: true,
  },

  // {
  //   name: "Edit User List",
  //   key: "AppList",
  //   route: "/dashboards/userprofile/edit",
  //   component: <EditUser />,
  //   // href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
  //   icon: <Icon fontSize="medium">AccountBox</Icon>,
  //   noCollapse: true,
  // },

  {
    // type: "collapse",
    name: "Face2FaceEdit",
    key: "Face2FaceEdit",
    route: "/pages/book-appointment/Face2FaceEdit",
    component: <Face2FaceEdit />,
    icon: <Icon fontSize="medium">add_task</Icon>,
    noCollapse: true,
  },

  {
    // type: "collapse",
    name: "Face2FaceEdit",
    key: "Face2FaceEdit",
    route: "/authentication/sign-up/EditInternalStaff",
    component: <EditInternalStaff />,
    icon: <Icon fontSize="medium">add_task</Icon>,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Appointment List",
    key: "BookList",
    route: "/pages/book-appointment/list",
    component: <BookAppointmentList />,
    // href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
    icon: <Icon fontSize="medium">task_alt</Icon>,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "User List",
    key: "List",
    route: "/dashboards/userprofile/Index",
    component: <List />,
    // href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
    icon: <Icon fontSize="medium">account_box</Icon>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Registers",
    key: "QualityRegistration",
    icon: <Icon fontSize="medium">app_registration</Icon>,
    collapse: [
      {
        type: "collapse",
        key: "ComplaintRegister",
        name: "Complaint Register",
        route: "qualityRegister/ComplaintRegister",
        icon: <Icon fontSize="medium">app_registration</Icon>,
        component: <ComplaintRegister />,

        noCollapse: true,
      },
      {
        type: "collapse",
        key: "RiskRegister",
        name: "Risk Register",
        route: "qualityRegister/RiskRegister",
        component: <RiskRegister />,
        // icon: <Icon fontSize="medium">Referal</Icon>,
        noCollapse: true,
      },
      {
        type: "collapse",
        key: "ComplianceRegister",
        name: "Compliance Register",
        route: "qualityRegister/ComplianceRegister",
        component: <ComplianceRegister />,
        // icon: <Icon fontSize="medium">Referal</Icon>,
        noCollapse: true,
      },
      {
        type: "collapse",
        key: "OfficeRiskAssessment",
        name: "Office Risk Assessment",
        route: "qualityRegister/OfficeRiskAssessment",
        component: <OfficeRiskAssessment />,
        // icon: <Icon fontSize="medium">Referal</Icon>,
        noCollapse: true,
      },
      {
        type: "collapse",
        key: "ContinuosImprovementRegister",
        name: "Continuous Improvement Register",
        route: "qualityRegister/ContinuosImprovementRegister",
        component: <ContinuosImprovementRegister />,
        // icon: <Icon fontSize="medium">Referal</Icon>,
        noCollapse: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Reports",
    key: "Reports",
    icon: <Icon fontSize="medium">receipt_long</Icon>,
    collapse: [
      {
        type: "collapse",
        key: "AppointmentReport",
        name: "Appointments",
        route: "Reports/AppointmentReport",
        component: <AppointmentReport />,
        icon: <Icon fontSize="medium">task_alt</Icon>,
        noCollapse: true,
      },
      // {
      //   type: "collapse",
      //   key: "RiskRegister",
      //   name: "Risk Register",
      //   route: "qualityRegister/RiskRegister",
      //   component: <RiskRegister />,
      //   // icon: <Icon fontSize="medium">Referal</Icon>,
      //   noCollapse: true,
      // },
      // {
      //   type: "collapse",
      //   key: "ComplianceRegister",
      //   name: "Compliance Register",
      //   route: "qualityRegister/ComplianceRegister",
      //   component: <ComplianceRegister />,
      //   // icon: <Icon fontSize="medium">Referal</Icon>,
      //   noCollapse: true,
      // },
      // {
      //   type: "collapse",
      //   key: "OfficeRiskAssessment",
      //   name: "Office Risk Assessment",
      //   route: "qualityRegister/OfficeRiskAssessment",
      //   component: <OfficeRiskAssessment />,
      //   // icon: <Icon fontSize="medium">Referal</Icon>,
      //   noCollapse: true,
      // },
      // {
      //   type: "collapse",
      //   key: "ContinuosImprovementRegister",
      //   name: "Continuos Improvement Register",
      //   route: "qualityRegister/ContinuosImprovementRegister",
      //   component: <ContinuosImprovementRegister />,
      //   // icon: <Icon fontSize="medium">Referal</Icon>,
      //   noCollapse: true,
      // },
    ],
  },

  // type: "collapse",
  // name: "Refer",
  // key: "brooklyn-alice",
  // icon: <Icon fontSize="medium">Referal</Icon>,
  // // icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
  // collapse: [
  //   {
  //     type: "collapse",
  //     name: "Share Referal",
  //     key: "AllProjects",
  //     route: "layouts/referal/Index",
  //     component: <AllProjects />,
  //     // href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
  //     icon: <Icon fontSize="medium">Referal</Icon>,
  //     noCollapse: true,
  //   },

  {
    type: "collapse",
    name: "Contact Us",
    key: "ContactList",
    route: "/pages/contact-us/list",
    component: <ContactusList />,
    // href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
    icon: <Icon fontSize="medium">contact_page</Icon>,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Internal Staff",
    key: "InternalStaff",
    route: "/authentication/sign-up/InternalStaff",
    component: <InternalStaff />,
    icon: <Icon fontSize="medium">supervisor_account</Icon>,
    noCollapse: true,
  },

  {
    name: "Edit Appointment List",
    key: "AppList",
    route: "/pages/book-appointment/edit",
    component: <EditBookAppointment />,
    // href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
    icon: <Icon fontSize="medium">AppList</Icon>,
    noCollapse: true,
  },

  {
    name: "Edit User List",
    key: "AppList",
    route: "/dashboards/userprofile/edit",
    component: <EditUser />,
    // href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
    icon: <Icon fontSize="medium">AccountBox</Icon>,
    noCollapse: true,
  },

  {
    name: "Change Password",
    key: "AppList",
    route: "/authentication/sign-up/ChangePassword",
    component: <ChangePassword />,
    // href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
    icon: <Icon fontSize="medium">Change Password</Icon>,
    noCollapse: true,
  },

  {
    name: "Change Password",
    key: "AppList",
    route: "/authentication/sign-up/ForgotPassword",
    component: <ForgotPassword />,
    // href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
    icon: <Icon fontSize="medium">Change Password</Icon>,
    noCollapse: true,
  },

  {
    name: "Edit Contact List",
    key: "EditList",
    route: "/pages/contact-us/edit",
    component: <EditContact />,
    // href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
    icon: <Icon fontSize="medium">EditList</Icon>,
    noCollapse: true,
  },

  {
    name: "Book Appointment",
    key: "BookWithoutLogin",
    route: "/pages/book-appointment/index",
    component: <BookAppointment />,
    icon: (
      <Icon className="white-icon" fontSize="medium">
        BookWithoutLogin
      </Icon>
    ),
    noCollapse: true,
  },

  {
    name: "Referal Edit",
    key: "refEdit",
    route: "layouts/referal/refEdit",
    component: <RefEdit />,
    // href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",

    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Referral",
    key: "brooklyn-alice",
    // icon: <Icon fontSize="medium">group_add</Icon>,
    icon: (
      <Icon fontSize="medium">
        <PermContactCalendarIcon />
      </Icon>
    ),

    // icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      {
        type: "collapse",
        name: "Share Referral",
        key: "AllProjects",
        route: "layouts/referal/Index",
        component: <AllProjects />,
        // href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
        icon: <Icon fontSize="medium">task_alt</Icon>,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "Referral List",
        key: "Referallist",
        route: "layouts/referal/Referallist",
        component: <Referallist />,
        // href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
        icon: <Icon fontSize="medium">task_alt</Icon>,
        noCollapse: true,
      },
    ],
  },

  {
    type: "collapse",
    name: "Property",
    key: "Property",
    // icon: <Icon fontSize="medium">group_add</Icon>,
    icon: (
      <Icon fontSize="medium">
        <ApartmentIcon />
      </Icon>
    ),

    // icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      {
        type: "collapse",
        name: "Add Property",
        key: "AddProperty",
        route: "layouts/Property/AddProperty",
        component: <AddProperty />,
        // href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
        icon: <Icon fontSize="medium">task_alt</Icon>,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "Property List",
        key: "PropertyList",
        route: "layouts/Property/PropertyList",
        component: <PropertyList />,
        // href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
        icon: <Icon fontSize="medium">task_alt</Icon>,
        noCollapse: true,
      },
      {
        type: "collapse",
        // name: "Assign Property",
        name: "Property Inspection Request",
        key: "AssignProperty",
        route: "layouts/Property/AssignProperty",
        component: <AssignProperty />,
        // href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
        icon: <Icon fontSize="medium">task_alt</Icon>,
        noCollapse: true,
      },
      {
        type: "collapse",
        // name: "Assign Property List",
        name: "Property Inspection List",
        key: "BookPropertyList",
        route: "layouts/Property/BookPropertyList",
        component: <BookPropertyList />,
        // href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
        icon: <Icon fontSize="medium">task_alt</Icon>,
        noCollapse: true,
      },
      // {
      //   type: "collapse",
      //   // name: "Assign Property List",
      //   name: "New Property Inspection",
      //   key: "PropertyInspection",
      //   route: "layouts/Property/PropertyInspection",
      //   component: <PropertyInspection />,
      //   // href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
      //   icon: <Icon fontSize="medium">task_alt</Icon>,
      //   noCollapse: true,
      // },
    ],
  },
  {
    name: "Edit Book Property",
    key: "EditBookProperty",
    route: "layouts/Property/EditBookProperty",
    component: <EditBookProperty />,
    icon: <Icon fontSize="medium">task_alt</Icon>,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Study Material",
    key: "StudyMaterial",
    route: "/study/material",
    component: <StudyMaterial />,
    icon: (
      <Icon fontSize="medium">
        <LocalLibraryIcon />
      </Icon>
    ),
    noCollapse: true,
  },
  {
    name: "VideoInput",
    key: "VideoInput",
    route: "/video-streaming",
    component: <VideoInput />,
    icon: <Icon fontSize="medium">task_alt</Icon>,
    noCollapse: true,
  },
  {
    name: "PdfFile",
    key: "PdfFile",
    route: "/PdfFile",
    component: <PdfFile />,
    icon: <Icon fontSize="medium">task_alt</Icon>,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Add Property",
  //   key: "AddProperty",
  //   route: "layouts/Property/AddProperty",
  //   component: <AddProperty />,
  //   icon: <Icon fontSize="medium">supervisor_account</Icon>,
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Assign Property",
  //   key: "AssignProperty",
  //   route: "layouts/Property/AssignProperty",
  //   component: <AssignProperty />,
  //   icon: <Icon fontSize="medium">supervisor_account</Icon>,
  //   noCollapse: true,
  // },
];

export default routes;
