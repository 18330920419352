import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { CommonConfig } from "constant";
import Card from "@mui/material/Card";
import "./styles.css";
import { pdfjs } from "react-pdf";
import samplenew from "../../../assets/studyMaterial/newSample.pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const PDFJS = window.pdfjsLib;




export default function PdfFile() {
  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  // pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  const { state } = useLocation();
  let pdfDetails = state ? state?.data : null;
  // let pdfUrl = state ? state.File : null;
  //valuecare.cognisun.net/Backend/documents/video/1678087392473-Development.mp4.mp4
  // https://valuecare.cognisun.net:9595/api/documents/video/1677850708613-document (2).pdf
  console.log("retrievedpdfDetails", CommonConfig.dir + "video/" + pdfDetails);
  console.log("retrievedpdfDetails12", pdfDetails);
  console.log("Checkpdfinfooo", samplenew);
  var pdfUrl =  pdfDetails;
  console.log("checkvariable", pdfUrl);
  const [images, setImages] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageRendering, setPageRendering] = React.useState("");
  const [height, setHeight] = React.useState(0);
  const [width, setWidth] = React.useState(0);
  // const [numPages, setNumPages] = useState(null);

  // testing new---------------------
  const [numPages, setNumPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setpageNumber] = useState(0);
  const [urlData, setUrlData] = useState(null);

  console.log("psfUrLChecking", urlData);

  function onDocumentLoadSuccess({ numPages }) {
    console.log("=================Inside function=================",numPages)
    // setNumPages(numPages);
    // setLoading(false);
  }

  console.log("---------------",numPages)
  useEffect(() => {
    setLoading(false);
  }, [numPages]);  




   console.log("PdfUrl========================",pdfUrl)

  const loadingTask = pdfjs.getDocument(pdfUrl);

  loadingTask.promise.then((pdf) => {
    const numPages = pdf.numPages;
    setNumPages(numPages);
    console.log('Number of pages:', numPages);
  }).catch((error) => {
    console.error('Error while loading PDF:', error);
  });
  const iframeHeight = numPages ? `${numPages * 1070}px` : '3600vh';
  const ih = '1800vh'
  console.log("=====================",iframeHeight)
  function changePage() {
    setCurrentPage();
  }

  useEffect(() => {
    setImages(pdfDetails);
    setPageRendering(false);
  }, [pdfDetails]);

  useEffect(() => {
    setUrlData(CommonConfig.dir + "video/" + pdfDetails);
    // eslint-disable-next-line
  }, []);

  const nextPages = () => {
    if (pageNumber < numPages) {
      setpageNumber(pageNumber + 1);
    }
  };
  const prevPages = () => {
    if (pageNumber > 1) {
      setpageNumber(pageNumber - 1);
    }
  };

  document.addEventListener(
    "contextmenu",
    function (e) {
      e.preventDefault();
    },
    false
  );

    document.addEventListener('contextmenu', event => event.preventDefault());
    
  return (
    <>
      <DashboardLayout>
        <div className="wrap"  >
          <div className="controls">
            <button onClick={prevPages} disabled={pageNumber === 1}>
              prev
            </button>
            <button onClick={nextPages} disabled={pageNumber === numPages}>
              next
            </button>
          </div>






              <div>
              {loading && <div>Loading PDF...</div>}
              <iframe
                className="contextmenu"
                // id="contextmenu"
                name="contextmenu"
                // onLoad={disableRightclick(pdfUrl)}
                onLoad={onDocumentLoadSuccess}
                // oncontextmenu="return false;"
                src={pdfUrl + "#toolbar=0&navpanes=0&scrollbar=0"}

                onContextMenu={(e) => e.preventDefault()}
                title="PDF in an i-Frame"
                frameborder="0"
                style={{ height: iframeHeight }}
              ></iframe>
              </div>


        </div>

      </DashboardLayout>
    </>
  );
}
