/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import React, { useState, useEffect } from "react";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "examples/Cards/BookingCard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import VideoCallIcon from '@mui/icons-material/VideoCall';

// Anaytics dashboard components
import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";

// Images
import booking1 from "assets/images/products/product-1-min.jpg";
import booking2 from "assets/images/products/product-2-min.jpg";
import booking3 from "assets/images/products/product-3-min.jpg";
import MDBadgeDot from "components/MDBadgeDot";
import MDButton from "components/MDButton";
import { CommonConfig } from "constant";
import axios from "axios";
import PieChart from "examples/Charts/PieChart";
import Card from "@mui/material/Card";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import defaultLineChartData from "layouts/dashboards/sales/data/defaultLineChartData";
import ChannelsChart from "layouts/dashboards/sales/components/ChannelsChart";
import { Button as PButton } from "primereact/button";
import cogoToast from "cogo-toast";
import moment from "moment";
function Analytics() {

  if (localStorage.getItem("userData")) {
    // alert("Hello");
    const loginUser = JSON.parse(localStorage.getItem("userData"));
    var loginUserName = loginUser.FirstName + " " + loginUser.LastName;
    var PersonType = loginUser.PersonType;
    console.log("Testing12 ", loginUserName);
  }




  const paginatorLeft = <PButton type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <PButton type="button" icon="pi pi-cloud" className="p-button-text" />;
  const { sales, tasks } = reportsLineChartData;
  const [pieData, setPieData] = useState({});
  const [pieDataContact, setPieDataContact] = useState({});
  const [pieDataComplaint, setPieDataComplaint] = useState({});
  const [pieDataProperty, setPieDataProperty] = useState({});
  const [initialPieData, setInitialPieData] = useState();
  const [initialPieDataContact, setInitialPieDataContact] = useState();
  const [graphdata, setgraphdata] = useState(false);
  const [graphdataContact, setgraphdataContact] = useState(false);
  const [graphdataComplaint, setgraphdataComplaint] = useState(false);
  const [graphdataProperty, setgraphdataProperty] = useState(false);
  const [data, setData] = useState(null);
  const [loginUser, setLoginUser] = useState(null);

  debugger;

  var newDateTime = (new Date().toISOString())
  console.log("checktodaydate", newDateTime)

  const getfillterDAte = data?.filter(x => x.AppointmentDate > newDateTime);
  console.log("getfillterDAte", getfillterDAte)




  useEffect(() => {
    getSheduleMeetingList();
    getPieData();
    getContactPieData();
    getComplaintPieData();
    getPropertyPieData();
  }, []);
  var columns = [];

  columns = [
    {
      name: "Appointment ID",
      selector: "AppointmentNumber",
      filter: true,
      sortable: true,
    },


    {
      name: "Client Name",
      selector: "PatientName",
      filter: true,
      sortable: true,
    },

    // {
    //   name: "Meeting Time",      
    //   selector: "AppointmentDate",
    //   filter: true,
    //   sortable: true,
    // },


    {
      name: "Meeting Time",
      body: (data) => {
        if (data.AppointmentDate) {
          // return moment(data.StartDate).format("hh:mm a");
          return moment(data.AppointmentDate).utc().format("DD-MM-YYYY hh:mm A");
        }
        return "";
      },
      selector: "AppointmentDate",
      filter: true,
      sortable: true,
    },

    {
      name: "Action",
      body: (data) => actionTemplate(data),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];


  const actionTemplate = (row) => {
    return (
      <div>
        <PButton
          type="button"
          icon="pi pi-video"
          className="p-button-primary"
          onClick={() => MeetingJoin(row)}
          style={{ marginRight: ".5em" }}
          tooltip="Join Meeting"
          tooltipOptions={{ position: "bottom" }}
        />
        {CommonConfig.isInternalStaff() === 1 ? (
          <PButton
            type="button"
            className="p-button-danger"
            icon="pi pi-user-edit"
            onClick={() => RemoveMeeting(row)}
            tooltip="Remove Meeting"
            tooltipOptions={{ position: "bottom" }}

          />

        ) : null}
      </div>
    );
  };

  const getColoumnsRow = () =>
    columns.map((item, id) => {
      return (
        <Column
          style={{ minWidth: "200px" }}
          key={id}
          sortable={item.sortable}
          body={item?.body}
          filter={item.filter}
          field={item.selector}
          header={item.name}
        />
      );
    });


  // const MeetingJoin = (row) => {
  //   console.log("rowwww", row.ZoommeetingURL);
  //   window.open(row.ZoommeetingURL)
  // }
  const MeetingJoin = (row) => {
    console.log("rowwww", row);
    if (CommonConfig.isInternalStaff() === 1 || PersonType == "INTERNAL STAFF") {
      window.open(row.ZoommeetinghostURL)
    }
    else {
      window.open(row.ZoommeetingURL)
    }
  }




  const RemoveMeeting = (row) => {
    console.log("rowdatata", row.id);

    let formattedData = {
      meetingId: row.ZoommeetingID,
    };
    console.log("formattedDataDEl", formattedData);
    axios({
      url: CommonConfig.ZoomUrl + "delete/meeting/",
      method: "DELETE",
      headers: {
      },
      data: formattedData,
    })
      .then((res) => {
        console.log("ressss", res);
        cogoToast.success("Meeting Removed Successfully.")
        getSheduleMeetingList()

      })
      .catch((error) => {
        console.log(error);
      });

  }


  const getSheduleMeetingList = () => {
    debugger
    let formattedData = {
      CurrentUser: CommonConfig.getCurrentUserId()
    };
    axios({
      url: CommonConfig.ApiUrl + "Appointment/getBookAppointmentList",
      method: "POST",
      headers: {

      },
      data: formattedData,
    })
      .then((res) => {
        console.log("res.data.data", res.data.data);
        const getFilterMeetingData = res.data.data?.filter(x => x.ZoommeetingID);
        console.log("getFilterMeetingData", getFilterMeetingData);

        var newDateTime = (new Date().toISOString())
        const getFinalFilterMeetingData = getFilterMeetingData?.filter(x => x.AppointmentDate > newDateTime);



        if (PersonType == "INTERNAL STAFF") {
          const getFilterMeetingDataByMannagedBy = res.data.data?.filter(x => x.ManagedByName === loginUserName);
          setData(getFilterMeetingDataByMannagedBy)
        } else {
          setData(getFinalFilterMeetingData)
        }


      })
      .catch((error) => {
        console.log(error);
      });
  };


  // const getSheduleMeetingList = () => {
  //   debugger
  //   let formattedData = {
  //     CurrentUser: CommonConfig.getCurrentUserId()
  //     // CurrentUser:"naveen.cognisun@gmail.com",
  //   };
  //   axios({
  //     // Endpoint to send files
  //     url: CommonConfig.ApiUrl + "Appointment/getBookAppointmentList",
  //     method: "POST",
  //     headers: {
  //       // Add any auth token here
  //       //authorization: "your token comes here",
  //     },
  //     data: formattedData,
  //   })
  //     .then((res) => {
  //       // if (res.data?.success) {
  //           setData(res.data.data)
  //          console.log("res.data.data",res);
  //       //   // var loginUserName = loginUser.FirstName + " " + loginUser.LastName;
  //       //   // var PersonType = loginUser.PersonType;
  //       //   // if (PersonType == "INTERNAL STAFF") {

  //       //   //   var tempstore = [];
  //       //   //   for (var i = 0; i < res.data.data.length; i++) {
  //       //   //     if (res.data.data[i].ManagedByName === loginUserName) {
  //       //   //       tempstore.push(res.data.data[i]);
  //       //   //     }
  //       //   //   }
  //       //   //   setData(tempstore || []);
  //       // } else {

  //       //     setData(res.data.data || []);
  //       //     // var data = res.data.data;
  //       //     // console.log("data...", data);
  //       //     // var pendingcount = 0;
  //       //     // var completedcount = 0;
  //       //     // var inprogresscount = 0;
  //       //     // var Canceledcount = 0;
  //       //     // data.map((value) => {
  //       //     //   if (value.Status == "Pending") {
  //       //     //     pendingcount++;
  //       //     //   } else if (value.Status == "Completed") {
  //       //     //     completedcount++;
  //       //     //   } else if (value.Status == "In Progress") {
  //       //     //     inprogresscount++;
  //       //     //   } else if (value.Status == "Canceled") {
  //       //     //     Canceledcount++;
  //       //     //   }
  //       //     // });

  //       //     // var datalist = [];

  //       //     // datalist.push({ Name: "pending", count: pendingcount });
  //       //     // datalist.push({ Name: "Completed", count: completedcount });
  //       //     // datalist.push({ Name: "Progress", count: inprogresscount });
  //       //     // datalist.push({ Name: "Canceled", count: Canceledcount });
  //       //     // console.log("datalist", datalist);
  //       //     // const label = [];
  //       //     // const datacount = [];
  //       //     // for (var i of datalist) {
  //       //     //   label.push(i.Name);
  //       //     //   datacount.push(i.count);
  //       //     // }
  //       //     // setPieData({
  //       //     //   datasets: [
  //       //     //     {
  //       //     //       data: datacount,
  //       //     //       backgroundColor: CommonConfig.chartColors,
  //       //     //       hoverBackgroundColor: CommonConfig.chartColors,
  //       //     //     },
  //       //     //   ],
  //       //     //   labels: label,
  //       //     // });
  //       //   }


  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };



  const getPieData = () => {
    let formattedData = {
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    setgraphdata(false)
    axios({
      url: CommonConfig.ApiUrl + "Appointment/getAppointmentPieData",
      method: "POST",
      headers: {
      },
      data: formattedData,
    })
      .then((res) => {
        setInitialPieData(res.data.data[0]);
        const label = [];
        const data = [];
        console.log("Wel = ",res.data.data[0])
        for (var i of res.data.data[0]) {
          label.push(i.Name);
          data.push(i.CountNumber)
          console.log("Test = 1414" , i.CountNumber);
          if(i.CountNumber > 0){
            setgraphdata(true)
            console.log("UIN = ", graphdata);
            
          }
        }
        console.log("graphdata = ",graphdata);
        setPieData(
          {
            datasets: {
              data: data,
              backgroundColors: [
                "info", "success", "secondary", "warning", "error",
              ]
            },
            labels: label,
          }
        )
        console.log("testing = ", pieData)
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const getContactPieData = () => {
    let formattedData = {
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    setgraphdataContact(false)
    axios({
      url: CommonConfig.ApiUrl + "Appointment/getContactUsPieData",
      method: "POST",
      headers: {
      },
      data: formattedData,
    })
      .then((res) => {
        setInitialPieDataContact(res.data.data[0]);
        const label = [];
        const data = [];
        console.log("Wel = ",res.data.data[0])
        for (var i of res.data.data[0]) {
          label.push(i.Name);
          data.push(i.CountNumber)
          console.log("Test = 1414" , i.CountNumber);
          debugger
          if(i.CountNumber > 0){
            setgraphdataContact(true)
            console.log("UIN = ", graphdata);
            // break;
          }
        }
        console.log("graphdata = ",graphdata);
        setPieDataContact(
          {
            datasets: {
              data: data,
              backgroundColors: [
                "info", "success", "secondary", "warning", "error",
              ]
            },
            labels: label,
          }
        )
        console.log("testing = ", pieData)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getComplaintPieData = () => {
    let formattedData = {
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    setgraphdataContact(false)
    axios({
      url: CommonConfig.ApiUrl + "Appointment/getComplaintPieData",
      method: "POST",
      headers: {
      },
      data: formattedData,
    })
      .then((res) => {
        // setInitialPieDataContact(res.data.data[0]);
        const label = [];
        const data = [];
        console.log("Wel = ",res.data.data[0])
        for (var i of res.data.data[0]) {
          label.push(i.Name);
          data.push(i.CountNumber)
          console.log("Test = 1414" , i.CountNumber);
          debugger
          if(i.CountNumber > 0){
            setgraphdataComplaint(true)
            console.log("UIN = ", graphdata);
            // break;
          }
        }
        console.log("graphdata = ",graphdata);
        setPieDataComplaint(
          {
            datasets: {
              data: data,
              backgroundColors: [
                "info", "secondary", "success", "error"
              ]
            },
            labels: label,
          }
        )
        console.log("testing = ", pieData)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPropertyPieData = () => {
    let formattedData = {
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    setgraphdataContact(false)
    axios({
      url: CommonConfig.ApiUrl + "Appointment/getPropertyPieData",
      method: "POST",
      headers: {
      },
      data: formattedData,
    })
      .then((res) => {
        // setInitialPieDataContact(res.data.data[0]);
        const label = [];
        const data = [];
        console.log("Wel = ",res.data.data[0])
        for (var i of res.data.data[0]) {
          label.push(i.Name);
          data.push(i.CountNumber)
          console.log("Test = 1414" , i.CountNumber);
          debugger
          if(i.CountNumber > 0){
            setgraphdataProperty(true)
            console.log("UIN = ", graphdata);
            // break;
          }
        }
        console.log("graphdata152 = ",data);
        setPieDataProperty(
          {
            datasets: {
              data: data,
              backgroundColors: [
                "info", "secondary", "success", "error"
              ]
            },
            labels: label,
          }
        )
        console.log("testing = ", pieData)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  

  // Action buttons for the BookingCard
  const actionButtons = (
    <>
      <Tooltip title="Refresh" placement="bottom">
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">refresh</Icon>
        </MDTypography>
      </Tooltip>
      <Tooltip title="Edit" placement="bottom">
        <MDTypography variant="body1" color="info" lineHeight={1} sx={{ cursor: "pointer", mx: 3 }}>
          <Icon color="inherit">edit</Icon>
        </MDTypography>
      </Tooltip>
    </>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={4}>
            <Card sx={{ height: "100%" }}>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                <MDTypography variant="h6">Appointments</MDTypography>
                <Tooltip title="See Appointment based on status" placement="bottom" arrow>
                  <MDButton variant="outlined" color="secondary" size="small" circular iconOnly>
                    <Icon>priority_high</Icon>
                  </MDButton>
                </Tooltip>
              </MDBox>
              <MDBox mt={3}>
              {/* {graphdata == true ? ( */}
                <Grid container alignItems="center">
                <Grid item xs={7}>
                {graphdata == true ? (
                  <PieChart chart={pieData} height="12.5rem" />
                ):
                <p className="nodata">There is no appointment</p>  
              }
                  
                </Grid>
                <Grid item xs={5}>
                  <MDBox pr={1}>
                    <MDBox mb={1}>
                      <MDBadgeDot color="info" size="medium" badgeContent="Pending" />
                    </MDBox>
                    <MDBox mb={1}>
                      <MDBadgeDot color="success" size="sm" badgeContent="Completed" />
                    </MDBox>
                    {/* <MDBox mb={1}>
                            <MDBadgeDot color="secondary" size="sm" badgeContent="Assigned" />
                          </MDBox> */}
                    <MDBox mb={1}>
                      <MDBadgeDot color="secondary" size="sm" badgeContent="In Progress" />

                    </MDBox>
                    <MDBox mb={1}>
                      <MDBadgeDot color="warning" size="sm" badgeContent="Canceled" />
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
              {/* ): 
              
              <p>There is no appointment now</p>
              } */}
                
              </MDBox>
              <MDBox
                pt={4}
                pb={2}
                px={2}
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                mt="auto"
              >
               
              </MDBox>
              
            </Card>

            
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
          <Card sx={{ height: "100%" }}>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                <MDTypography variant="h6">Contact us</MDTypography>
                <Tooltip title="See Contact us based on status" placement="bottom" arrow>
                  <MDButton variant="outlined" color="secondary" size="small" circular iconOnly>
                    <Icon>priority_high</Icon>
                  </MDButton>
                </Tooltip>
              </MDBox>
              <MDBox mt={3}>
              {/* {graphdata == true ? ( */}
                <Grid container alignItems="center">
                <Grid item xs={7}>
                {graphdataContact == true ? (
                  <PieChart chart={pieDataContact} height="12.5rem" />
                ):
                <p className="nodata">There is no Contact</p>  
              }
                  
                </Grid>
                <Grid item xs={5}>
                  <MDBox pr={1}>

                    <MDBox mb={1}>
                      <MDBadgeDot color="info" size="sm" badgeContent="New" />
                    </MDBox>

                    <MDBox mb={1}>
                      <MDBadgeDot color="success" size="sm" badgeContent="Complete" />
                    </MDBox>
                    
                    <MDBox mb={1}>
                      <MDBadgeDot color="secondary" size="medium" badgeContent="Pending" />
                    </MDBox>

                    <MDBox mb={1}>
                            <MDBadgeDot color="warning" size="sm" badgeContent="In Progress" />
                          </MDBox>
                    <MDBox mb={1}>
                      <MDBadgeDot color="error" size="sm" badgeContent="Closed" />

                    </MDBox>
                    

                    
                  </MDBox>
                </Grid>
              </Grid>
              {/* ): 
              
              <p>There is no appointment now</p>
              } */}
                
              </MDBox>
              <MDBox
                pt={4}
                pb={2}
                px={2}
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                mt="auto"
              >
               
              </MDBox>
              
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
          <Card sx={{ height: "100%" }}>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                <MDTypography variant="h6">Complaint Register</MDTypography>
                <Tooltip title="See Complaint Register based on status" placement="bottom" arrow>
                  <MDButton variant="outlined" color="secondary" size="small" circular iconOnly>
                    <Icon>priority_high</Icon>
                  </MDButton>
                </Tooltip>
              </MDBox>
              <MDBox mt={3}>
              {/* {graphdata == true ? ( */}
                <Grid container alignItems="center">
                <Grid item xs={7}>
                {graphdataComplaint == true ? (
                  <PieChart chart={pieDataComplaint} height="12.5rem" />
                ):
                <p className="nodata">There is no Complaint Register</p>  
              }
                  
                </Grid>
                <Grid item xs={5}>
                  <MDBox pr={1}>

                    <MDBox mb={1}>
                      <MDBadgeDot color="info" size="sm" badgeContent="In Progress" />
                    </MDBox>

                    <MDBox mb={1}>
                      <MDBadgeDot color="secondary" size="sm" badgeContent="Pending" />
                    </MDBox>
                    
                    <MDBox mb={1}>
                      <MDBadgeDot color="success" size="medium" badgeContent="Completed" />
                    </MDBox>

                    <MDBox mb={1}>
                      <MDBadgeDot color="error" size="sm" badgeContent="Closed" />

                    </MDBox>
                    
                    
                    
                  </MDBox>
                </Grid>
              </Grid>
              {/* ): 
              
              <p>There is no appointment now</p>
              } */}
                
              </MDBox>
              <MDBox
                pt={4}
                pb={2}
                px={2}
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                mt="auto"
              >
               
              </MDBox>
              
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
          <Card sx={{ height: "100%" }}>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                <MDTypography variant="h6">Property Inspections</MDTypography>
                <Tooltip title="See Property Inspections based on status" placement="bottom" arrow>
                  <MDButton variant="outlined" color="secondary" size="small" circular iconOnly>
                    <Icon>priority_high</Icon>
                  </MDButton>
                </Tooltip>
              </MDBox>
              <MDBox mt={3}>
              {/* {graphdata == true ? ( */}
                <Grid container alignItems="center">
                <Grid item xs={7}>
                {graphdataProperty == true ? (
                  <PieChart chart={pieDataProperty} height="12.5rem" />
                ):
                <p className="nodata">There is no Property Inspections</p>  
              }
                  
                </Grid>
                <Grid item xs={5}>
                  <MDBox pr={1}>

                    <MDBox mb={1}>
                      <MDBadgeDot color="info" size="sm" badgeContent="Pending" />
                    </MDBox>

                    <MDBox mb={1}>
                      <MDBadgeDot color="secondary" size="sm" badgeContent="Assigned" />
                    </MDBox>
                    
                    <MDBox mb={1}>
                      <MDBadgeDot color="success" size="medium" badgeContent="Completed" />
                    </MDBox>
                    
                  </MDBox>
                </Grid>
              </Grid>
              {/* ): 
              
              <p>There is no appointment now</p>
              } */}
                
              </MDBox>
              <MDBox
                pt={4}
                pb={2}
                px={2}
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                mt="auto"
              >
               
              </MDBox>
              
            </Card>
          </Grid>

        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}


export default Analytics;
