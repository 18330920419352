import "assets/css/style.css";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import cogoToast from "cogo-toast";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import axios from "axios";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CardContent from "@mui/material/CardContent";
import { CommonConfig } from "constant";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";

// add by akm
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel,
} from "@mui/material";
import MDButton from "components/MDButton";


function ContactusList(props) {
  let navigate = useNavigate();
  let userData = localStorage.getItem("userData");
  if (localStorage.getItem("userData")) {
    // alert("Hello");
  } else {
    // alert("Hello2")
    navigate("/");
  }

  const getData = () => {
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "authentication/contactList",
      method: "GET",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
    })
      .then((res) => {
        if (res.data.success === true) {
          console.log("liiiii", res);
          debugger;
          setData(res.data?.Data?.[0] || {});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [data, setData] = useState([]);

  // add by akm
  const [selectedMediumType, setSelectedMediumType] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [appointmentdelID, setappointmentdelID] = useState(null);

  const CancelDelete = () => {
    setSelectedMediumType("");
    setOpenDialog(false);
  };

  useEffect(() => {
    axios({
      url: CommonConfig.ApiUrl + "authentication/contactList",
      method: "GET",
      headers: {},
    })
      .then((res) => {
        if (res.data.success === true) {
          console.log("uuuu.....", res);
          setData(res.data.data);
        }
        console.log("testttttt", res.data.data[0]);
      })

      // Catch errors if any
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const EditData = (selectedRow = {}) => {
    navigate("/pages/contact-us/edit", {
      state: { selectedRow },
    });
    // console.log("hello",typeof selectedRow)
    // localStorage.setItem("ContactData", JSON.stringify(selectedRow));
    // localStorage.setItem("ContactData",selectedRow.ContactId)
    // navigate("/pages/contact-us/edit");
    // state: { selectedRow },
  };

  const selectedRow = (selectedRow = {}) => {
    debugger;
    setappointmentdelID(selectedRow.ContactId);

    console.log("........,new", appointmentdelID);
    setSelectedMediumType(false);
    setOpenDialog(true);
  };

  const deleteUser = (selectedRow = {}) => {
    // const {AppointmentId, EntityID}=inputData;
    console.log("checkdata124", selectedRow);
    let inputData = {
      ContactId: selectedRow.ContactId,

      // SecurityUserId:selectedRow.EntityID,
      // AddressId:selectedRow.AddressId
    };

    if (selectedMediumType == "Yes") {
      debugger
      document.getElementById("ServicesError").style.display = "none";
      let inputData = {
        ReferralId: appointmentdelID,
      };
      console.log("inputData...", inputData);
      axios({
        // Endpoint to send files
        url: CommonConfig.ApiUrl + "Contact/DeleteContact",
        method: "POST",
        headers: {},
        // Attaching the form data
        data: inputData,
      })
        //   Handle the response from backend here
        .then((res) => {
          if (res.data?.success) {
            console.log("test22", res.data);
            // getData();
            cogoToast.success("Deleted Successfully.");
              setOpenDialog(false);
              window.location.reload();
              getData();
          }
        })
        // Catch errors if any
        .catch((error) => {
          console.log(error);
        });
    } else if (selectedMediumType == "No") {
      document.getElementById("ServicesError").style.display = "none";
      setOpenDialog(false);
    } else {
      document.getElementById("ServicesError").style.display = "block";
    }

    console.log(inputData);

    // axios({
    //   // Endpoint to send files
    //   url: CommonConfig.ApiUrl + "Contact/contactDelete",
    //   method: "POST",
    //   headers: {},
    //   // Attaching the form data
    //   data: inputData,
    // })
    //   //   Handle the response from backend here
    //   .then((res) => {
    //     if (res.data?.success) {
    //       console.log("test22", res.data);
    //       getData();
    //     }
    //   })
    //   // Catch errors if any
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  const deleteData = (selectedRow = {}) => {
    // const {AppointmentId, EntityID}=inputData;
    console.log("checkdata124", selectedRow);
    let inputData = {
      ContactId: selectedRow.ContactId,
      // SecurityUserId:selectedRow.EntityID,
      // AddressId:selectedRow.AddressId
    };
    console.log(inputData);

    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "Contact/contactDelete",
      method: "POST",
      headers: {},
      // Attaching the form data
      data: inputData,
    })
      //   Handle the response from backend here
      .then((res) => {
        if (res.data?.success) {
          console.log("test22", res.data);
          getData();
        }
      })
      // Catch errors if any
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    {
      name: "Sr.No",
      cell: (row, index) => index + 1,
      width: "80px",
    },

    {
      name: "Name",
      selector: "Name",
      sortable: true,
      width: "150px",
    },
    {
      name: "Email",
      selector: "Email",
      sortable: true,
      width: "250px",
    },
    {
      name: "Phone",
      selector: "Phone",
      sortable: true,
      width: "200px",
    },
    {
      name: "Message",
      selector: "Message",
      sortable: true,
      width: "200px",
    },

    {
      name: "Action",
      cell: (row) => (
        <button className="btn btn-primary" onClick={() => selectedRow(row)}>
          Delete
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "60px",
    },
    {
      cell: (row) => (
        <button className="btn btn-danger" onClick={() => EditData(row)}>
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "60px",
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ width: "100%", mt: 3 }}>
        <MDBox display="flex">
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="primary"
            color="white"
            shadow="md"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <Icon fontSize="medium" color="inherit">
              contact_page
            </Icon>
          </MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            Contact Us
          </MDTypography>
        </MDBox>
        <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
          <div className="table-custom">
            <DataTable
              data={data}
              minRows={0}
              filterable
              // defaultFilterMethod={}
              resizable={false}
              columns={columns}
              defaultPageSize={10}
              showPaginationBottom={true}
              className="-striped -highlight chatMgtList"
            />
          </div>
        </MDBox>
      </Card>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="xs">
        <DialogTitle>Remove Contact</DialogTitle>
        <DialogContent>
          <Grid item md="12">
            <div className="reg_input check_value mb-0">
              <InputLabel className="reg_lable mt-5 mb-2">
                Are You Sure to Remove this Contact?
              </InputLabel>
              <div>
                <span class="servicesError" id="ServicesError">
                  Please select any one option.
                </span>
              </div>
              <RadioGroup
                aria-label="remove"
                name="Remove"
                row="true"
                onChange={(e) => setSelectedMediumType(e.target.value)}
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio checked={selectedMediumType === "Yes" ? true : false} />}
                  label="Yes"
                  name="Remove"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio checked={selectedMediumType === "No" ? true : false} />}
                  label="No"
                  name="Remove"
                />
              </RadioGroup>
              <span class="errorTC" id="ServicesError">
                Please select any one service
              </span>
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton variant="contained" color="secondary" onClick={() => CancelDelete()}>
            Back
          </MDButton>
          <MDButton
            variant="contained"
            color="primary"
            onClick={() => {
              deleteUser();
            }}
          >
            Ok
          </MDButton>
        </DialogActions>
      </Dialog>

    </DashboardLayout>
  );
}

export default ContactusList;
