import React, { useEffect, useMemo, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller, set } from "react-hook-form";
import cogoToast from "cogo-toast";
import axios from "axios";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
import Multiselect from "multiselect-react-dropdown";
import PhoneInput from "react-phone-input-2";
import MDDatePicker from "components/MDDatePicker";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDInput from "components/MDInput";
import { CommonConfig } from "constant";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import EditIcon from '@mui/icons-material/Edit';
import MDTypography from "components/MDTypography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import MDEditor from "components/MDEditor";
import { NotesComponent } from "layouts/component/Notes";

import {
  FormControlLabel,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Radio,
  Select,
  RadioGroup,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from "@mui/material/Autocomplete";
import Logo from "assets/img/valuecare-logo.png";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import * as yup from "yup";
import { Button as PButton } from "primereact/button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { item } from "examples/Sidenav/styles/sidenavItem";

import { isValidDate } from "@fullcalendar/react";
import { Alert } from "@mui/material";
import { Margin } from "@mui/icons-material";
import { Copyright } from "layouts/component/Copyright.js";


let userLogin = JSON.parse(localStorage.getItem("userData"));
console.log("test = ", localStorage.getItem("userData"));
console.log("UserLogin = ", userLogin);
const schema = yup.object().shape({
  //InspectionDateTime: yup.string().required("InspectionDate Date required."),
  FirstName: yup.string().required("First Name Required."),
  LastName: yup.string().required("Last Name Required."),
  PostCode: yup
    .string()
    .required("Post Code Required.")
    .matches("^[0-9]+$", "Post Code should be number.")
    .min(4, "Invalid Post Code.")
    .max(4, "Invalid Post Code."),
  // State: yup.object().required("State Required."),
  // PropertyName: yup.object().required("Property Name Required."),
  Email: yup.string().required("Email Required.").email("Incorrect email format."),
  Phone: yup
    .string()
    .required("Phone Required.")
    .matches("^[0-9]+$", "Phone should be number.")
    .min(10, "Invalid Phone Number.")
    .max(10, "Invalid Phone Number."),
  Message: yup.string().required("Message Required."),
  AvailableDateTimeNote: yup.string().required("Availability Note Required."),
  visitingDate: yup.string().required("Visiting Date Required."),
  // visitingDate: yup.string().when('Status', {
  //   is: (Status) => Status !== 'Pending',
  //   then: yup.string().required("Visiting Date Required."),
  //   otherwise: yup.string(),
  // }),
  // AppointmentTime: yup.object().required("Visiting Time Required."),
});

const EditBookProperty = () => {
  const defaultFeatures = ["Dining Area"];
  const defaultAmenities = ["Air Condition"];
  const defaultAccessibility = ["Wheelchair accessible"];
  const defaultSDA = ["Highly physical support"];

  const { state } = useLocation();

  let navigate = useNavigate();
  let clientRef = useRef(false);
  const [managedData, setManagedData] = useState(null);
  const [PropertyAddress, setPropertyAddress] = useState(null);
  const [propertyData, setPropertyData] = useState(state || null);
  const [values, setValues] = useState();
  const [stateId, setStateId] = useState([]);
  const [stateLabel, setStateLabel] = useState(state?.SecurityUserData?.State || null);
  const [relativeStateId, setRelativeStateId] = useState([]);
  const [relativeStateLabel, setRelativeStateLabel] = useState(
    state?.SecurityUserData?.RelativeState || null
  );
  const [chNotes, setChNotes] = useState(null);
  const [appoinmentStatusList, setAppoinmentStatusList] = useState(null);
  const [chStatus, setChStatus] = useState(null);
  const [imageurl, setimageurl] = useState([]);
  const [serviceAutocompleteValues, setServiceAutocompleteValues] = useState(null);
  const [Bookingtype, setBookingtype] = useState("");
  const [manageStatus, setManageStatus] =  useState("");
  const [userData, setUserData] = useState(state?.SecurityUserData || null);
  const [isEditEnabled, setIsEditEnabled] = useState(state ? true : false);
  const [stateList, setStateList] = useState(null);
  const [featureCheckboxlist, setFeatureCheckboxlist] = useState([]);
  const [amenityCheckboxlist, setAmenityCheckboxlist] = useState([]);
  const [accessibilityCheckboxlist, setAccessibilityCheckboxlist] = useState([]);
  const [sdaCheckboxlist, setSdaCheckboxlist] = useState([]);
  const [Propertynamelist, setPropertynamelist] = useState(null);
  const [citylist, setcitylist] = useState(null);
  const [serviceList, setServiceList] = useState(null);
  const [loader, setLoader] = React.useState(false);
  const [value, setValue] = React.useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [availableBedroom, setAvailableBedroom] = useState(null);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [selectedMediumType, setSelectedMediumType] = useState(false);
  const [TimeList, setTimeList] = useStateWithCallbackLazy(null);
  const [riskRegisterId, setRiskRegisterId] = useState(null);
  const [inNoteImportant, setinNoteImportant] = useState(false);
  const [notesEnable, setNotesEnable] = useState(false);
  const [selectedNotesType, setSselectedNotesType] = useState(null);
  const [selectedpropertylocation, setselectedpropertylocation] = useState(
    null || propertyData.City
  );
  const [selectedpropertyname, setselectedpropertyname] = useState(
    null || propertyData.PropertyName
  );
  const [chManagedByLabel, setChManagedByLabel] = useState(null);
  const [sendEmailCheckbox, setSendEmailCheckbox] = useState(false);
  const [getpropertyData, setgetpropertyData] = useState([]);
  const [visittime, setvisittime] = useState("" || propertyData.VsitingTime);
  const [datetimeshow, setdatetimeshow] = useState(false);
  const [visitselecteddate, setvisitselecteddate] = useState("");
  const [chManagedBy, setChManagedBy] = useState(null);
  const [notesData, setNotesData] = useState([]);
  // var AppointmentDateValue = state?.PatientData?.visitingDate;
  const PropertyLocationData = citylist;
  const PropertyNameData = Propertynamelist;
  const BookInspectionID = propertyData?.BookInspectionID ? propertyData?.BookInspectionID : "";
  const PropertyID = propertyData?.PropertyID ? propertyData?.PropertyID : "";
  const ManageStatus = propertyData?.ManageStatus ? propertyData?.ManageStatus : "";
  // const EnquiryAboutdata = {
  //   EnquiryAbout: propertyData?.EnquiryAbout?.replace(/-/g, ",").split(","),
  // };
  const AreYoudata = {
    AreYou: propertyData?.AreYou?.replace(/-/g, ",").split(","),
  };
  const ContactTypedata = {
    ContactType: propertyData?.Contact?.replace(/-/g, ",").split(","),
  };
  // const [checkedValues, setCheckedValues] = useStateWithCallbackLazy(
  //   propertyData ? EnquiryAboutdata.EnquiryAbout : []
  // );
  const [areYouCheckedValues, setAreYouCheckedValues] = useStateWithCallbackLazy(
    propertyData ? AreYoudata.AreYou : []
  );
  const [contactTypeCheckedValues, setContactTypeCheckedValues] = useStateWithCallbackLazy(
    propertyData ? ContactTypedata.ContactType : []
  );
  // const handleSelect = (checkedName) => {
  //   const newNames = checkedValues?.includes(checkedName)
  //     ? checkedValues?.filter((name) => name !== checkedName)
  //     : [...(checkedValues ?? []), checkedName];
  //   setCheckedValues(newNames);
  //   return checkedValues;
  // };

  const AreYouHandleSelect = (checkedName) => {
    const AreYou = areYouCheckedValues?.includes(checkedName)
      ? areYouCheckedValues?.filter((name) => name !== checkedName)
      : [...(areYouCheckedValues ?? []), checkedName];
    setAreYouCheckedValues(AreYou);
    return areYouCheckedValues;
  };

  const ContactTypeHandleSelect = (checkedName) => {
    const ContactType = contactTypeCheckedValues?.includes(checkedName)
      ? contactTypeCheckedValues?.filter((name) => name !== checkedName)
      : [...(contactTypeCheckedValues ?? []), checkedName];
      setContactTypeCheckedValues(ContactType);
    return contactTypeCheckedValues;
  };

  const onManagedByChangeStatusChanged = (e, value) => {
    console.log("VVV", value);
    setChManagedByLabel(value.label);
    setChManagedBy(value.id);
  };

  const getAppoinmentStatus = () => {
    const formattedData = { StringMapType: "APPOINTMENTSTATUS", SortType: "Alpha" };
    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          let appoinmentStatus = res.data.data;
          setAppoinmentStatusList(
            appoinmentStatus.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getInternalStaff = () => {
    axios({
      url: CommonConfig.ApiUrl + "contact/getInternalStaffDropdown",
      method: "POST",
      headers: {},
    })
      .then((res) => {
        if (res.data.success) {
          console.log("Managed Data", res.data.data);
          setManagedData(
            res.data.data.map((item) => ({
              id: item.PersonId,
              label: item.FullName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onStatusChanged = (e, value) => {
    setChStatus(value.id);
  };

  const displaydatetime = (e) => {
    console.log("datefortime", CommonConfig.formatDate(e));
    var Selecteddate = CommonConfig.formatDate(e);
    var currentDate = new Date().toJSON().slice(0, 10);
    setvisitselecteddate(Selecteddate);
    console.log("currentdate", currentDate);
    if (Selecteddate === currentDate) {
      getTimeSlot(1);
    } else {
      getTimeSlot(0);
    }
    // var dates = document.getElementById("DateValue");
    // var dates2 = document.getElementById("DateValue").value;
    // setvisitselecteddate(Selecteddate);
    console.log("Appi=", visitselecteddate);
    // alert("Hello = " , dates)
  };

  const handleSwitchChange = () => {
    setSendEmailCheckbox(!sendEmailCheckbox);
  };

  const getTimeSlot = (n) => {
    axios({
      url: CommonConfig.ApiUrl + "Appointment/getTimeSlotValue",
      method: "GET",
      headers: {},
    })
      .then((res) => {
        if (res.data?.success) {
          var list = [];
          var list2 = [];
          var dt = Date();
          var newdate = dt.slice(15, -37);
          if (n === 1) {
            res.data.data.map((item1) => {
              console.log("iiii", item1.TimeValue.slice(0, -6));
              var date = item1.TimeValue.slice(0, -6);
              console.log("date", date);
              console.log("newdate", newdate);
              if (parseInt(date) <= parseInt(newdate)) {
                list.push(item1);
                setTimeList(
                  list.map((item) => ({
                    id: item.TimeValue,
                    label: item.TimeLabel,
                  }))
                );
              } else if (parseInt(date) >= parseInt(newdate)) {
                list2.push(item1);
                setTimeList(
                  list2.map((item) => ({
                    id: item.TimeValue,
                    label: item.TimeLabel,
                  }))
                );
              } else {
                setTimeList();
                // list2.push();
                // setTimeList(
                //   list2.map((item) => ({
                //     id: item.TimeValue,
                //     label: item.TimeLabel,
                //   }))
                // );
              }
            });
          } else {
            setTimeList(
              res.data.data.map((item) => ({
                id: item.TimeValue,
                label: item.TimeLabel,
              }))
            );
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const bookingconfirm = () => {
    setBookingtype("confirm");
    // UpdateAvalibleBedroomCount();
    onSubmit();
  };

  const selectedAction = () => {
    setOpenDialog(true);
    console.log("propertydataa", getpropertyData[0].TotalAvailableBedroomCount);
    setAvailableBedroom(getpropertyData[0].TotalAvailableBedroomCount);
  }

  const CancelAction = () => {
    setSelectedMediumType("");
    setOpenDialog(false);
  };

  const bookingCancel = () => {
    setOpenCancelDialog(true);
  }

  const CancelBookingAction = () => {
    setSelectedMediumType("");
    setOpenCancelDialog(false);
  }

  const ConfirmAction = () => {
    console.log("Selected Medium Type:", selectedMediumType);
    if (selectedMediumType == "Yes") {
      document.getElementById("ServicesError").style.display = "none";
      setBookingtype("confirm");
      UpdateAvalibleBedroomCount('Booked');
      onSubmit('', 'confirm');
      setOpenDialog(false); 
    } else if (selectedMediumType == "No") {
      document.getElementById("ServicesError").style.display = "none";
      setOpenDialog(false);
    } else {
      document.getElementById("ServicesError").style.display = "block";
    }
  };

  const CancelBooking = () => {
    console.log("Selected Medium Type:", selectedMediumType);
    if (selectedMediumType == "Yes") {
      document.getElementById("ServicesError").style.display = "none";
      UpdateAvalibleBedroomCount('Canceled');
      setOpenCancelDialog(false); 
    } else if (selectedMediumType == "No") {
      document.getElementById("ServicesError").style.display = "none";
      setOpenCancelDialog(false);
    } else {
      document.getElementById("ServicesError").style.display = "block";
    }
  };

  const UpdateAvalibleBedroomCount = (pStatus) => {
    console.log("manageStatus",manageStatus);
    var PropertyId = {
      id: PropertyID,
      manageStatus: pStatus,
      bookID: BookInspectionID,
    };
    axios({
      url: CommonConfig.ApiUrl + "Property/UpdateAvalibleBedroomCount",
      method: "POST",
      headers: {},
      data: PropertyId,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("UpdateResponse", res.data.data);
          cogoToast.success("Booking status changed to " + pStatus + " successfully");
          navigate("/layouts/Property/BookPropertyList");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const bookingvisit = (data) => {
    setBookingtype("visit");
    // onSubmit(data, '');
    console.log("bookingvisitData", data);
  };

  // const errorMessage = () => {
  //   if (Object.keys(errors).length > 0) {
  //     cogoToast.error("Please complete the form first");
  //   }
  // }

  const getLocationDropdown = () => {
    axios({
      url: CommonConfig.ApiUrl + "Property/getcitylist",
      method: "Get",
      headers: {},
      data: "",
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("1StateList===>", res.data.data[0]);
          setcitylist(
            res.data.data[0].map((item) => ({
              id: item.City,
              label: item.City,
            }))
          );
        }
        console.log("PropertyLocationData===>", PropertyLocationData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStateData = () => {
    const formattedData = {
      StringMapType: "STATE",
      SortType: "Alpha",
    };
    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);
          setStateList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPropertyCheckboxlist = () => {
    axios({
      url: CommonConfig.ApiUrl + "Property/getPropertyCheckboxlist",
      method: "Get",
      headers: {},
      data: "",
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("PropertyCheckboxlist", res.data.data);
          var list1 = [];
          res.data.data[2].map((item) => {
            list1.push(item.Feature);
          });
          setFeatureCheckboxlist(list1);

          var list2 = [];
          res.data.data[1].map((item) => {
            list2.push(item.Amenity);
          });
          setAmenityCheckboxlist(list2);

          var list3 = [];
          res.data.data[0].map((item) => {
            list3.push(item.Accesibility);
          });
          setAccessibilityCheckboxlist(list3);

          var list4 = [];
          res.data.data[3].map((item) => {
            list4.push(item.SDA);
          });
          setSdaCheckboxlist(list4);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const display = (e) => {
    setselectedpropertylocation(e.label);
    console.log("array", getpropertyData);
    var name = e.label;
    GetpopertynameByCity(name);
  };

  const getpropertydetails = (name) => {
    setLoader(true);
    //setPropertyData([]);
    getpropertyData.pop();
    imageurl.length = 0;
    setselectedpropertyname(name.label);
    var inputdata = {
      city: selectedpropertylocation,
      name: name.label ? name.label : propertyData.PropertyName,
    };
    axios({
      url: CommonConfig.ApiUrl + "Property/getPropertydetails",
      method: "POST",
      headers: {},
      data: inputdata,
    })
      .then((res) => {
        if (res.data?.success) {
          var url = "";
          getpropertyData.push(res.data.data[0][0]);

          console.log("document", res.data.data[0]);

          for (var i = 0; i < res.data.data[0].length; i++) {
            url = CommonConfig.dir + "Property/" + res.data.data[0][i].Filename;
            imageurl.push(url);
            // setimageurl(url);
            console.log("imageurl11", imageurl);
          }

          var addressLine1 = ""
          if (getpropertyData[0].AddrLine1 != "") {
            addressLine1 = getpropertyData[0].AddrLine1 + ", "
          }

          var addressLine2 = ""
          if (getpropertyData[0].AddrLine2 != "") {
            addressLine2 = getpropertyData[0].AddrLine2 + ", "
          }

          var addressLine3 = ""
          if (getpropertyData[0].AddrLine3 != "") {
            addressLine3 = getpropertyData[0].AddrLine3 + ", "
          }

          var PropertyAddressData = (addressLine1 + addressLine2 + addressLine3 +
            getpropertyData[0].City +
            ", " +
            getpropertyData[0].State +
            ", " +
            "Australia" +
            ", " +
            getpropertyData[0].PostalCode)

          setPropertyAddress("");
          setPropertyAddress(PropertyAddressData)

          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetpopertynameByCity = (name) => {
    //setLoader(true);
    var selectedcity = {
      id: name,
    };
    axios({
      url: CommonConfig.ApiUrl + "Property/GetpopertynameByCity",
      method: "POST",
      headers: {},
      data: selectedcity,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data[0][0]);

          setPropertynamelist(
            res.data.data[0].map((item) => ({
              id: item.PropertyName,
              label: item.PropertyName,
            }))
          );
          // setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getInternalStaff();
    getAppoinmentStatus();
    getStateData();
    getPropertyCheckboxlist();
    getLocationDropdown();
    getpropertydetails("name");
    getNoteList(propertyData.BookInspectionID);
    //GetpopertynameByCity(propertyData?.City);
  }, []);

  console.log("UserData = ", propertyData);
  console.log("date...11", propertyData.ADateTime);

  const propertyloaction = {
    id: propertyData?.City,
    label: propertyData?.City,
  };

  const statedata = {
    id: userData?.State,
    label: userData?.State,
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    // errors,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
// InspectionDateTime: isEditEnabled
      //   ? propertyData?.InspectionDateTime
      //     ? moment(propertyData?.InspectionDateTime).format("YYYY-MM-DD")
      //     : ""
      //   : "",
      // visitingDate: propertyData?.InspectionDateTime
      //   ? moment(propertyData.InspectionDateTime).format("dd/MM/yyyy")
      //   : "",
      FirstName: propertyData?.FirstName ? propertyData?.FirstName : "",
      LastName: propertyData?.LastName ? propertyData?.LastName : "",
      Phone: propertyData?.Phone ? propertyData?.Phone : "",
      Email: propertyData?.Email ? propertyData?.Email : "",
      Message: propertyData?.Message ? propertyData?.Message : "",
      AvailableDateTimeNote: propertyData?.AvailableDateTimeNote ? propertyData?.AvailableDateTimeNote : "",
      PostCode: propertyData?.PostCode ? propertyData?.PostCode : "",
      //PropertyLocation: propertyData?.City ? propertyData?.City : "",
      PropertyLocationData: propertyloaction ? propertyloaction : "",
      AppointmentTime: propertyData?.VsitingTime ? propertyData?.VsitingTime : "",
            PropertyName: propertyData?.PropertyName ? propertyData?.PropertyName : "",
      visitingDate: propertyData?.ADateTime ? propertyData?.ADateTime : "",
      // AppointmentTime: propertyData?.visitingtime ? propertyData?.visitingtime : "",
      inquiryDate: propertyData?.InquiryDate ? propertyData?.InquiryDate : "",
      //BookInspectionID: propertyData?.BookInspectionID ? propertyData?.BookInspectionID : "",
      TotalAvailableBedroomCount: propertyData?.TotalAvailableBedroomCount
        ? propertyData?.TotalAvailableBedroomCount
        : "",
      TotalBedroomCount: propertyData?.TotalBedroomCount ? propertyData?.TotalBedroomCount : "",
      AppointmentDate: propertyData?.ADateTime
        ? moment(propertyData.ADateTime).format("dd/MM/yyyy")
        : "",
      Status: propertyData?.ManageStatus ? propertyData?.ManageStatus : "",
      ManageBy: propertyData?.managebyname ? propertyData?.managebyname : "",
      // Contact: propertyData?.Contact ? propertyData?.Contact : "Email",
      ContactTime: propertyData?.ContactTime ? propertyData?.ContactTime : "Morning",
    },
  });

  const getNoteList = (values) => {
    let noteReqData = {
      EntityType: "AssignProperty",
      EntityId: values ? values : "",
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    axios({
      url: CommonConfig.ApiUrl + "note/getNoteList",
      method: "POST",
      headers: {},
      data: noteReqData,
    })
      .then((res) => {
        if (res.data?.success) {
          setNotesData(res.data.data || []);
          document.getElementById("NotesTableDynamic").style.display = "block";
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const saveNotes = () => {
    if (chNotes === null || chNotes === "") {
      document.getElementById("NotesError").style.display = "block";
      return;
    } else {
      document.getElementById("NotesError").style.display = "none";
    }
    const formattedData = {
      EntityType: "AssignProperty",
      EntityId: propertyData.BookInspectionID ? propertyData.BookInspectionID : "",
      CurrentUser: CommonConfig.getCurrentUserId(),
      IsFlag: inNoteImportant ? 1 : 0,
      NoteType: "AssignProperty",
      NoteTitle: "",
      NoteText: chNotes,
    };
    console.log("formatteddata..", formattedData);
    axios({
      url: CommonConfig.ApiUrl + "note/addNote",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          cogoToast.success("Notes Added Successfully.");
          getNoteList(propertyData.BookInspectionID);
          setChNotes("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const isFormValid = (data) => {
  //   const requiredFields = ['FirstName', 'LastName', 'PostCode', 'Email', 'Message', 'visitingDate', 'Phone'];
  //   for (const field of requiredFields) {
  //     if (!data[field]) {
  //       return false;
  //     }
  //   }
  //   return true;
  // };
  // if (!isFormValid(data)) {
  //   cogoToast.error("Please complete the form first");
  //   return;
  // }

  const onSubmit = (data, booktype) => {
    console.log("AppointmentTime", data?.AppointmentTime?.label);
    console.log("PuraData", data);
    console.log("Bookingtype", Bookingtype);
    // setBookingtype("visit");
    if (Bookingtype !== "confirm" && booktype !== 'confirm') {
      // if (visitselecteddate === "") {
      //   setOpenDialog(true);
      // } else {
        let formattedData = "";
        if (userLogin.PersonType == "INTERNAL STAFF") {
          formattedData = {
            ...data,
            BookInspectionId: propertyData.BookInspectionID ? propertyData.BookInspectionID : "",
            InspectionDateTime: moment(data.visitingDate).format("YYYY-MM-DD"),
            visitingDate: moment(data.visitingDate).format("DD/MM/yyyy"),
            // visitingtime: data?.AppointmentTime?.label ? data?.AppointmentTime?.label: propertyData?.VsitingTime,
            visitingtime: visittime,
            PropertyId: getpropertyData[0] ? getpropertyData[0].PropertyID : "  ",
            manageby: userLogin.PersonId,
            Status: data.Status.id ? data.Status.id : propertyData?.ManageStatus,
            bookingtype: "visit",
            boooking: "visit",
            EmailData: data.Email ? data.Email : propertyData.Email,
            // Contact: data.Contact,
            // Contact: data?.Contact || propertyData?.Contact || "",
            // ContactTime: data.ContactTime,
            ContactTime: data?.ContactTime || propertyData?.ContactTime || "",
            // EnquiryAbout: checkedValues.toString().replace(/,/g, "-"),
            AreYou: areYouCheckedValues.toString().replace(/,/g, "-"),
            ContactType: contactTypeCheckedValues.toString().replace(/,/g, "-"),
            SendEmailCheckbox : sendEmailCheckbox === true ? 1: 0,
          };
        } else {
          formattedData = {
            ...data,
            BookInspectionId: propertyData.BookInspectionID ? propertyData.BookInspectionID : "",
            InspectionDateTime: moment(data.visitingDate).format("YYYY-MM-DD"),
            visitingDate: moment(data.visitingDate).format("DD/MM/yyyy"),
            // visitingtime:data?.AppointmentTime || (propertyData?.VsitingTime ? propertyData.VsitingTime : null),
            visitingtime: visittime,
            PropertyId: getpropertyData[0] ? getpropertyData[0].PropertyID : "  ",
            manageby: data?.ManageBy?.id || (propertyData?.ManageBy? propertyData.ManageBy : null),
            Status: data?.Status?.id || (propertyData?.ManageStatus ? propertyData.ManageStatus : null),
            bookingtype: "visit",
            boooking: "visit",
            EmailData: data.Email ? data.Email : propertyData.Email,
            // Contact: data.Contact,
            // Contact: data?.Contact || propertyData?.Contact || "",
            // ContactTime: data.ContactTime,
            ContactTime: data?.ContactTime || propertyData?.ContactTime || "",
            // EnquiryAbout: checkedValues.toString().replace(/,/g, "-"),
            AreYou: areYouCheckedValues.toString().replace(/,/g, "-"),
            ContactType: contactTypeCheckedValues.toString().replace(/,/g, "-"),
            SendEmailCheckbox : sendEmailCheckbox === true ? 1: 0,
          };
        }
        console.log("TopFormattedData", formattedData);
        axios({
          url: CommonConfig.ApiUrl + "Property/AddupdateAssignProperty",
          method: "POST",
          headers: {},
          data: formattedData,
        })
          .then((res) => {
            setLoader(!loader);
            if (res.data.success === true) {
              cogoToast.success("Property Inspection Updated Successfully.");
              navigate("/layouts/Property/BookPropertyList");
            } else {
              cogoToast.error("Something Went Wrong.");
            }
          })
          .catch((error) => {
            console.log("AddUpdateErro", error);
          });
      // }
    } else {
      let formattedData = "";
      formattedData = {
        ...data,
        BookInspectionId: propertyData.BookInspectionID ? propertyData.BookInspectionID : "",
        InspectionDateTime: moment(new Date()).format("YYYY-MM-DD"),
        PropertyId: getpropertyData[0] ? getpropertyData[0].PropertyID : "  ",
        bookingtype: Bookingtype,
        boooking: "confirm",
        // EmailData: data.Email ? data.Email : propertyData.Email,
        EmailData: data && data.Email ? data.Email : propertyData.Email,
        manageby: data?.ManageBy?.id || (propertyData?.ManageBy? propertyData.ManageBy : null),
        // Contact: data.Contact,
        // Contact: data?.Contact || propertyData?.Contact || "",
        // ContactTime: data.ContactTime,
        ContactTime: data?.ContactTime || propertyData?.ContactTime || "",
        // EnquiryAbout: checkedValues.toString().replace(/,/g, "-"),
        AreYou: areYouCheckedValues.toString().replace(/,/g, "-"),
        ContactType: contactTypeCheckedValues.toString().replace(/,/g, "-"),
        SendEmailCheckbox : sendEmailCheckbox === true ? 1: 0,
      };
      axios({
        url: CommonConfig.ApiUrl + "Property/AddupdateAssignProperty",
        method: "POST",
        headers: {},
        data: formattedData,
      })
        .then((res) => {
          setLoader(!loader);
          if (res.data.success === true) {
            // cogoToast.success("Booking Confirmed Successfully.");
            navigate("/layouts/Property/BookPropertyList");
          } else {
            cogoToast.error("Something Went Wrong.");
          }
        })
        .catch((error) => {
          console.log("AddUpdateErro", error);
        });
    }
  };

  return (
    <>
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <DashboardLayout>
        <DashboardNavbar />
        <Card sx={{ width: "100%", mt: 3 }}>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="primary"
              color="white"
              shadow="md"
              borderRadius="xl"
              ml={3}
              mt={-2}
            >
              <EditIcon fontSize="medium" color="inherit"></EditIcon>
            </MDBox>
            <MDTypography variant="h4" sx={{ mt: 2, mb: 1, ml: 2 }}>
              Edit Inspection Details
            </MDTypography>
            <MDBox marginLeft="auto" marginTop="10px" marginRight="10px">
              <MDButton
                onClick={() => navigate("/layouts/Property/BookPropertyList")}
                variant="contained" color="secondary">
                Back to list
              </MDButton>
            </MDBox>
          </MDBox>
          <MDBox sx={{ pt: 2, pb: 2, pl: 2, pr: 2 }}>
            <div style={{ paddingBottom: "20px" }}>
              <h5>Booking Inspection Number: {propertyData?.BookID}</h5>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="reg_step" id="regStep1">
                <div className="reg_form">
                  <Grid container spacing={3}>
                    {CommonConfig.isInternalStaff() === 1 && userLogin.PersonType === "Admin" ? (
                      <Grid item md="3">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control}
                            name="ManageBy"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="ManageBy"
                                name="ManageBy"
                                value={value}
                                options={managedData ? managedData : []}
                                onChange={(e, v) => {
                                  onChange(v);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Managed By"
                                    error={errors.ManageBy}
                                    helperText={errors.ManageBy ? "ManageBy Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    ) : null}

                    <Grid item md="3">
                      <div className="reg_input">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control}
                            name="Status"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="Status"
                                name="Status"
                                value={value}
                                options={appoinmentStatusList ? appoinmentStatusList : []}
                                onChange={(e, v) => {
                                  onChange(v);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Status"
                                    error={errors.Status}
                                    helperText={errors.Status ? "Status Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="5">
                      <div className="reg_input">
                        <Controller
                          name="FirstName"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="First Name"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            // disabled={propertyData ? true : false}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="5">
                      <div className="reg_input">
                        <Controller
                          name="LastName"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Last Name"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            //disabled={propertyData ? true : false}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="5">
                      <div className="reg_input">
                        <Controller
                          name="Email"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Email*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            //disabled={propertyData ? true : false}
                            />
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="3">
                      <div className="reg_input">
                        <Controller
                          name="Phone"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              type="number"
                              label="Phone Number"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                              //disabled={propertyData ? true : false}
                              inputProps={{
                                maxLength: 10,
                              }}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="2">
                      <div className="reg_input">
                        <Controller
                          name="PostCode"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Post Code"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                              //disabled={propertyData ? true : false}
                              inputProps={{
                                maxLength: 4,
                              }}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="10">
                      <div className="reg_input">
                        <Controller
                          name="Message"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Message*"
                              variant="outlined"
                              value={value}
                              rows={3}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>


                  <Grid container spacing={3}>
                    <Grid item md="10">
                      <div className="reg_input">
                        <Controller
                          name="AvailableDateTimeNote"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Available Date Time for Property Inspection*"
                              variant="outlined"
                              value={value}
                              rows={3}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>


                  <Grid container spacing={3}>
                    <Grid item md="6">


                      <div className="reg_input check_value">
                        <label>How would you like us to contact you?*</label>
                        <div className="inline-checkbox">
                          {[
                            "Email",
                            "SMS",
                            "Phone",
                          ].map((name) => (
                            <FormControlLabel
                              control={
                                <Controller
                                  name="ContactType"
                                  render={({ onChange: onCheckChange }) => {
                                    return (
                                      <Checkbox
                                        checked={contactTypeCheckedValues?.includes(name)}
                                        onChange={() => onCheckChange(ContactTypeHandleSelect(name))}
                                      />
                                    );
                                  }}
                                  control={control}
                                />
                              }
                              key={name}
                              label={name}
                            />
                          ))}
                        </div>
                      </div>


                      {/* <div className="reg_input check_value">
                        <InputLabel>How would you like us to contact you?*</InputLabel>
                        <Controller
                          name="Contact"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <>
                              {" "}
                              <RadioGroup
                                aria-label="appointment"
                                row="true"
                                defaultValue={propertyData ? propertyData?.Contact : "Email"}
                              >
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Email"
                                  control={<Radio />}
                                  label="Email"
                                />
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="SMS"
                                  control={<Radio />}
                                  label="SMS"
                                />
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Phone"
                                  control={<Radio />}
                                  label="Phone"
                                />
                              </RadioGroup>
                            </>
                          )}
                        />
                      </div> */}


                    </Grid>

                    <Grid item md="6">
                      <div className="reg_input check_value">
                        <InputLabel>When would you like us to contact you?*</InputLabel>
                        <Controller
                          name="ContactTime"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <>
                              {" "}
                              <RadioGroup
                                aria-label="appointment"
                                row="true"
                                defaultValue={propertyData ? propertyData?.ContactTime : "Morning"}
                              >
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Morning"
                                  control={<Radio />}
                                  label="Morning"
                                />
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Afternoon"
                                  control={<Radio />}
                                  label="Afternoon"
                                />
                              </RadioGroup>
                            </>
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

{/* 
                  <Grid container spacing={3}>
                    <Grid item md="12">
                      <div className="reg_input check_value">
                        <label>What is your enquiry about?*</label>
                        <div className="arrageCheckBox">
                          {[
                            "Disability Employment Services",
                            "Short Term Accommodation and Respite",
                            "Community Services",
                            "Allied Health",
                            "Australian Disability Enterprises",
                            "Careers",
                            "School Leavers Employment Services",
                            "Supported Independent Living",
                            "Lifestyle Centres",
                            "Support Coordination",
                            "Media Enquiries and Partnerships",
                            "Other Enquiries",
                          ].map((name) => (
                            <FormControlLabel
                              control={
                                <Controller
                                  name="EnquiryAbout"
                                  render={({ onChange: onCheckChange }) => {
                                    return (
                                      <Checkbox
                                        checked={checkedValues?.includes(name)}
                                        onChange={() => onCheckChange(handleSelect(name))}
                                      />
                                    );
                                  }}
                                  control={control}
                                />
                              }
                              key={name}
                              label={name}
                            />
                          ))}
                        </div>
                      </div>
                    </Grid>
                  </Grid> */}


                  <Grid container spacing={3}>
                    <Grid item md="12">
                      <div className="reg_input check_value">
                        <label>Are you a?*</label>
                        <div className="inline-checkbox">
                          {[
                            "NDIS Participant",
                            "Current Value Care Participant",
                            "Carer / Family / Guardian",
                            "Support Coordinator",
                            "Referrer",
                            "Other",
                          ].map((name) => (
                            <FormControlLabel
                              control={
                                <Controller
                                  name="AreYou"
                                  render={({ onChange: onCheckChange }) => {
                                    return (
                                      <Checkbox
                                        checked={areYouCheckedValues?.includes(name)}
                                        onChange={() => onCheckChange(AreYouHandleSelect(name))}
                                      />
                                    );
                                  }}
                                  control={control}
                                />
                              }
                              key={name}
                              label={name}
                            />
                          ))}
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="12">
                      {propertyData.BookingStatus !== "confirm" ? (
                        <div>
                          <h5>
                            <span>Inquiry Date & Time</span> <span style={{ marginLeft: "30px" }}>{propertyData.InquiryDate}</span>
                          </h5>
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item md="12">
                      {propertyData.BookingStatus !== "confirm" ? (
                        <div className="reg_input" style={{ marginBottom: "0px" }}>
                          <h5>Visiting Date & Time</h5>
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item md="4">
                      {propertyData.BookingStatus !== "confirm" ? (
                        <div className="reg_input">
                          <Controller
                            name="visitingDate"
                            defaultValue=""
                            id="visitingDate"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <LocalizationProvider dateAdapter={DateFnsUtils}>
                                <DatePicker
                                  label="Date*"
                                  value={value}
                                  inputFormat="dd/MM/yyyy"
                                  onChange={(date) => {
                                    onChange(date);
                                    displaydatetime(date);
                                  }}
                                  onClose={(e, v) => displaydatetime(value)}
                                  minDate={new Date()}
                                  renderInput={(params) => (
                                    <TextField
                                      // id="visitingDate"
                                      // name="visitingDate"
                                      {...params}
                                      // error={errors.visitingDate}
                                      // helperText={
                                      //   errors.visitingDate ? "Visiting Date Required." : ""
                                      // }
                                      error={error}
                                      helperText={error?.message}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item md="4">
                      {propertyData.BookingStatus !== "confirm" ? (
                        <div className="reg_input">
                          <div className="reg_input auto-complete">
                            <Controller
                              name="AppointmentTime"
                              id="AppointmentTime"
                              defaultValue={ propertyData?.VsitingTime}
                              control={control}
                              // rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <Autocomplete
                              disablePortal
                              id="AppointmentTime"
                              name="AppointmentTime"
                              value={value}
                              options={TimeList ? TimeList : []}
                              onChange={(e, v) => {
                                    onChange(v);
                                    setvisittime(v.label);
                                  }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Time*"
                                  error={errors.AppointmentTime}
                                  helperText={
                                        errors.AppointmentTime ? "Appointment Time Required." : ""
                                      }
                                />
                              )}
                            />
                              )}
                            />
                          </div>
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item md="4">
                      {propertyData.BookingStatus !== "confirm" ? (
                        <FormControlLabel
                          control={<Checkbox checked={sendEmailCheckbox} onChange={handleSwitchChange} />}
                          label="Send Inspection Scheduled Email"
                          className="email-labeling"
                        />
                      ) : null}
                    </Grid>
                  </Grid>

                  <Grid>
                    <div className="reg_input">
                      <h5>Property Details</h5>
                    </div>
                    <div className="pd-row">
                      {getpropertyData[0] ? (
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                          <Grid item md="12">
                            <div className="pd-box">
                              <p>
                                <span>Property ID: </span>
                                <b>{getpropertyData[0].ValueCarePropertyId ? getpropertyData[0].ValueCarePropertyId : "  "}</b>
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                      ) : null}
                    </div>
                    <div className="pd-row">
                      {getpropertyData[0] ? (
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                          <Grid item md="6">
                            <div className="pd-box">
                              <p>
                                <span>Name:</span>
                                {getpropertyData[0] ? getpropertyData[0].PropertyName : "  "}
                              </p>
                            </div>
                          </Grid>
                          <Grid item md="6">
                            <div className="pd-box">
                              <p>
                                <span>Type:</span>
                                {getpropertyData[0] ? getpropertyData[0].PropertyType : "  "}
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                      ) : null}
                    </div>
                    <div className="pd-row">
                      {getpropertyData[0] ? (
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                          <Grid item md="12">
                            <div className="pd-box">
                              <p>
                                <span>Address: </span>
                                {PropertyAddress}
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                      ) : null}
                    </div>
                    <div className="pd-row">
                      {getpropertyData[0] ? (
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                          <Grid item md="4">
                            <div className="pd-box">
                              <p>
                                <span>Total Bedrooms:</span> {getpropertyData[0].TotalBedroomCount}
                              </p>
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="pd-box">
                              <p>
                                <span>Available Bedrooms:</span>{" "}
                                {getpropertyData[0].TotalAvailableBedroomCount}
                              </p>
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="pd-box">
                              <p>
                                <span>Total Bathrooms:</span> {getpropertyData[0].BathroomCount}
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                      ) : null}
                    </div>
                    <div className="pd-row">
                      {getpropertyData[0] ? (
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                          <Grid item md="12">
                            <div className="pd-box">
                              <p>
                              <span>Features:</span> {getpropertyData[0].PropertyFeature}
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                      ) : null}
                    </div>
                    <div className="pd-row last">
                      {getpropertyData[0] ? (
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                          <Grid item md="4">
                            <div className="pd-box">
                              <p>
                                <span>Accessibility:</span>{" "}
                                {getpropertyData[0].PropertyAccesibility}
                              </p>
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="pd-box">
                              <p>
                                <span>Amenities:</span> {getpropertyData[0].PropertyAminities}
                              </p>
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="pd-box">
                              <p>
                                <span>SDA:</span> {getpropertyData[0].PropertySDA}
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                      ) : null}
                    </div>
                  </Grid>

                </div>
              </div>
              {getpropertyData[0] ? (
                <Grid container spacing={3}>
                  <Grid item md="12">
                    <div>
                      <h5>Property Images</h5>
                      <div className="added-property-images">
                        {imageurl
                          ? imageurl.map((name) => (
                            <span>
                              <img src={name}></img>
                            </span>
                          ))
                          : null}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
              {CommonConfig.isInternalStaff() === 1 ? (
                <Card sx={{ width: "100%", mt: 6 }}>
                  <MDBox display="flex">
                    <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="4rem"
                      height="4rem"
                      variant="gradient"
                      bgColor="primary"
                      color="white"
                      shadow="md"
                      borderRadius="xl"
                      ml={3}
                      mt={-2}
                    >
                      <Icon fontSize="medium" color="inherit">
                        note
                      </Icon>
                    </MDBox>
                    <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                      Notes
                    </MDTypography>
                  </MDBox>
                  <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
                    <div className="notes-table">
                      <table>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Notes</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="nt-date">
                              {moment(new Date()).format("DD-MM-YYYY")}{" "}
                              <span> {moment(new Date()).format("hh:mm A")}</span>
                            </td>

                            <td className="nt-comment">
                              <div className="reg_input mb-0">
                                <TextField
                                  onChange={(e) => setChNotes(e.target.value)}
                                  value={chNotes ? chNotes : ""}
                                  name="notes"
                                  multiline
                                  rows={2}
                                  // maxRows={4}
                                  label="Notes Text*"
                                  id="regFirstName"
                                  fullWidth
                                />
                                <span class="errorTC" id="NotesError">
                                  Please Write Notes.
                                </span>
                              </div>
                            </td>
                            <td className="nt-action">
                              <PButton
                                type="button"
                                icon="pi pi-plus"
                                className="p-button-primary AddNotes"
                                onClick={() => saveNotes()}
                                tooltip="Add Notes"
                                tooltipOptions={{ position: "bottom" }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    
                    <NotesComponent
                      entityType="AssignProperty"
                      entityId={propertyData.BookInspectionID}
                      NotesData={notesData}
                    />
                  </MDBox>
                </Card>
              ) : (
                <></>
              )}
              {Object.keys(errors).length > 0 && (
                <div>
                  <p style={{margin: "20px 5px 0px", color: "red", fontSize: "1rem"}}>Something is wrong. Please check your inputs.</p>
                </div>
              )}
              <div>
                <div className="reg_submit mt-20">
                  <MDButton
                    onClick={() => navigate("/layouts/Property/BookPropertyList")}
                    variant="contained" color="secondary">
                    Back to list
                  </MDButton>
                  <MDButton
                    type="submit"
                    // onClick={bookingvisit}
                    onClick={() => {
                      bookingvisit();
                      // errorMessage();
                    }}
                    variant="contained"
                    color="primary">
                    update
                  </MDButton>
                  {ManageStatus !== "Booked" ? (
                    <MDButton
                      type="button"
                      onClick={selectedAction}
                      // onClick={bookingconfirm}  // old button
                      variant="contained"
                      color="success">
                      Confirm Booking
                    </MDButton>
                  ) : (
                    <></>
                  )}
                  {ManageStatus === "Booked" ? (
                    <MDButton
                      type="button"
                      onClick={bookingCancel}
                      variant="contained"
                      color="error">
                      Cancel Booking
                    </MDButton>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <Copyright />
            </form>
          </MDBox>
        </Card>

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md">
          <DialogTitle>Confirm Booking Property</DialogTitle>
          <DialogContent>
            <Grid item md="12">
              {availableBedroom > 0 ? (
                <div className="reg_input check_value mb-0">
                  <InputLabel className="reg_lable mt-5 mb-2">
                    Are You Sure to Confirm this booking?
                  </InputLabel>
                  <div className="pd-box">
                    <p>
                      <span>Available Bedrooms:</span>{" "}
                      {availableBedroom}
                    </p>
                  </div>
                  <div>
                    <span class="servicesError" id="ServicesError">
                      Please select any one option.
                    </span>
                  </div>
                  <RadioGroup
                    aria-label="remove"
                    name="Remove"
                    row="true"
                    onChange={(e) => setSelectedMediumType(e.target.value)}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio checked={selectedMediumType === "Yes" ? true : false} />}
                      label="Yes"
                      name="Remove"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio checked={selectedMediumType === "No" ? true : false} />}
                      label="No"
                      name="Remove"
                    />
                  </RadioGroup>
                  <span class="errorTC" id="ServicesError">
                    Please select any one service
                  </span>
                </div>
              ) : (
                <div className="pd-box">
                  <p>
                    <span style={{ color: "red", fontSize: "1rem" }}>
                      No Available Bedrooms. Please choose another property.
                    </span>
                  </p>
                </div>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <MDButton
              variant="contained"
              color="secondary"
              onClick={() => CancelAction()}>
              Back
            </MDButton>
            {availableBedroom > 0 ? (
              <MDButton
                variant="contained"
                color="primary"
                onClick={() => {
                  ConfirmAction();
                }}
              >
                Ok
              </MDButton>
            ) : (
              <></>
            )}
          </DialogActions>
        </Dialog>

        <Dialog open={openCancelDialog} onClose={() => setOpenCancelDialog(false)} maxWidth="xs">
          <DialogTitle>Cancel Booking Property</DialogTitle>
          <DialogContent>
            <Grid item md="12">
              <div className="reg_input check_value mb-0">
                <InputLabel className="reg_lable mt-5 mb-2">
                  Are You Sure to Cancel this booking?
                </InputLabel>
                <div>
                  <span class="servicesError" id="ServicesError">
                    Please select any one option.
                  </span>
                </div>
                <RadioGroup
                  aria-label="remove"
                  name="Remove"
                  row="true"
                  onChange={(e) => setSelectedMediumType(e.target.value)}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio checked={selectedMediumType === "Yes" ? true : false} />}
                    label="Yes"
                    name="Remove"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio checked={selectedMediumType === "No" ? true : false} />}
                    label="No"
                    name="Remove"
                  />
                </RadioGroup>
                <span class="errorTC" id="ServicesError">
                  Please select any one service
                </span>
              </div>
            </Grid>
          </DialogContent>
          <DialogActions>
            <MDButton
              variant="contained"
              color="secondary"
              onClick={() => CancelBookingAction()}
            >
              Back
            </MDButton>
            <MDButton
              variant="contained"
              color="primary"
            onClick={() => {
              CancelBooking();
            }}
            >
              Ok
            </MDButton>
          </DialogActions>
        </Dialog>
      </DashboardLayout>
    </>
  );
};

//EditBookPrperty.defaultProps = {};

export default EditBookProperty;
