import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  FormControlLabel,
  Switch,
  AccordionSummary,
  Autocomplete,
  Button,
  Card,
  CardContent,
  Grid,
  Input,
  InputLabel,
  TextField,
  Radio,
  RadioGroup,
} from "@mui/material";
import * as yup from "yup";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { Button as PButton } from "primereact/button";
import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import axios from "axios";
import { CommonConfig } from "constant";
import cogoToast from "cogo-toast";
import { Column } from "primereact/column";
import { NotesComponent } from "layouts/component/Notes";
import MDButton from "components/MDButton";
import { ArrowDownward } from "@mui/icons-material";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import PrimeDatatable from "layouts/component/PrimeDatatable";

import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation, useNavigate } from "react-router-dom";
import { useStateWithCallbackLazy } from "use-state-with-callback";

const schema = yup.object().shape({
  FirstName: yup.string().required("First Name Required."),
  LastName: yup.string().required("Last Name Required."),
  Email: yup.string().required("Email Required."),
  Phone: yup.string().required("Phone Required."),
  PostalCode: yup.string().required("Postal Code Required."),
  Message: yup.string().required("Message Required."),
  EnquiryAbout: yup.object().required("Please select any one enquiry"),
  AreYou: yup.object().required("Please select any one option"),


  ComplaintDate: yup.string().required("Date Required."),
  // ReceivedFrom: yup.string().required("Received From required"),
  ReceivedBy: yup.object().required("Received By Required."),
  AssignTo: yup.object().required("Assign To Required."),
  CRStatus: yup.object().required("Status Required."),
  ComplaintDetail: yup.string().required("Details Required."),
});

const schemaNotes = yup.object().shape({
  Notes: yup.string().required("Note Required."),
  NotesType: yup.string().required("NoteType Required."),
});

const ComplaintRegister = () => {
  const [apiData, setApiData] = useState([]);
  const [visitType, setVisitType] = useState('');
  const [showDateRange, setShowDateRange] = useState(true);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [rememberMe, setRememberMe] = useState(true);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [name, setName] = useState("");
  const [cityOrState, setCityOrState] = useState("");
  const [userType, setUserType] = useState("");
  const [phoneOrEmail, setPhoneOrEmail] = useState("");
  const [personIdNumber, setPersonIdNumber] = useState("");
  const [stateList, setStateList] = useState(null);
  const [values, setValues] = useState([]);
  const [stateLabel, setStateLabel] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMediumType, setSelectedMediumType] = useState(false);
  const [appointmentdelID, setappointmentdelID] = useState(null);
  const [loader, setLoader] = React.useState(false);
  const [conformationData, setConformationData] = useState(null);
  const [bookingAllStatus, setBookingAllStatus] = useState(null);

  const [todayDate, settodayDate] = useState(new Date().toISOString().slice(0, 10));
  const [internalStaff, setInternalStaff] = useState(null);
  const [complaintList, setComplaintList] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [complaintStatus, setComplaintStatus] = useState(null);
  const [chNotes, setChNotes] = useState(null);
  const [notesData, setNotesData] = useState([]);
  const [notesDropdownList, setNotesDropdownList] = useState(null);
  const [selectedNotesType, setSselectedNotesType] = useState(null);
  const [inNoteImportant, setinNoteImportant] = useState(false);
  const [notesEnable, setNotesEnable] = useState(false);
  const [complaintRegisterId, setComplaintRegisterId] = useState(null);
  // const [complaintRegisterNumber, setComplaintRegisterId] = useState(null);
  const [complaintRegisterNumber, setComplaintRegisterNumber] = useState(null);
  const [citylist, setcitylist] = useState(null);
  const [selectedpropertylocation, setselectedpropertylocation] = useState(null);
  const [getpropertyData, setgetpropertyData] = useState([]);
  const [Propertynamelist, setPropertynamelist] = useState(null);
  const [imageurl, setimageurl] = useState([]);
  const [featureCheckboxlist, setFeatureCheckboxlist] = useState([]);
  const [amenityCheckboxlist, setAmenityCheckboxlist] = useState([]);
  const [accessibilityCheckboxlist, setAccessibilityCheckboxlist] = useState([]);
  const [sdaCheckboxlist, setSdaCheckboxlist] = useState([]);

  const PropertyLocationData = citylist;
  const PropertyNameData = Propertynamelist;
  const RoomsData = [{ label: 1 }, { label: 2 }, { label: 3 }, { label: 4 }, { label: 5 }];
  const [checkedValues, setCheckedValues] = useStateWithCallbackLazy([]);
  const [areYouCheckedValues, setAreYouCheckedValues] = useStateWithCallbackLazy([]);
  const [bookingtype, setbookingtype] = useState("");
  const [BookInspectionId, setBookInspectionId] = useState("");
  const [visitselecteddate, setvisitselecteddate] = useState("");
  const [visittime, setvisittime] = useState("");
  const [redirect, setredirect] = useState(true);
  const [enquiryAboutAutocompleteValues, setEnquiryAboutAutocompleteValues] = useState(null);
  
  const PropertyAccesibilitydata = {
    // Feature: propertyData?.PropertyAccesibility.split(","),
  };

  const PropertySDAdata = {
    //Feature: propertyData?.PropertySDA.split(","),
  };

  const [accessibilityCheckedValues, setAccessibilityCheckedValues] = useStateWithCallbackLazy(
    PropertyAccesibilitydata.Feature ? PropertyAccesibilitydata.Feature : []
  );
  const [sdaCheckedValues, setSdaCheckedValues] = useStateWithCallbackLazy(
    PropertySDAdata.Feature ? PropertySDAdata.Feature : []
  );
  const [showsavebutton, setshowsavebutton] = React.useState(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      Action: "",
      FirstName: "",
      LastName: "",
      Email: "",
      Phone: "",
      PostalCode: "",
      Message: "",
      Contact: "Email",
      ContactTime: "Morning",
      EnquiryAbout: "",
      AreYou: "",

      AssignTo: "",
      ComplaintDate: "",
      ComplaintDetail: "",
      ComplaintRegisterId: "",
      CurrentUser: "",
      EntityID: "",
      EntityType: "",
      ReceivedBy: "",
      RecievedFrom: "",
    },
  });

  const {
    register: registerNote,
    formState: { errors: errorsNote },
    handleSubmit: handleSubmitNote,
    reset: resetNote,
    control: controlNotes,
  } = useForm({
    resolver: yupResolver(schemaNotes),
    defaultValues: {
      NotesType: "",
      Notes: "",
    },
  });

  let navigate = useNavigate();
  let loginUser;
  if (localStorage.getItem("userData")) {
    // alert("Hello");
    loginUser = JSON.parse(localStorage.getItem("userData"));
  } else {
    // alert("Hello2")
    navigate("/");
  }
  // console.log("ERRRR", errorsNote);

  const visitTypeList = [
    { label: 'Inquiry Date', value: 'InquiryDate' },
    { label: 'Visit Date', value: 'VisitDate' },
  ];

  const EnquiryAboutList = [
    { label: 'Disability Employment Services', value: 'Disability Employment Services' },
    { label: 'Short Term Accommodation and Respite', value: 'Short Term Accommodation and Respite' },
    { label: 'Community Services', value: 'Community Services' },
    { label: 'Allied Health', value: 'Allied Health' },
    { label: 'Australian Disability Enterprises', value: 'Australian Disability Enterprises' },
    { label: 'Careers', value: 'Careers' },
    { label: 'School Leavers Employment Services', value: 'School Leavers Employment Services' },
    { label: 'Supported Independent Living', value: 'Supported Independent Living' },
    { label: 'Lifestyle Centres', value: 'ShLifestyle Centresort' },
    { label: 'Support Coordination', value: 'Support Coordination' },
    { label: 'Media Enquiries and Partnerships', value: 'Media Enquiries and Partnerships' },
    { label: 'Other Enquiries', value: 'Other Enquiries' },
  ];

  const AreYou = [
    { label: 'NDIS Participant', value: 'NDIS Participant' },
    { label: 'Current Value Care Client', value: 'Current Value Care Client' },
    { label: 'Carer / Family / Guardian', value: 'Carer / Family / Guardian' },
    { label: 'Referrer', value: 'Referrer' },
    { label: 'Other', value: 'Other' },
  ];

  useEffect(() => {
    getInternalStaff();
    getPropertyInspectionList();
    getNotesDropdownList();
    getNoteList();
    getComplaintStatus();
  }, []);

  useEffect(() => {
    getStateData();
    getPropertyCheckboxlist();
    getLocationDropdown();
  }, []);

  const column = [
    {
      name: "Booking inspection Number",
      selector: "BookID",
      filter: true,
      sortable: true,
    },
    {
      name: "Property ID",
      selector: "ValueCarePropertyId",
      filter: true,
      sortable: true,
    },
    {
      name: "Address",
      selector: "Address",
      filter: true,
      sortable: true,
    },
    {
      name: "Name",
      selector: "PersonName",
      filter: true,
      sortable: true,
    },
    {
      name: "Email",
      selector: "Email",
      filter: true,
      sortable: true,
    },
    {
      name: "Phone",
      selector: "Phone",
      filter: true,
      sortable: true,
    },
    {
      name: "Managed By",
      selector: "managebyname",
      filter: true,
      sortable: true,
    },
    {
      name: "Inquiry Date",
      selector: "InquiryDate",
      filter: true,
      sortable: true,
    },
    {
      name: "Visiting Date",
      selector: "VisitDateTime",
      filter: true,
      sortable: true,
    },
    {
      name: "Booking Confirm Date",
      selector: "ConfirmationDateTime",
      filter: true,
      sortable: true,
    },
    {
      name: "Booking Status",
      selector: "ManageStatus",
      filter: true,
      sortable: true,
    },
    // {
    //   name: "Date",
    //   selector: "ADateTime",
    //   filter: true,
    //   sortable: true,
    // },
    // {
    //   name: "Time",
    //   selector: "VsitingTime",
    //   filter: true,
    //   sortable: true,
    //   rowReorderIcon: null,
    //   filtericon: false,
    // },
    {
      name: "Action",
      body: (data) => actionTemplate(data),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const actionTemplate = (data) => {
    return (
      <div>
        <PButton
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => {
            // setIsEditEnabled(true);
            setShowAdd(true);
            setNotesEnable(true);
            if (data.ComplaintRegisterId) {
              setComplaintRegisterId(data.ComplaintRegisterId);
              getNoteList(data.ComplaintRegisterId);
            }
            if (data.ComplaintNumber) {
              setComplaintRegisterNumber(data.ComplaintNumber);
            }
            let assignToObj;
            let receivedByObj;
            let CRStatusobj;
            if (data.AssignedTo) {
              assignToObj = internalStaff.filter((item) => item.id == data.AssignedTo);
              // console.log("dsfds", assignToObj);
            }
            if (data.ReceivedBy) {
              receivedByObj = internalStaff.filter((item) => item.id == data.ReceivedBy);
              // console.log("ReceivedBy", receivedByObj);
            }
            if (data.CRStatus) {
              CRStatusobj = complaintStatus.filter((item) => item.id == data.CRStatus);
              // console.log("ReceivedBy", receivedByObj);
            }
            // console.log("DATA", {
            //   ...data,
            //   ReceivedBy: receivedByObj ? receivedByObj[0] : "",
            //   AssignedTo: assignToObj ? assignToObj[0] : "",
            // });
            reset({
              ...data,
              ReceivedBy: receivedByObj ? receivedByObj[0] : "",
              AssignTo: assignToObj ? assignToObj[0] : "",
              CRStatus: CRStatusobj ? CRStatusobj[0] : "",
            });
          }}
          style={{ marginRight: ".5em" }}
          tooltip="Edit Complaint"
          tooltipOptions={{ position: "bottom" }}
        />
        &nbsp;
        <PButton
          type="button"
          icon="pi pi-user-minus"
          className="p-button-danger"
          onClick={() => {
            // deleteUser(data);
            selectedRow(data);
          }}
          style={{ marginRight: ".5em" }}
          // tooltip="Remove Book Property"
          tooltip="Cancel Booking"
          tooltipOptions={{ position: "bottom" }}
        />
        &nbsp;
        {/* {data.BookingStatus === "confirm" ? ( */}
        {bookingAllStatus === null || bookingAllStatus === "confirm" ? (
          <PButton
            type="button"
            icon="pi pi-calendar-plus"
            className="p-button-success"
            disabled={conformationData}
            onClick={() => {
              // deleteUser(data);
              ConfirmRow(data);
            }}
            // onClick={() => AddUser(data)}
            tooltip="Confirm Booking"
            tooltipOptions={{ position: "bottom" }}
          />
        ) : null}
        {/* {data.BookingStatus === "Booked" ? ( */}
        {bookingAllStatus === "Complete" ? (
          <span>
            <PButton
              type="button"
              icon="pi pi-check"
              className="p-button-success"
              disabled={conformationData}
              onClick={() => {
                StatusRow(data, "Complete");
              }}
              tooltip="Complete Booking"
              tooltipOptions={{ position: "bottom" }}
            />
            &nbsp;
            <PButton
              type="button"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => {
                StatusRow(data, "Cancelled");
              }}
              tooltip="Cancel Booking"
              tooltipOptions={{ position: "bottom" }}
            />
          </span>
        ) : null}
      </div>
    );
  };
  const handleSwitchChange = () => {
    setShowDateRange(!showDateRange);
  };

  const getColumn = () =>
    column.map((item, id) => {
      return (
        <Column
          style={{ minWidth: "200px" }}
          key={id}
          sortable={item.sortable}
          body={item?.body}
          filter={item.filter}
          field={item.selector}
          header={item.name}
        />
      );
    });

  const getLocationDropdown = () => {
    debugger;
    axios({
      url: CommonConfig.ApiUrl + "Property/getcitylist",
      method: "Get",
      headers: {},
      data: "",
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("1StateList===>", res.data.data[0]);
          setcitylist(
            res.data.data[0].map((item) => ({
              id: item.City,
              label: item.City,
            }))
          );
        }
        console.log("PropertyLocationData===>", PropertyLocationData);
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const getStateData = () => {
    const formattedData = {
      StringMapType: "STATE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);

          setStateList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const getPropertyCheckboxlist = () => {
    axios({
      url: CommonConfig.ApiUrl + "Property/getPropertyCheckboxlist",
      method: "Get",
      headers: {},
      data: "",
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("PropertyCheckboxlist", res.data.data);
          var list1 = [];
          res.data.data[2].map((item) => {
            list1.push(item.Feature);
          });
          setFeatureCheckboxlist(list1);

          var list2 = [];
          res.data.data[1].map((item) => {
            list2.push(item.Amenity);
          });
          setAmenityCheckboxlist(list2);

          var list3 = [];
          res.data.data[0].map((item) => {
            list3.push(item.Accesibility);
          });
          setAccessibilityCheckboxlist(list3);

          var list4 = [];
          res.data.data[3].map((item) => {
            list4.push(item.SDA);
          });
          setSdaCheckboxlist(list4);
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const display = (e) => {
    setselectedpropertylocation(e.label);
    setgetpropertyData([]);
    var name = e.label;
    var selectedcity = {
      id: name,
    };
    setLoader(true);
    //GetpopertynameByCity;
    axios({
      url: CommonConfig.ApiUrl + "Property/GetpopertynameByCity",
      method: "POST",
      headers: {},
      data: selectedcity,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data[0][0]);

          setPropertynamelist(
            res.data.data[0].map((item) => ({
              id: item.PropertyName,
              label: item.PropertyName,
            }))
          );
          setLoader(false);
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const onServiceChange = (event, value) => {
    let formattedData = [];
    for (let i = 0; i < value.length; i++) {
      formattedData.push(value[i].id);
    }
    const servi = formattedData;
    setValues(formattedData);

    console.log("---------------", value);
    setEnquiryAboutAutocompleteValues(value);
  };
  const bookingsave = () => {
    if (checkedValues.length === 0) {
      document.getElementById("checkedValuesError").style.display = "block";
      return;
    } else {
      document.getElementById("checkedValuesError").style.display = "none";
    }

    if (areYouCheckedValues.length === 0) {
      document.getElementById("areYoucheckedValuesError").style.display = "block";
      return;
    } else {
      document.getElementById("areYoucheckedValuesError").style.display = "none";
    }

    setbookingtype("save");
    //  UpdateAvalibleBedroomCount();
    onsubmit();
  };

  const getpropertydetails = (name) => {
    setLoader(true);
    getpropertyData.pop();
    imageurl.length = 0;
    var inputdata = {
      city: selectedpropertylocation,
      name: name.label,
    };

    axios({
      url: CommonConfig.ApiUrl + "Property/getPropertydetails",
      method: "POST",
      headers: {},
      data: inputdata,
    })
      .then((res) => {
        var url = "";
        if (res.data?.success) {
          getpropertyData.push(res.data.data[0][0]);
          console.log("document...", res.data.data[0][0]);
          console.log("document......", res.data);

          for (var i = 0; i < res.data.data[0].length; i++) {
            url = CommonConfig.dir + "Property/" + res.data.data[0][i].Filename;
            imageurl.push(url);
            console.log("checkimgValue", imageurl);
          }
          setLoader(false);
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  // console.log("Error::", errors);
  const editUser = async (row = {}) => {
    setLoader(!loader);
    const BookInspectionID = row.BookInspectionID;
    var rowdata = row;
    console.log("PropertyID////0", row?.BookingStatus);
    console.log("rowDataFromListing", rowdata);

    navigate("/layouts/Property/EditBookProperty", {
      state: rowdata,
    });
  };
  const selectedRow = (selectedRow = {}) => {
    setappointmentdelID(selectedRow.BookInspectionID);

    console.log("........,new", appointmentdelID);
    setOpenDialog(true);
  };
  const ConfirmRow = (Confirmdata) => {
    console.log("Confirmdata = ", Confirmdata?.BookingStatus);
    setBookingAllStatus(Confirmdata?.BookingStatus);
    UpdateAvalibleBedroomCount(Confirmdata);
    bookingStatus();
  };

  const UpdateAvalibleBedroomCount = (PropertyId) => {
    var PropertyId = {
      id: PropertyId.PropertyID,
      bookID: PropertyId.BookInspectionID,
    };
    axios({
      url: CommonConfig.ApiUrl + "Property/UpdateAvalibleBedroomCount",
      method: "POST",
      headers: {},
      data: PropertyId,
    })
      .then((res) => {
        if (res.data?.success) {
          cogoToast.success("Room alloted successfully");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const StatusRow = (StatusRowData, StatusType) => {
    var PropertyId = {
      id: StatusRowData.PropertyID,
      bookID: StatusRowData.BookInspectionID,
      statusdata: StatusType,
    };

    axios({
      url: CommonConfig.ApiUrl + "Property/UpdateAvalibleBedroomCount",
      method: "POST",
      headers: {},
      data: PropertyId,
    })
      .then((res) => {
        if (res.data?.success) {
          cogoToast.success("Booking " + StatusType + " successfully");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const bookingStatus = () => {
    setConformationData("Active");
    setTimeout(() => {
      setConformationData(null);
    }, 3000);
  };
  const deleteUser = (selectedRow = {}) => {
    // setappointmentdelID(selectedRow.ReferralId);
    setSelectedMediumType(false);
    //setOpenDialog(true);
    console.log("........,,,ddddd", appointmentdelID);

    // if (selectedMediumType == "") {
    //   document.getElementById("ServicesError").style.display = "block";
    //   return;
    // } else {
    //   document.getElementById("ServicesError").style.display = "none";
    // }

    if (selectedMediumType == "Yes") {
      document.getElementById("ServicesError").style.display = "none";
      let inputData = {
        id: appointmentdelID,
      };
      console.log("inputData...", inputData);
      axios({
        // Endpoint to send files
        url: CommonConfig.ApiUrl + "Property/deleteBookInspection",
        method: "POST",
        headers: {},

        data: inputData,
      })
        // Handle the response from backend here
        .then((res) => {
          if (res.data?.success) {
            console.log("test222", res.data);

            if (res.data.success === true) {
              cogoToast.success("Deleted Successfully.");
              setOpenDialog(false);
              getPropertyInspectionList();
            }
          }
        })
        // Catch errors if any
        .catch((error) => {
          console.log(error);
        });
    } else if (selectedMediumType == "No") {
      document.getElementById("ServicesError").style.display = "none";
      setOpenDialog(false);
    } else {
      document.getElementById("ServicesError").style.display = "block";
    }
  };

  const getPropertyInspectionList = () => {
    debugger;
    setLoader(!loader);
    let userLogin = JSON.parse(localStorage.getItem("userData"));
    var manageBy = "All"
    if (userLogin.PersonType == "INTERNAL STAFF") {
      manageBy = userLogin.PersonId
    }
    var dataset = {
      manageBy: manageBy,
      showDateRange: showDateRange === true ? 1 : 0,
      visitType: visitType,
      fromDate: CommonConfig.formatDate(fromDate),
      toDate: CommonConfig.formatDate(toDate)
    }
    axios({
      url: CommonConfig.ApiUrl + "Property/getBookInspectionlist",

      method: "POST",
      headers: {
        // https://uatapi.valuecare.org.au/Referral/getReferralList
        // Add any auth token here
        //authorization: "your token comes here",
      },
      // headers: {},
      data: dataset,
    })
      .then((res) => {
        setLoader(!loader);
        if (res.data?.success === true) {
          console.log("resdata", res.data.data[0]);
          setLoader(false);
          if (res.data.data[0].length > 0) {
            console.log("resdata33", res.data.data[0]);
            const mapData = res.data.data[0].map((tempData) => {
              tempData.IsMultiLivingOption = "";
              tempData.IsPreferred = "";
              tempData.BookingStatus = "";
              return tempData;
            });
            setApiData(mapData);
            console.log("Data...1", mapData);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getInternalStaff = () => {
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "contact/getInternalStaffDropdown",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
    })
      // Handle the response from backend here
      .then((res) => {
        if (res.data.success) {
          // cogoToast.success("Appointment Booked Successfully");
          // console.log("Managed Data", res.data.data);

          setInternalStaff(
            res.data.data.map((item) => ({
              id: item.PersonId,
              label: item.FullName,
            }))
          );
          // navigate("/pages/book-appointment/list");
        }
        // cogoToast.error('Something went wrong');
      })

      // Catch errors if any
      .catch((error) => {
        console.log(error);
        // cogoToast.error('Something went wrong');
      });
  };

  const getComplaintStatus = () => {
    const formattedData = {
      StringMapType: "COMPLAINTSTATUS",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ComplaintStatus===>", res.data.data);

          setComplaintStatus(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const getNotesDropdownList = () => {
    const formattedData = {
      StringMapType: "NOTETYPE.ComplaintRegister",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("NotesList===>", res.data.data);
          setSselectedNotesType(res.data.data[0].StringMapName);
          // setNotesDropdownList(
          //   res.data.data.map((item) => ({
          //     id: item.StringMapKey,
          //     label: item.StringMapName,
          //   }))
          // );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const saveNotes = () => {
    if (chNotes === null || chNotes === "") {
      document.getElementById("NotesError").style.display = "block";
      return;
    } else {
      document.getElementById("NotesError").style.display = "none";
    }
    const formattedData = {
      EntityType: "ComplaintRegister",
      EntityId: complaintRegisterId ? complaintRegisterId : "",
      CurrentUser: CommonConfig.getCurrentUserId(),
      IsFlag: inNoteImportant ? 1 : 0,
      NoteType: selectedNotesType,
      NoteTitle: "",
      NoteText: chNotes,
    };

    // debugger;
    axios({
      url: CommonConfig.ApiUrl + "note/addNote",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          cogoToast.success("Notes Added Successfully.");
          getNoteList(complaintRegisterId);
          setChNotes("");
        }
        console.log("test...", res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getNoteList = (values) => {
    // debugger;
    let noteReqData = {
      EntityType: "ComplaintRegister",
      EntityId: values ? values : "",
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "note/getNoteList",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
      data: noteReqData,
    })
      .then((res) => {
        // console.log("Hello147 ", res.data);
        if (res.data?.success) {
          // console.log("-------------", data);
          setNotesData(res.data.data || []);
          document.getElementById("NotesTableDynamic").style.display = "block";
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = (data) => {
    debugger;
    if (bookingtype === "save") {
      let formattedData = "";
      formattedData = {
        ...data,
        visitingDate: moment(new Date()).format("YYYY-MM-DD"),
        //  visitingtime: '',
        PropertyId: getpropertyData[0] ? getpropertyData[0].PropertyID : "  ",
        PostCode: data.PostCode.toString(),
        //  bookingtype: bookingtype,
        NumberOfBedrooms: data.NumberOfBedrooms.label,
        PropertyLocation: data.PropertyLocation.label,
        PropertyName: data.PropertyName.label,
        // PropertyFeature: checkedValues.toString(),
        // PropertyAminities: amenitiesCheckedValues.toString(),
        PropertyAccesibility: accessibilityCheckedValues.toString(),
        PropertySDA: sdaCheckedValues.toString(),
        Contact: data.Contact,
        ContactTime: data.ContactTime,
        EnquiryAbout: checkedValues.toString().replace(/,/g, "-"),
        AreYou: areYouCheckedValues.toString().replace(/,/g, "-"),
        EmailData: data.Email,
      };
      console.log("formattedDataQA", formattedData);
      setLoader(true);

      axios({
        url: CommonConfig.ApiUrl + "Property/AddupdateAssignProperty",
        method: "POST",
        headers: {},
        data: formattedData,
      })
        .then((res) => {
          //  setLoader(!loader);
          if (res.data.success === true) {
            setLoader(false);
            cogoToast.success("Property saved Successfully.");
            setshowsavebutton(true);
            setbookingtype("");
            setBookInspectionId(res.data.data[0][0].BookInspectionID);
            navigate("/layouts/Property/BookPropertyList");
          } else {
            cogoToast.error("Something Went Wrong.");
          }
        })
        .catch((error) => {
          console.log("AddUpdateErro", error);
        });
    } else if (bookingtype !== "confirm") {
      if (visitselecteddate === "" && visittime === "") {
        setOpenDialog(true);
        setshowsavebutton(true);
      } else if (visitselecteddate !== "" && visittime !== "") {
        if (redirect === true) {
          let formattedData = "";
          formattedData = {
            ...data,
            BookInspectionId: BookInspectionId ? BookInspectionId : "",
            InspectionDateTime: moment(visitselecteddate).format("YYYY-MM-DD"),
            visitingtime: visittime,
            PropertyId: getpropertyData[0] ? getpropertyData[0].PropertyID : "  ",
            PostCode: data.PostCode.toString(),
            bookingtype: "visit",
            NumberOfBedrooms: data.NumberOfBedrooms.label,
            PropertyLocation: data.PropertyLocation.label,
            EmailData: data.Email,
            PropertyName: data.PropertyName.label,
            // PropertyFeature: checkedValues.toString(),
            // PropertyAminities: amenitiesCheckedValues.toString(),
            PropertyAccesibility: accessibilityCheckedValues.toString(),
            PropertySDA: sdaCheckedValues.toString(),
            CurrentUser: CommonConfig.getCurrentUserId(),
            Contact: data.Contact,
            ContactTime: data.ContactTime,
            EnquiryAbout: checkedValues.toString(),
            AreYou: areYouCheckedValues.toString(),
          };
          console.log("formattedData", formattedData);

          axios({
            url: CommonConfig.ApiUrl + "Property/AddupdateAssignProperty",
            method: "POST",
            headers: {},
            data: formattedData,
          })
            .then((res) => {
              setLoader(!loader);
              if (res.data.success === true) {
                cogoToast.success("Property Booked for visit Successfully.");
                navigate("/layouts/Property/BookPropertyList");
              } else {
                cogoToast.error("Something Went Wrong.");
              }
            })
            .catch((error) => {
              console.log("AddUpdateErro", error);
            });
        }
      }
    } else {
      let formattedData = "";
      formattedData = {
        ...data,
        BookInspectionId: BookInspectionId ? BookInspectionId : "",
        visitingDate: moment(new Date()).format("YYYY-MM-DD"),
        visitingtime: visittime,
        PropertyId: getpropertyData[0] ? getpropertyData[0].PropertyID : "  ",
        PostCode: data.PostCode.toString(),
        bookingtype: bookingtype,
        NumberOfBedrooms: data.NumberOfBedrooms.label,
        PropertyLocation: data.PropertyLocation.label,
        PropertyName: data.PropertyName.label,
        // PropertyFeature: checkedValues.toString(),
        // PropertyAminities: amenitiesCheckedValues.toString(),
        PropertyAccesibility: accessibilityCheckedValues.toString(),
        PropertySDA: sdaCheckedValues.toString(),
        EmailData: data.Email,
        Contact: data.Contact,
        ContactTime: data.ContactTime,
        EnquiryAbout: checkedValues.toString(),
        AreYou: areYouCheckedValues.toString(),
      };
      console.log("formattedData", formattedData);

      axios({
        url: CommonConfig.ApiUrl + "Property/AddupdateAssignProperty",
        method: "POST",
        headers: {},
        data: formattedData,
      })
        .then((res) => {
          setLoader(!loader);
          if (res.data.success === true) {
            cogoToast.success("Property Booked Successfully.");
            navigate("/layouts/Property/BookPropertyList");
          } else {
            cogoToast.error("Something Went Wrong.");
          }
        })
        .catch((error) => {
          console.log("AddUpdateErro", error);
        });
    }
  };

  const onSubmitN = (data) => {
    // if (!data.files[0]) {
    //   document.getElementById("fileError").style.display = "block";
    //   return;
    // } else {
    //   document.getElementById("fileError").style.display = "none";
    // }
    console.log("Datataaaa", data);
    // let formData = new FormData();
    // formData.append("EntityType", data.NotesType);
    // formData.append("EntityId", complaintRegisterId ? complaintRegisterId : "");
    // formData.append("CustomURL", "Notes/" + data.NotesType + "/" + complaintRegisterNumber);
    // formData.append("IsFlag", inNoteImportant ? 1 : 0);
    // formData.append("NoteType", data.NotesType);
    // formData.append(
    //   "FileName",
    //   `${data.fileName}_${moment(Date())
    //     .toISOString()
    //     .replace(/[^0-9]/g, "")
    //     .slice(0, -3)}` +
    //   "." +
    //   `${data.files[0].name.split(".").pop()}`
    // );
    // formData.append("NoteTitle", "");
    // formData.append("NoteText", data.Notes);
    // formData.append("document", data.files[0]);
    // formData.append("CurrentUser", CommonConfig.getCurrentUserId());
    // formData.append("LanguageId", "en-US");
    // console.log("DDDDDDD", formData);

    const formattedData = {
      EntityType: "ComplaintRegister",
      EntityId: complaintRegisterId ? complaintRegisterId : "",
      CurrentUser: CommonConfig.getCurrentUserId(),
      IsFlag: inNoteImportant ? 1 : 0,
      NoteType: data.NotesType,
      NoteTitle: "",
      NoteText: data.Notes,
    };

    // reset({
    //   Notes: "",
    //   NotesTypes: "",
    // });
    // console.log("formattedData", formattedData);

    // debugger;
    axios({
      url: CommonConfig.ApiUrl + "note/addNote",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          cogoToast.success("Notes Added Successfully.");
          getNoteList();

          reset({
            NotesType: "",
            Notes: "",
          });
        }
        console.log("test...", res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };





  // 20231210





  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Card sx={{ width: "100%", mt: 3 }}>
        <MDBox display="flex">
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="primary"
            color="white"
            shadow="md"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <Icon fontSize="medium" color="inherit">
              assignment_late
            </Icon>
          </MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            {showAdd ? "New Add Inspection Request" : "New View Inspection List"}
          </MDTypography>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 0, ml: "auto", mr: 2 }}>
            {complaintRegisterNumber ? "Reference Number:" + complaintRegisterNumber : ""}
          </MDTypography>
          <MDButton
            variant="contained"
            sx={{ mt: 1, mb: 0, ml: 2, mr: 2 }}
            color="primary"
            onClick={() => {
              setShowAdd((prev) => !prev);
              setNotesEnable(false);
              reset({});
              setComplaintRegisterNumber(null);
            }}
          >
            {showAdd ? "Show List" : "Add New"}
          </MDButton>
        </MDBox>
        <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
          {showAdd ? (
            <div className="reg_step quality-form">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="referral_form_inner">
                  <Grid container spacing={3}>

                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="FirstName"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="First Name*"
                              color="primary"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="LastName"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Last Name*"
                              color="primary"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>








                    {/* <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="ReceivedBy"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="ReceivedBy"
                              name="ReceivedBy"
                              value={value}
                              options={internalStaff ? internalStaff : []}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Received By*"
                                  error={errors.ReceivedBy}
                                  helperText={errors.ReceivedBy ? "Received By Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid> */}
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="Email"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Email*"
                              color="primary"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="Phone"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Phone*"
                              color="primary"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="PostalCode"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Postal Code*"
                              color="primary"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="12">
                      <div className="reg_input">
                        <Controller
                          name="Message"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Message*"
                              color="primary"
                              variant="outlined"
                              rows={3}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input check_value">
                        <InputLabel>How would you like us to contact you?*</InputLabel>
                        <Controller
                          name="Contact"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <>
                              {" "}
                              <RadioGroup aria-label="appointment" row="true" defaultValue="Email">
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Email"
                                  control={<Radio />}
                                  label="Email"
                                />
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="SMS"
                                  control={<Radio />}
                                  label="SMS"
                                />
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Phone"
                                  control={<Radio />}
                                  label="Phone"
                                />
                              </RadioGroup>
                            </>
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="6">
                      <div className="reg_input check_value">
                        <InputLabel>When would you like us to contact you?*</InputLabel>
                        <Controller
                          name="ContactTime"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <>
                              {" "}
                              <RadioGroup
                                aria-label="appointment"
                                row="true"
                                defaultValue="Morning"
                              >
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Morning"
                                  control={<Radio />}
                                  label="Morning"
                                />
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Afternoon"
                                  control={<Radio />}
                                  label="Afternoon"
                                />
                              </RadioGroup>
                            </>
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>



                  <Grid container spacing={3}>
                    <Grid item md="8">
                      <div className="reg_input sec_level auto-complete">
                        <Autocomplete
                          multiple
                          id="EnquiryAbout"
                          name="EnquiryAbout"
                          options={EnquiryAboutList ? EnquiryAboutList : []}
                          value={enquiryAboutAutocompleteValues ? enquiryAboutAutocompleteValues : []}
                          defaultValue={
                            enquiryAboutAutocompleteValues ? enquiryAboutAutocompleteValues : []
                          }
                          onChange={onServiceChange}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="What is your enquiry about?*"
                              placeholder="Enquiry About..."
                              id="EnquiryAbout"
                              name="EnquiryAbout"
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="8">
                      <div className="reg_input sec_level auto-complete">
                        <Autocomplete
                          multiple
                          id="AreYou"
                          name="AreYou"
                          options={AreYou}
                          // value={serviceAutocompleteValues ? serviceAutocompleteValues : []}
                          // defaultValue={
                          //   serviceAutocompleteValues ? serviceAutocompleteValues : []
                          // }
                          // onChange={onServiceChange}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Are You a?*"
                              placeholder="Are You a..."
                              id="AreYou"
                              name="AreYou"
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>





                  {/* NewCodeStart */}

                  <div className="reg_input">
                    <h5>Assign Property</h5>
                  </div>

                  <Grid container spacing={3}>
                    <Grid item md="5">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="PropertyLocation"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="PropertyLocation"
                              name="PropertyLocation"
                              value={value}
                              options={PropertyLocationData ? PropertyLocationData : ""}
                              onChange={(e, v) => {
                                onChange(v);
                                display(v);
                              }}
                              //    onClose={(e, v) => display(value)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Property Location*"
                                  error={errors.PropertyLocation}
                                  helperText={
                                    errors.PropertyLocation ? "Property Location Required." : ""
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="5">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="PropertyName"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disabled={selectedpropertylocation ? false : true}
                              id="PropertyName"
                              name="PropertyName"
                              value={value}
                              options={PropertyNameData ? PropertyNameData : ""}
                              onChange={(e, v) => {
                                onChange(v);
                                getpropertydetails(v);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Property Name*"
                                  error={errors.PropertyName}
                                  helperText={errors.PropertyName ? "Property Name Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="NumberOfBedrooms"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="NumberOfBedrooms"
                              name="NumberOfBedrooms"
                              value={value}
                              options={RoomsData ? RoomsData : ""}
                              onChange={(e, v) => onChange(v)}
                              // onBlur={GetData}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Number of Bedrooms*"
                                  error={errors.NumberOfBedrooms}
                                  helperText={
                                    errors.NumberOfBedrooms ? "Number of Bedrooms Required." : ""
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div className="reg_input">
                    <h5>Property Details</h5>
                  </div>
                  <div className="pd-row">
                    {getpropertyData[0] ? (
                      <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                        <Grid item md="12">
                          <div className="pd-box">
                            <p>
                              <span>Property ID: </span>
                              <b>{getpropertyData[0].ValueCarePropertyId ? getpropertyData[0].ValueCarePropertyId : "  "}</b>
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    ) : null}
                  </div>
                  <div className="pd-row">
                    {getpropertyData[0] ? (
                      <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                        <Grid item md="6">
                          <div className="pd-box">
                            <p>
                              <span>Name:</span>
                              {getpropertyData[0] ? getpropertyData[0].PropertyName : "  "}
                            </p>
                          </div>
                        </Grid>
                        <Grid item md="6">
                          <div className="pd-box">
                            <p>
                              <span>Type:</span>
                              {getpropertyData[0] ? getpropertyData[0].PropertyType : "  "}
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    ) : null}
                  </div>
                  <div className="pd-row">
                    {getpropertyData[0] ? (
                      <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                        <Grid item md="12">
                          <div className="pd-box">
                            <p>
                              <span>Address: </span>
                              {getpropertyData[0].AddrLine1 +
                                " ," +
                                getpropertyData[0].AddrLine2 +
                                " ," +
                                getpropertyData[0].City +
                                " ," +
                                getpropertyData[0].State +
                                "," +
                                //getpropertyData[0].Country +
                                "Australia ," +
                                getpropertyData[0].PostalCode}
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    ) : null}
                  </div>
                  <div className="pd-row">
                    {getpropertyData[0] ? (
                      <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                        <Grid item md="6">
                          <div className="pd-box">
                            <p>
                              <span>Total Bedrooms:</span>
                              {getpropertyData[0] ? getpropertyData[0].TotalBedroomCount : "  "}
                            </p>
                          </div>
                        </Grid>
                        <Grid item md="6">
                          <div className="pd-box">
                            <p>
                              <span>Available Bedrooms:</span>{" "}
                              {getpropertyData[0]
                                ? getpropertyData[0].TotalAvailableBedroomCount
                                : "  "}
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    ) : null}
                  </div>
                  <div className="pd-row ">
                    {getpropertyData[0] ? (
                      <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                        <Grid item md="6">
                          <div className="pd-box">
                            <p>
                              <span>Total Bathrooms:</span> {getpropertyData[0].BathroomCount}
                            </p>
                          </div>
                        </Grid>
                        <Grid item md="6">
                          <div className="pd-box">
                            <p>
                              <span>Amenities:</span>{" "}
                              {getpropertyData[0] ? getpropertyData[0].PropertyAminities : ""}
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    ) : null}
                  </div>
                  <div className="pd-row">
                    {getpropertyData[0] ? (
                      <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                        <Grid item md="12">
                          <div className="pd-box">
                            <p>
                              <span>Features:</span> {getpropertyData[0].PropertyFeature}

                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    ) : null}
                  </div>
                  <div className="pd-row last">
                    {getpropertyData[0] ? (
                      <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                        <Grid item md="6">
                          <div className="pd-box">
                            <p>
                              <span>Accessibility:</span>{" "}
                              {getpropertyData[0] ? getpropertyData[0].PropertyAccesibility : ""}
                            </p>
                          </div>
                        </Grid>
                        <Grid item md="6">
                          <div className="pd-box">
                            <p>
                              <span>SDA:</span>{" "}
                              {getpropertyData[0] ? getpropertyData[0].PropertySDA : ""}
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    ) : null}
                  </div>

                  {/* NewCodeEnd */}





                  <div className="reg_submit">
                    <MDButton
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setShowAdd(false);
                        setNotesEnable(false);
                        setComplaintRegisterNumber(null);
                      }}
                    >
                      Cancel
                    </MDButton>
                    <MDButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      style={{
                        color: "#fff",
                      }}
                      // onClick={bookingsave}
                    >
                      {complaintRegisterNumber ? "Update" : "Submit"}
                    </MDButton>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            // start

            // End
            <div className="table-custom">
              <div>
                <Accordion>
                  <AccordionSummary expandIcon={<ArrowDownward />}>Search Filters</AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={3}>
                      {showDateRange && (
                        <>
                          <Grid item md="4">
                            <div className="reg_input">
                              <Controller
                                name="visitType"
                                control={control}
                                defaultValue={''}
                                render={({ field: { onChange, value } }) => (
                                  <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={visitTypeList}
                                    onChange={(event, newValue) => {
                                      onChange(newValue?.value || '');
                                      setVisitType(newValue?.value);
                                    }}
                                    // value={visitTypeList.find((option) => option.value === value) || null}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Search By" />}
                                  />
                                )}
                              />
                            </div>
                          </Grid></>)}
                      <Grid item md="4">
                        {showDateRange && (
                          <>
                            <div className="reg_input">
                              <Controller
                                name="fromDate"
                                defaultValue=""
                                id="fromDate"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <LocalizationProvider dateAdapter={DateFnsUtils}>
                                    <DatePicker
                                      label="From Date"
                                      value={value}
                                      inputFormat="dd/MM/yyyy"
                                      onChange={(date) => {
                                        setFromDate(date);
                                        onChange(date);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          id="fromDate"
                                          name="fromDate"
                                          {...params}
                                          error={errors.fromDate}
                                          helperText={
                                            errors.fromDate ? "From Date Required." : ""
                                          }
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                )}
                              />
                            </div>
                          </>
                        )}
                      </Grid>
                      <Grid item md="4">
                        {showDateRange && (
                          <>
                            <div className="reg_input">
                              <Controller
                                name="toDate"
                                defaultValue=""
                                id="toDate"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <LocalizationProvider dateAdapter={DateFnsUtils}>
                                    <DatePicker
                                      label="To Date"
                                      value={value}
                                      inputFormat="dd/MM/yyyy"
                                      onChange={(date) => {
                                        setToDate(date);
                                        onChange(date);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          id="toDate"
                                          name="toDate"
                                          {...params}
                                          error={errors.fromDate}
                                          helperText={
                                            errors.fromDate ? "To Date Required." : ""
                                          }
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                )}
                              />
                            </div>
                          </>
                        )}
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <FormControlLabel control={<Switch checked={showDateRange} onChange={handleSwitchChange} />} label="With Date Range" />
                      </Grid>
                      <Grid item md="4">
                        <MDButton onClick={() => getPropertyInspectionList()} variant="contained" color="primary">
                          Search
                        </MDButton>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
              <PrimeDatatable data={apiData} getColumnListing={getColumn} />
            </div>

          )
          }
        </MDBox >
      </Card >

      {notesEnable && CommonConfig.isInternalStaff() === 1 ? (
        <Card sx={{ width: "100%", mt: 6 }}>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="primary"
              color="white"
              shadow="md"
              borderRadius="xl"
              ml={3}
              mt={-2}
            >
              <Icon fontSize="medium" color="inherit">
                warning_amber
              </Icon>
            </MDBox>
            <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
              Notes
            </MDTypography>
          </MDBox>
          <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
            {/* <Grid container spacing={3}>
          <Grid item xs={4}>
            <div className="reg_input">
              <Autocomplete
                options={notesDropdownList ? notesDropdownList : []}
                defaultValue={selectedNotesType ? selectedNotesType : ""}
                onChange={(e, value) => {
                  setSselectedNotesType(value.label);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Notes Type*"
                    name="NotesType"
                    // error={values.errorstate}
                    // helperText={values.errorstate}
                  />
                )}
              />
              <span class="errorTC" id="ServicesError">
                Please select Notes Type.
              </span>
            </div>
          </Grid>
          <Grid item xs={4}>
            <ReportGmailerrorredIcon
              color={inNoteImportant ? "primary" : "secondary"}
              fontSize="large"
              onClick={() => setinNoteImportant((prev) => !prev)}
            />
          </Grid>
        </Grid> */}
            <Grid container spacing={3}>
              <Grid item xs={6}>
                {/* <div className="reg_input">
              <TextField
                onChange={(e) => setChNotes(e.target.value)}
                value={chNotes ? chNotes : ""}
                name="notes"
                multiline
                rows={3}
                // maxRows={4}
                label="Notes Text*"
                id="regFirstName"
                fullWidth
              />
              <span class="errorTC" id="NotesError">
                Please Write Notes.
              </span>
            </div> */}
              </Grid>
            </Grid>

            <div className="notes-table">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Notes</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="nt-date">{moment(new Date()).format("DD-MM-YYYY")}</td>
                    <td className="nt-comment">
                      <div className="reg_input mb-0">
                        <TextField
                          onChange={(e) => setChNotes(e.target.value)}
                          value={chNotes ? chNotes : ""}
                          name="notes"
                          multiline
                          rows={2}
                          // maxRows={4}
                          label="Notes Text*"
                          id="regFirstName"
                          fullWidth
                        />
                        <span class="errorTC" id="NotesError">
                          Please Write Notes.
                        </span>
                      </div>
                    </td>
                    <td className="nt-action">
                      <PButton
                        type="button"
                        icon="pi pi-plus"
                        className="p-button-primary AddNotes"
                        onClick={() => saveNotes()}
                        // style={{ marginRight: ".5em" }}
                        tooltip="Add Notes"
                        tooltipOptions={{ position: "bottom" }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <NotesComponent
              entityType="ComplaintRegister"
              entityId={complaintRegisterId}
              NotesData={notesData}
            />
          </MDBox>
        </Card>
      ) : (
        <></>
      )}

      {/* {notesEnable && CommonConfig.isInternalStaff() === 1 ? (
        <div className="custom-accordian mt-2">
          <Accordion>
            <AccordionSummary expandIcon={<ArrowDownward />}>Notes</AccordionSummary>
            <AccordionDetails>
              <form onSubmit={handleSubmitNote(onSubmitN)}>
                <Grid container spacing={3}>
                  <Grid item md="4">
                    <div className="reg_input auto-complete">
                      <Controller
                        control={controlNotes}
                        name="NotesType"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            disablePortal
                            id="NotesType"
                            name="NotesType"
                            value={value}
                            options={notesDropdownList ? notesDropdownList : []}
                            onChange={(e, v) => onChange(v.id)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Note Type*"
                                error={errorsNote.NotesType}
                                helperText={errorsNote.NotesType ? "Note Type required" : ""}
                              />
                            )}
                          />
                        )}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="reg_input">
                      <InputLabel>Attach Document:</InputLabel>
                      <Controller
                        name="files"
                        control={controlNotes}
                        defaultValue={[]}
                        render={({ field }) => {
                          return (
                            <Input
                              {...field}
                              label="Upload Document"
                              type="file"
                              variant="outlined"
                              onChange={(event) => field.onChange(event.target?.files)}
                              value={field.target?.fileName}
                            />
                          );
                        }}
                      />
                     
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="imp-notes">
                      <ReportGmailerrorredIcon
                        color={inNoteImportant ? "primary" : "secondary"}
                        fontSize="large"
                        cursor="pointer"
                        onClick={() => setinNoteImportant((prev) => !prev)}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={8}>
                    <div className="reg_input">
                      <Controller
                        name="Notes"
                        control={controlNotes}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            label="Note Text*"
                            color="primary"
                            variant="outlined"
                            rows={3}
                            {...registerNote("Notes", { required: true })}
                            multiline
                            formControlProps={{
                              fullWidth: true,
                            }}
                            value={value}
                            onChange={onChange}
                            error={errorsNote.Notes}
                            helperText={errorsNote.Notes ? "Note required" : ""}
                          />
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid item md="4" sx={{ mb: 2 }}>
                  <MDButton type="submit" variant="contained" color="primary">
                    Save Notes
                  </MDButton>
                </Grid>
              </form>

              <NotesComponent
                entityType="ComplaintRegister"
                entityId={complaintRegisterId}
                NotesData={notesData}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      ) : (
        <></>
      )} */}
    </DashboardLayout >
  );
};

export default ComplaintRegister;
