import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import { useLocation, useNavigate } from "react-router-dom";
import { Button as PButton } from "primereact/button";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";
import MDInput from "components/MDInput";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { CommonConfig } from "constant";
import axios from "axios";
import PrimeDatatable from "layouts/component/PrimeDatatable";
import { Column } from "primereact/column";
import { ArrowDownward } from "@mui/icons-material";
import { NotesComponent } from "layouts/component/Notes";
import cogoToast from "cogo-toast";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const schema = yup.object().shape({
  RiskDescription: yup.string().required("Risk Description Required."),
  RiskDetail: yup.string().required("Risk Required."),
  RiskRating: yup.object().required("Risk Rating Required."),
  AssignedTo: yup.object().required("Assigned to Required."),
  // ControlActions: yup.string().required("Status required"),
  // PersonResponsible: yup.string().required("Details required"),
  ReviewDueDate: yup.string().required("Review Due Date Required."),
});

export default () => {
  const MAX_COUNT = 5;
  const [todayDate, settodayDate] = useState(new Date().toISOString().slice(0, 10));
  const [riskRegisterList, setRiskRegisterList] = useState(null);
  const [internalStaff, setInternalStaff] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [riskRatingDD, setRiskRatingDD] = useState(null);
  const [riskRegisterId, setRiskRegisterId] = useState(null);
  const [riskRegisterNumber, setRiskRegisterNumber] = useState(null);
  const [chNotes, setChNotes] = useState(null);
  const [selectedNotesType, setSselectedNotesType] = useState(null);
  const [inNoteImportant, setinNoteImportant] = useState(false);
  const [notesData, setNotesData] = useState([]);
  const [documentdata, setdocumentdata] = useState([]);
  const [notesDropdownList, setNotesDropdownList] = useState(null);
  const [notesEnable, setNotesEnable] = useState(false);
  const [loader, setLoader] = React.useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([] || null);
  const [fileLimit, setFileLimit] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  // let dir = "https://valuecare.cognisunsandbox.com/Backend/documents/riskregister/";
  let dir = CommonConfig.dir + "riskregister/";
  
  let navigate = useNavigate();
  let loginUser;
  if (localStorage.getItem("userData")) {
    // alert("Hello");
    loginUser = JSON.parse(localStorage.getItem("userData"));
  } else {
    //alert("Hello2");
    navigate("/");
  }

  // console.log("ERRRor", errors);

  useEffect(() => {
    getRiskRegisterList();
    getInternalStaff();
    getRiskRatingDD();
    getNotesTypeDropdownList();
    // getNoteList();
  }, []);

  const getRiskRatingDD = () => {
    const formattedData = {
      StringMapType: "RISKRATING",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          // console.log("ddddd", res);
          setRiskRatingDD(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const getInternalStaff = () => {
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "contact/getInternalStaffDropdown",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
    })
      // Handle the response from backend here
      .then((res) => {
        if (res.data.success) {
          // cogoToast.success("Appointment Booked Successfully");
          // console.log("Managed Data", res.data.data);

          setInternalStaff(
            res.data.data.map((item) => ({
              id: item.PersonId,
              label: item.FullName,
            }))
          );
          // navigate("/pages/book-appointment/list");
        }
        // cogoToast.error('Something went wrong');
      })

      // Catch errors if any
      .catch((error) => {
        console.log(error);
        // cogoToast.error('Something went wrong');
      });
  };
  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };
  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  const getRiskRegisterList = () => {
    setLoader(!loader);
    let formattedData = {
      ComplaintRegisterId: "",
      CurrentUser: "",
    };

    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "qualityManagment/getRiskRegisterData",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
      data: formattedData,
    })
      // Handle the response from backend here
      .then((res) => {
        console.log("RRRRR", res);
        if (res.data.success) {
          setLoader(false);
          var PersonType = loginUser.PersonType;
          var loginUserName = loginUser.FirstName + " " + loginUser.LastName;
          if (PersonType == "INTERNAL STAFF") {
            // setLoader(false);
            var tempstore = [];
            for (var i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].AssigneeName === loginUserName) {
                tempstore.push(res.data.data[i]);
              }
            }
            debugger;
            setRiskRegisterList(tempstore);
          } else {
            setRiskRegisterList(res.data.data);
          }

          // console.log("DSfadsaf");
        }
        // cogoToast.error('Something went wrong');
      })

      // Catch errors if any
      .catch((error) => {
        console.log(error);
        // cogoToast.error('Something went wrong');
      });
  };

  const getNotesTypeDropdownList = () => {
    const formattedData = {
      StringMapType: "NOTETYPE.RiskRegister",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);
          setSselectedNotesType(res.data.data[0].StringMapName);

          // setNotesDropdownList(
          //   res.data.data.map((item) => ({
          //     id: item.StringMapKey,
          //     label: item.StringMapName,
          //   }))
          // );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const getNoteList = (values) => {
    // debugger;
    let noteReqData = {
      EntityType: "RiskRegister",
      EntityId: values ? values : "",
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "note/getNoteList",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
      data: noteReqData,
    })
      .then((res) => {
        // console.log("Hello147 ", res.data);
        if (res.data?.success) {
          // console.log("-------------", data);
          setNotesData(res.data.data || []);
          document.getElementById("NotesTableDynamic").style.display = "block";
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getdocumentlist = (values) => {
    // debugger;
    let docReqData = {
      EntityType: "RiskRegister",
      EntityId: values ? values : "",
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "document/getDocumentListByTypeAndId",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
      data: docReqData,
    })
      .then((res) => {
        // console.log("Hello147 ", res.data);
        if (res.data?.success) {
          // console.log("-------------", data);
          setdocumentdata(res.data.data[0] || []);

          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let column = [
    {
      name: "No. / Ref",
      selector: "RiskNumber",
      filter: true,
      sortable: true,
    },
    {
      name: "Risk Description",
      selector: "RiskDescription",
      filter: true,
      sortable: true,
    },
    {
      name: "Risk",
      selector: "RiskDetail",
      filter: true,
      sortable: true,
    },
    {
      name: "Risk Rating",
      selector: "RiskRating",
      filter: true,
      sortable: true,
    },
    {
      name: "Control Action",
      selector: "ControlAction",
      filter: true,
      sortable: true,
    },
    {
      name: "Person Responsible",
      selector: "AssigneeName",
      filter: true,
      sortable: true,
    },
    {
      name: "Due Date",
      body: (data) => {
        if (data.DueDate) {
          return CommonConfig.formatDate(data.DueDate);
        }
        return "";
      },
      selector: "DueDate",
      filter: true,
      sortable: true,
    },
    {
      name: "Comments/Progress on Treatment",
      selector: "ProgressComment",
      filter: true,
      sortable: true,
    },
    {
      name: "Date Completed",
      body: (data) => {
        if (data.CompletedDate) {
          return CommonConfig.formatDate(data.CompletedDate);
        }
        return "";
      },
      selector: "CompletedDate",
      filter: true,
      sortable: true,
    },
    {
      name: "Due Date for Review",
      body: (data) => {
        if (data.ReviewDueDate) {
          return CommonConfig.formatDate(data.ReviewDueDate);
        }
        return "";
      },
      selector: "ReviewDueDate",
      filter: true,
      sortable: true,
    },
    {
      name: "Action",
      body: (data) => actionTemplate(data),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const notesColumns = [
    {
      selector: (notesData) => {
        if (notesData.CreatedOn) {
          return moment(notesData.CreatedOn).format("DD-MM-YYYY");
        }
      },
      className: "NotesDates",
    },

    {
      selector: (notesData) => {
        return notesData?.NoteTitle + " " + notesData?.NoteText;
      },
      className: "NotesTitle",
    },

    {
      className: "NotesInfo",
      body: (notesData) => actionTemplate(notesData),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const getNotesColoumns = () =>
    notesColumns.map((item, id) => {
      return (
        <Column
          style={{ minWidth: "200px" }}
          key={id}
          sortable={item.sortable}
          body={item?.body}
          filter={item.filter}
          field={item.selector}
          header={item.name}
        />
      );
    });

  const actionTemplate = (row) => {
    return (
      <PButton
        type="button"
        icon="pi pi-pencil"
        className="p-button-warning"
        onClick={() => {
          // setIsEditEnabled(true);
          setShowAdd(true);
          setNotesEnable(true);
          setUploadedFiles([]);
          setdocumentdata([]);
          // console.log("ROW", row);
          if (row.RiskRegisterId) {
            setRiskRegisterId(row.RiskRegisterId);
            setRiskRegisterNumber(row.RiskNumber);
            getNoteList(row.RiskRegisterId);
            getdocumentlist(row.RiskRegisterId);
            // getNoteList()
          }
          let assignToObj;
          let riskRatingToObj;
          if (row.AssignedTo) {
            assignToObj = internalStaff.filter((item) => item.id == row.AssignedTo);
            // console.log("dsfds", assignToObj);
          }
          if (row.RiskRating) {
            riskRatingToObj = riskRatingDD.filter((item) => item.id == row.RiskRating);
          }

          reset({
            ...row,
            AssignedTo: assignToObj ? assignToObj[0] : "",
            RiskRating: riskRatingToObj ? riskRatingToObj[0] : "",
          });
        }}
        style={{ marginRight: ".5em" }}
        tooltip="Edit Complaint"
        tooltipOptions={{ position: "bottom" }}
      />
    );
  };

  const getColumn = () =>
    column.map((item, id) => {
      return (
        <Column
          style={{ minWidth: "200px" }}
          key={id}
          sortable={item.sortable}
          body={item?.body}
          filter={item.filter}
          field={item.selector}
          header={item.name}
        />
      );
    });

  const saveNotes = () => {
    // if (selectedNotesType === null) {
    //   document.getElementById("ServicesError").style.display = "block";
    //   return;
    // } else {
    //   document.getElementById("ServicesError").style.display = "none";
    // }

    if (chNotes === null || chNotes === "") {
      document.getElementById("NotesError").style.display = "block";
      return;
    } else {
      document.getElementById("NotesError").style.display = "none";
    }

    const formattedData = {
      EntityType: "RiskRegister",
      EntityId: riskRegisterId ? riskRegisterId : "",
      CurrentUser: CommonConfig.getCurrentUserId(),
      IsFlag: inNoteImportant ? 1 : 0,
      NoteType: selectedNotesType,
      NoteTitle: "",
      NoteText: chNotes,
    };

    // debugger;
    axios({
      url: CommonConfig.ApiUrl + "note/addNote",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          cogoToast.success("Notes Added Successfully.");
          getNoteList(riskRegisterId);
          setChNotes("");
        }
        console.log("test...", res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = (data) => {
    console.log("DDDD", uploadedFiles);
    setLoader(!loader);
    let formattedData = "";

    formattedData = {
      ...data,
      DateCompleted: CommonConfig.formatDate(data?.DateCompleted),
      ReviewDueDate: CommonConfig.formatDate(data?.ReviewDueDate),
      DueDate: CommonConfig.formatDate(data?.DueDate),
      RiskRating: data?.RiskRating?.id,
      AssignedTo: data?.AssignedTo?.id,
    };
    var formData = new FormData();
    formData.append("data", JSON.stringify(formattedData));
    console.log("uploadedFiles.....", uploadedFiles.length);
    for (let i = 0; i < uploadedFiles.length; i++) {
      console.log("uploadedFiles[i].name.....", uploadedFiles[i].name);
      formData.append("File", uploadedFiles[i], uploadedFiles[i].name);
    }
    console.log("formattedData", formattedData);

    axios({
      url: CommonConfig.ApiUrl + "qualityManagment/addUpdateRiskRegister",
      method: "POST",
      headers: {},
      data: formData,
    })
      .then((res) => {
        setLoader(!loader);
        // console.log("res data....", res);
        if (res.data.success) {
          // console.log("Successfull", res.data);
          getRiskRegisterList();
          setShowAdd(false);
          setUploadedFiles([]);
          setdocumentdata([]);
          setNotesEnable(false);
          if (riskRegisterNumber) {
            cogoToast.success("Updated Successfully.");
            setRiskRegisterNumber(null);
          } else {
            cogoToast.success("Registered Successfully.");
          }
          // navigate("/layout/referal/Referallist");
        } else {
          cogoToast.error("Something Went Wrong.");
        }

        // console.log("test", res.res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Card sx={{ width: "100%", mt: 3 }}>
        <MDBox display="flex">
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="primary"
            color="white"
            shadow="md"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <Icon fontSize="medium" color="inherit">
              warning_amber
            </Icon>
          </MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            Risk Register
          </MDTypography>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 0, ml: "auto", mr: 2 }}>
            {riskRegisterNumber ? "Reference Number:" + riskRegisterNumber : ""}
          </MDTypography>
          <MDButton
            variant="contained"
            color="primary"
            sx={{ mt: 1, mb: 0, ml: 2, mr: 2 }}
            onClick={() => {
              setShowAdd((prev) => !prev);
              setNotesEnable(false);
              setUploadedFiles([]);
              setdocumentdata([]);
              reset({});
              setRiskRegisterNumber(null);
            }}
          >
            {showAdd ? "Show List" : "Add New"}
          </MDButton>
        </MDBox>
        <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
          {showAdd ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="reg_step quality-form">
                <div className="referral_form_inner">
                  {/* <Controller
                    name="RiskNumber"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        id="outlined-read-only-input"
                        label="Reference Number"
                        style={{
                          width: "250px",
                          marginBottom: "15px",
                          marginTop: "15px",
                        }}
                        value={value}
                        onChange={onChange}
                        error={error}
                        helperText={error?.message}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    )}
                  /> */}
                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="RiskDescription"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              id="outlined-read-only-input"
                              label="Risk Description*"
                              // style={{
                              //   width: "250px",
                              // }}
                              value={value}
                              rows={3}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                              // InputProps={{
                              //   readOnly: true,
                              // }}
                            />
                          )}
                        />

                        {/* <TextField color="primary" label="Risk Description" variant="outlined" /> */}
                      </div>
                    </Grid>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="RiskDetail"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              id="outlined-read-only-input"
                              label="Risk*"
                              // style={{
                              //   width: "250px",
                              // }}
                              rows={3}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                              // InputProps={{
                              //   readOnly: true,
                              // }}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="RiskRating"
                          // rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="RiskRating"
                              name="RiskRating"
                              value={value}
                              options={riskRatingDD ? riskRatingDD : []}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Risk Rating*"
                                  error={errors.RiskRating}
                                  helperText={errors.RiskRating ? "Risk Rating Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="AssignedTo"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="AssignTo"
                              name="AssignTo"
                              value={value}
                              options={internalStaff ? internalStaff : []}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Assign To*"
                                  error={errors.AssignedTo}
                                  helperText={errors.AssignedTo ? "Assign to Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="DueDate"
                          defaultValue=""
                          control={control}
                          rules={{ required: false }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={DateFnsUtils}>
                              <DatePicker
                                label="Due Date"
                                value={value || null}
                                inputFormat="dd/MM/yyyy"
                                onChange={onChange}
                                minDate={new Date()}
                                renderInput={(params) => (
                                  <TextField
                                    // required={false}
                                    {...params}
                                    error={errors.DueDate}
                                    helperText={errors.DueDate ? "" : ""}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="ControlAction"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              id="outlined-read-only-input"
                              label="Treatment/Control Actions"
                              // style={{
                              //   width: "250px",
                              // }}
                              rows={3}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                              // InputProps={{
                              //   readOnly: true,
                              // }}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="ProgressComment"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              id="outlined-read-only-input"
                              label="Comments on Progress"
                              // style={{
                              //   width: "250px",
                              // }}
                              rows={3}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                              // InputProps={{
                              //   readOnly: true,
                              // }}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="CompletedDate"
                          defaultValue=""
                          control={control}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={DateFnsUtils}>
                              <DatePicker
                                label="Date Completed"
                                value={value || null}
                                inputFormat="dd/MM/yyyy"
                                onChange={onChange}
                                // maxDate={new Date()}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={errors.CompletedDate}
                                    helperText={errors.CompletedDate ? "" : ""}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="ReviewDueDate"
                          defaultValue=""
                          control={control}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={DateFnsUtils}>
                              <DatePicker
                                label="Review Due Date*"
                                value={value || null}
                                inputFormat="dd/MM/yyyy"
                                onChange={onChange}
                                minDate={new Date()}
                                // minDate={new Date("02-01-2022")}
                                // maxDate={new Date("09-05-2022")}
                                renderInput={(params) => (
                                  <TextField
                                    // helperText={error?.message}
                                    // error={error}
                                    {...params}
                                    error={errors.ReviewDueDate}
                                    helperText={
                                      errors.ReviewDueDate ? "Review Due Date Required." : ""
                                    }
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </div>
                    </Grid>
                    {/* <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="RiskRegisterAttachment"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <div>
                              <input
                                id="fileUpload"
                                type="file"
                                multiple
                                accept="application/pdf, image/png"
                                onChange={handleFileEvent}
                                disabled={fileLimit}
                              />
                              <div className="uploaded-files-list">
                                {uploadedFiles.map((file) => (
                                  <div>{file.name}</div>
                                ))}
                                {documentdata
                                  ? documentdata.map((file) => (
                                      <div>
                                        <a href={dir + file.Filename} target="_new">
                                          {file.Filename}
                                        </a>
                                      </div>
                                    ))
                                  : null}
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    </Grid> */}
                  </Grid>

                  <div className="reg_submit">
                    <MDButton
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setShowAdd(false);
                        setUploadedFiles([]);
                        setNotesEnable(false);
                        setdocumentdata([]);
                        setRiskRegisterNumber(null);
                      }}
                    >
                      Cancel
                    </MDButton>
                    <MDButton type="submit" variant="contained" color="primary">
                      {/* Submit */}
                      {riskRegisterNumber ? "Update" : "Submit"}
                      {/* {
                        riskRegisterNumber ? "" : ""
                      } */}
                    </MDButton>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <div className="table-custom">
              <PrimeDatatable data={riskRegisterList} getColumnListing={getColumn} />
            </div>
          )}
        </MDBox>
      </Card>

      {notesEnable && CommonConfig.isInternalStaff() === 1 ? (
        <Card sx={{ width: "100%", mt: 6 }}>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="primary"
              color="white"
              shadow="md"
              borderRadius="xl"
              ml={3}
              mt={-2}
            >
              <Icon fontSize="medium" color="inherit">
                warning_amber
              </Icon>
            </MDBox>
            <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
              Notes
            </MDTypography>
          </MDBox>
          <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
            {/* <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <div className="reg_input">
                      <Autocomplete
                        options={notesDropdownList ? notesDropdownList : []}
                        defaultValue={selectedNotesType ? selectedNotesType : ""}
                        onChange={(e, value) => {
                          setSselectedNotesType(value.label);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Notes Type*"
                            name="NotesType"
                            // error={values.errorstate}
                            // helperText={values.errorstate}
                          />
                        )}
                      />
                      <span class="errorTC" id="ServicesError">
                        Please select Notes Type.
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <ReportGmailerrorredIcon
                      color={inNoteImportant ? "primary" : "secondary"}
                      fontSize="large"
                      onClick={() => setinNoteImportant((prev) => !prev)}
                    />
                  </Grid>
                </Grid> */}
            <Grid container spacing={3}>
              <Grid item xs={6}>
                {/* <div className="reg_input">
                      <TextField
                        onChange={(e) => setChNotes(e.target.value)}
                        value={chNotes ? chNotes : ""}
                        name="notes"
                        multiline
                        rows={3}
                        // maxRows={4}
                        label="Notes Text*"
                        id="regFirstName"
                        fullWidth
                      />
                      <span class="errorTC" id="NotesError">
                        Please Write Notes.
                      </span>
                    </div> */}
              </Grid>
            </Grid>

            <div className="notes-table">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Notes</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="nt-date">{moment(new Date()).format("DD-MM-YYYY")}</td>
                    <td className="nt-comment">
                      <div className="reg_input mb-0">
                        <TextField
                          onChange={(e) => setChNotes(e.target.value)}
                          value={chNotes ? chNotes : ""}
                          name="notes"
                          multiline
                          rows={2}
                          // maxRows={4}
                          label="Notes Text*"
                          id="regFirstName"
                          fullWidth
                        />
                        <span class="errorTC" id="NotesError">
                          Please Write Notes.
                        </span>
                      </div>
                    </td>
                    <td className="nt-action">
                      <PButton
                        type="button"
                        icon="pi pi-plus"
                        className="p-button-primary AddNotes"
                        onClick={() => saveNotes()}
                        // style={{ marginRight: ".5em" }}
                        tooltip="Add Notes"
                        tooltipOptions={{ position: "bottom" }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <Grid item md="4">
              {/* <MDButton onClick={() => saveNotes()} variant="contained" color="primary">
                    Save Notes
                  </MDButton> */}
            </Grid>
            <NotesComponent
              entityType="RiskRegister"
              entityId={riskRegisterId}
              NotesData={notesData}
            />
          </MDBox>
        </Card>
      ) : (
        <></>
      )}
    </DashboardLayout>
  );
};
